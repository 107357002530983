import { GlobalStyles, StackV2, Status, Text, View } from '@andromeda'
import { Types } from '@pickstar/orbit'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
// @ts-ignore
import { Calendar as BigCalendar, View as BigView, DateHeaderProps, HeaderProps, momentLocalizer } from 'react-big-calendar'
import { CalendarStyles } from './styles'
import { iCalendar, iCalendarEvent, iEvent } from './types'
import { Typography } from '@mui/material'

const defaultLocalizer = momentLocalizer(moment)
const today = new Date()

const Calendar = ({ localizer = defaultLocalizer, events, style, onChangeMonth, ...props }: iCalendar) => {
  const [selectedView, setSelectedView] = useState<BigView>('month')
  const [selectedMonth, setSelectedMonth] = useState<number>(today.getMonth())

  const calendarEvents = useMemo(
    () =>
      events.map((event) => {
        return {
          ...event,
          start: event.start_datetime ? new Date(event.start_datetime) : undefined,
          end: event.end_datetime ? new Date(event.end_datetime) : undefined,
        } as iEvent
      }),
    [events]
  )

  const _renderTime = (datetime?: string) => {
    return datetime ? moment(datetime, 'YYYY-MM-DD hh:mm:ss').format('hh:mma') : ''
  }

  const eventComponent = useCallback(({ event }: iCalendarEvent) => {
    let isMultipleDays = false
    if (event.start_datetime && event.end_datetime) {
      isMultipleDays = new Date(event.start_datetime).toDateString() !== new Date(event.end_datetime).toDateString()
    }

    return (
      <StackV2
        direction={'row'}
        spacing={'none'}
        style={{
          ...CalendarStyles.eventComponentStack,
        }}>
        <Status type={event.status ? (event.status as Types.Job.StatusTypes) : 'DRAFT'} style={{ width: 8, height: 8, marginRight: 4 }} />
        <Typography
          sx={{
            color: GlobalStyles.SLATE_700,
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '-0.12px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}>{`${_renderTime(event.start_datetime)} ${event.name}`}</Typography>
      </StackV2>
    )
  }, [])

  const eventPropGetter = () => {
    return {
      style: props.eventStyle || { backgroundColor: 'transparent' },
    }
  }

  const _getDateCellColor = (dayOfTheWeek: number) => {
    if (selectedView === 'month' && (dayOfTheWeek === 0 || dayOfTheWeek === 6)) return '#ECF3FF'
    return '#FFFFFF'
  }

  const dateCellComponent = ({ children, value }: any) => {
    const dayOfTheWeek = value.getDay()
    const month = value.getMonth()
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        style: {
          backgroundColor: month !== selectedMonth ? GlobalStyles.SLATE_50 : _getDateCellColor(dayOfTheWeek),
          flex: 1,
        },
      })
    })
  }

  const monthHeaderComponent = (headerProps: HeaderProps) => {
    return <>{headerProps.label.substring(0, 3)}</>
  }
  const monthDateHeaderComponent = (dateheaderProps: DateHeaderProps) => {
    let date = moment(dateheaderProps.date).format('D')
    if (moment(0, 'HH').diff(dateheaderProps.date, 'days') === 0) {
      date = moment(dateheaderProps.date).format('D MMM')
    }
    return <>{date}</>
  }

  const _showMore = (count: number) => {
    return (
      <View style={{ width: '100%', paddingHorizontal: 12 }}>
        <Text
          style={{
            paddingVertical: 0,
            paddingHorizontal: 4,
            borderRadius: 8,
            backgroundColor: GlobalStyles.SLATE_100,
            fontSize: GlobalStyles.FONT_SIZES.TINY,
            fontWeight: '400',
            color: GlobalStyles.PRIMARY_500,
            textAlign: 'left',
          }}>{`+${count} more`}</Text>
      </View>
    ) as any
  }

  return (
    <BigCalendar
      onSelectEvent={props.onSelectEvent}
      events={calendarEvents}
      defaultView="month"
      defaultDate={today}
      localizer={localizer}
      startAccessor={'start'}
      endAccessor={'end'}
      // views={['month']}
      components={{
        // @ts-expect-error
        event: props.customEvent || eventComponent,
        dateCellWrapper: dateCellComponent,
        month: {
          header: monthHeaderComponent,
          dateHeader: monthDateHeaderComponent,
        },
        toolbar: props.customToolbar,
      }}
      eventPropGetter={eventPropGetter}
      style={{ height: 600, minHeight: 600, ...style }}
      onView={(view) => setSelectedView(view)}
      onNavigate={(newDate) => {
        setSelectedMonth(newDate.getMonth())
        onChangeMonth && onChangeMonth(newDate)
      }}
      view={selectedView}
      formats={{
        weekdayFormat: 'dddd',
      }}
      messages={{
        showMore: _showMore,
      }}
      popup
      {...props}
    />
  )
}

export default Calendar
