import { ButtonV2, GlobalStyles, Text, Tooltip } from '@andromeda'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { AppStyles } from '@styles'
import React, { CSSProperties, useContext } from 'react'
import { DrawerContext } from './DrawerContext'
import { SxProps, Theme } from '@mui/material'

const MenuItem = ({
  children,
  selected,
  menuItemStyle,
  action,
  ...props
}: {
  children?: React.ReactNode
  selected?: boolean
  menuItemStyle?: CSSProperties
  action?: () => void
}) => {
  return (
    <ButtonV2
      onClick={action}
      sx={{
        backgroundColor: selected ? GlobalStyles.SLATE_800 : '',
        height: AppStyles.MENU_ITEM_HEIGHT,
        justifyContent: { xxs: 'flex-start', sm: 'center' },
        alignItems: 'center',
        ...menuItemStyle,
        width: '100%',
      }}
      {...props}>
      {children}
    </ButtonV2>
  )
}

const InnerDrawerMenuItem = ({
  title = '',
  icon,
  selected,
  action,
  type = 'default',
  menuItemStyle,
}: {
  title?: string
  icon: JSX.Element
  selected: boolean
  action?: () => void
  type?: 'workspace' | 'default'
  menuItemStyle?: CSSProperties
}) => {
  const { open } = useContext(DrawerContext)

  let buttonStyle: SxProps<Theme> = {
    height: '40px',
    width: '40px',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    flex: 1,
  }

  if (selected) {
    buttonStyle = { ...buttonStyle, backgroundColor: GlobalStyles.SLATE_600 }
  }
  return (
    <MenuItem selected={selected} action={action} menuItemStyle={menuItemStyle} aria-label={title}>
      <Tooltip disableHoverListener={type === 'workspace'} text={title} placement={'right'} showIcon={false}>
        <Box
          onClick={action}
          sx={{
            ...buttonStyle,
            display: 'flex',
            justifyContent: 'center',
            ':hover': { backgroundColor: { sm: GlobalStyles.SLATE_900 } },
            svg: {
              stroke: { xxs: selected ? GlobalStyles.ORANGE : GlobalStyles.MONO_WHITE, sm: GlobalStyles.MONO_WHITE },
            },
          }}>
          {icon}
        </Box>
      </Tooltip>
      {type == 'workspace' && open && (
        <Box display={{ xxs: 'flex', sm: 'none' }}>
          <Text style={{ color: GlobalStyles.MONO_WHITE, textAlign: 'center' }}> {title}</Text>
        </Box>
      )}
    </MenuItem>
  )
}

export { MenuItem, InnerDrawerMenuItem }
