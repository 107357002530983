import { Badge, ButtonV2, GlobalStyles, Icon, StackV2, Text } from '@andromeda'
import { CalendarListStyles, StatusColors } from './styles'
import { CalendarEventListProps } from './types'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Types } from '@pickstar/orbit'

type ListViewItemProps = {
  name: string
  description: string | null
  start_datetime: string
  job_eid: string | undefined
  deliverable_eid: string | undefined
  end_datetime: string | null
  status: Types.Job.StatusTypes
}

const headers = ['DELIVERABLES', 'CLIENT', 'START', 'END', 'STATUS', '']

const getDayMonth = (date: string) => {
  const day = new Date(date).toLocaleDateString('AU', { day: 'numeric' })
  const month = new Date(date).toLocaleDateString('AU', { month: 'long' })
  return {
    day,
    month,
  }
}
const getTime = (date: string) => new Date(date).toLocaleTimeString('us', { hour: '2-digit', minute: '2-digit', hour12: false })

const CalendarEventList = ({ data, onPressDeliverable, ...props }: CalendarEventListProps) => {
  let rows: Array<ListViewItemProps> = []

  data.forEach((item) => {
    !rows.some((row) => row.deliverable_eid == item.eid) &&
      rows.push({
        start_datetime: item.start_datetime,
        name: item.name,
        description: item.description,
        end_datetime: item.end_datetime,
        status: item.status,
        job_eid: item.job_eid,
        deliverable_eid: item.eid,
      })
  })

  return (
    <TableContainer
      onScroll={(event) => {
        const target = event.target as HTMLInputElement
        const bottom = target.scrollHeight - target.scrollTop === target.clientHeight
        if (bottom) {
          props.lazyLoad && props.lazyLoad()
        }
      }}
      style={{ maxHeight: 420 }}
      component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>DELIVERABLES</TableCell>
            <TableCell align="left">CLIENT</TableCell>
            <TableCell align="center">START</TableCell>
            <TableCell align="center">END</TableCell>
            <TableCell align="center">STATUS</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const { day, month } = getDayMonth(row.start_datetime)
            return (
              <TableRow key={row.deliverable_eid} style={{ borderLeft: `4px solid ${StatusColors[row.status]}` }}>
                <TableCell align="center" style={{ borderRight: '1px solid #dedede' }} component="th" scope="row">
                  <StackV2 direction="column" width={80} style={{ ...CalendarListStyles.date }}>
                    <Text type="heading" size="sm" style={{ color: GlobalStyles.MONO_BLACK }} bold>
                      {day}
                    </Text>
                    <Text size="tiny" style={{ color: GlobalStyles.SLATE_500 }} bold>
                      {month}
                    </Text>
                  </StackV2>
                </TableCell>
                <TableCell align="left">
                  <StackV2 direction="column" width="auto" alignItems="flex-start">
                    <Text style={CalendarListStyles.name} bold>
                      {row.name}
                    </Text>
                    <Text size="sm" style={CalendarListStyles.description}>
                      {row.description}
                    </Text>
                  </StackV2>
                </TableCell>
                <TableCell align="center">{getTime(row.start_datetime)}</TableCell>
                <TableCell align="center">{row.end_datetime ? getTime(row.end_datetime) : ''}</TableCell>
                <TableCell align="center">
                  <Badge
                    style={[
                      CalendarListStyles.status,
                      {
                        backgroundColor: StatusColors[row.status],
                      },
                    ]}
                    text={row.status}
                    textStyle={{
                      fontSize: GlobalStyles.FONT_SIZES.TINY,
                      fontWeight: GlobalStyles.FONT_WEIGHT_MEDIUM,
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <ButtonV2 onClick={() => onPressDeliverable && onPressDeliverable(row.job_eid)} variant="text">
                    <Icon.ChevronRight size={GlobalStyles.ICON_SIZE} color={GlobalStyles.PRIMARY_500} />
                  </ButtonV2>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
    // <CardV2 sx={{ padding: 0 }}>
    //     <StackV2 sx={{ padding: 0 }} direction="column" >
    //         <StackV2
    //             direction={'row'}
    //             style={{
    //                 // paddingVertical: GlobalStyles.PADDING_SIZES.sm,
    //                 borderBottomWidth: 1,
    //                 borderBottomColor: GlobalStyles.SLATE_100,
    //             }}>
    //             {headers.map((header, index) => (
    //                 <View
    //                     key={header}
    //                     style={{
    //                         alignSelf: 'stretch',
    //                         flex: index === 0 ? 2 : index === 1 ? 1 : 0.5,
    //                     }}>
    //                     <Text
    //                         size="tiny"
    //                         style={{
    //                             fontWeight: '600',
    //                             color: GlobalStyles.MONO_BLACK,
    //                             paddingLeft: index === 0 ? GlobalStyles.PADDING_SIZES.md : 0,
    //                             lineHeight: GlobalStyles.FONT_SIZES.XTRA_LARGE,
    //                         }}>
    //                         {header}
    //                     </Text>
    //                 </View>
    //             ))}
    //         </StackV2>
    //         {/* <div
    //             style={{ width: '100%', maxHeight: 420 }}
    //             onScroll={({ nativeEvent }) => {
    //                 const { layoutMeasurement, contentOffset, contentSize } = nativeEvent
    //                 if (layoutMeasurement.height + contentOffset.y + 5 >= contentSize.height) {
    //                     props.lazyLoad && props.lazyLoad()
    //                 }
    //             }}
    //             scrollEventThrottle={1000}> */}
    //         <StackV2 direction="column">
    //             <List disablePadding>
    //                 {data.map((deliverable, index) => {
    //                     const {
    //                         name,
    //                         description,
    //                         start_datetime,
    //                         end_datetime = '',
    //                         current_number_of_talents,
    //                         job,
    //                         status,
    //                     } = deliverable
    //                     const { day, month } = getDayMonth(start_datetime)
    //                     return (
    //                         <Box key={deliverable.eid}>
    //                             <ListItem>
    //                                 <StackV2
    //                                     direction={"row"}
    //                                     width="100%"
    //                                     justifyContent="center"
    //                                     alignItems="center"
    //                                     style={{
    //                                         borderBottomWidth: index < data.length - 1 ? 1 : 0,
    //                                         borderBottomColor: GlobalStyles.SLATE_100,
    //                                         margin: 0,
    //                                     }}>
    //                                     <StackV2
    //                                         direction={"row"}
    //                                         style={{
    //                                             ...CalendarListStyles.deliverableCell,
    //                                             ...{
    //                                                 borderColor: StatusColors[status],
    //                                                 borderBottomLeftRadius: index + 1 === data.length ? 12 : 0,
    //                                             },
    //                                         }}>
    //                                         <StackV2 direction="column" width={80} style={CalendarListStyles.date}>
    //                                             <Text type="heading" size="sm" style={{ color: GlobalStyles.MONO_BLACK }} bold>
    //                                                 {day}
    //                                             </Text>
    //                                             <Text size="tiny" style={{ color: GlobalStyles.SLATE_500 }} bold>
    //                                                 {month}
    //                                             </Text>
    //                                         </StackV2>
    //                                         <StackV2
    //                                             direction="column"
    //                                             width="auto"
    //                                             alignItems="flex-start"
    //                                             style={{ marginLeft: GlobalStyles.PADDING_SIZES.md }}>
    //                                             <Text style={CalendarListStyles.name} bold>
    //                                                 {name}
    //                                             </Text>
    //                                             <Text size="sm" style={CalendarListStyles.description}>
    //                                                 {description}
    //                                             </Text>
    //                                         </StackV2>
    //                                     </StackV2>
    //                                     <View style={CalendarListStyles.clientCell}>
    //                                         <Text size="sm">{job?.client}</Text>
    //                                     </View>
    //                                     <View style={CalendarListStyles.cell}>
    //                                         <Text size="sm">{getTime(start_datetime)}</Text>
    //                                     </View>
    //                                     <View style={CalendarListStyles.cell}>
    //                                         <Text size="sm">{end_datetime ? getTime(end_datetime) : ''}</Text>
    //                                     </View>
    //                                     <View style={CalendarListStyles.cell}>
    //                                         <Badge
    //                                             style={[
    //                                                 CalendarListStyles.status,
    //                                                 {
    //                                                     backgroundColor: StatusColors[status],
    //                                                 },
    //                                             ]}
    //                                             text={status}
    //                                             textStyle={{
    //                                                 fontSize: GlobalStyles.FONT_SIZES.TINY,
    //                                                 fontWeight: GlobalStyles.FONT_WEIGHT_MEDIUM,
    //                                             }}
    //                                         />
    //                                     </View>
    //                                     <View style={CalendarListStyles.cell}>
    //                                         <Button
    //                                             type="secondary"
    //                                             onPress={() => {
    //                                                 onPressDeliverable && onPressDeliverable(deliverable)
    //                                             }}>
    //                                             <View>
    //                                                 <Icon.ChevronRight size={GlobalStyles.ICON_SIZE} color={GlobalStyles.PRIMARY_500} />
    //                                             </View>
    //                                         </Button>
    //                                     </View>
    //                                 </StackV2>
    //                             </ListItem>
    //                             <Divider />
    //                         </Box>
    //                     )
    //                 })}
    //             </List>
    //         </StackV2>
    //         {props.isLazyLoading && <Loading />}
    //     </StackV2 >
    // </CardV2 >
  )
}

export default CalendarEventList
