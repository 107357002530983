import { Orbit } from '@pickstar/orbit'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'

export const useUnreadNotificationCount = () => {
  const router = useRouter()

  return useQuery({
    queryKey: ['notification-unread-count', router.query.workspace],
    queryFn: async () => {
      const res = await Orbit.Services.notificationService.getUserUnreadNotificationsCount()
      // @ts-expect-error
      return res.data.count
    },
    enabled: !!router.query.workspace,
    refetchInterval: 10000,
  })
}
