import { Types } from '@pickstar/orbit'
import { StyleProp, TextProps, TextStyle, ViewStyle } from 'react-native'
import { SpaceType } from '../Stack/types'
import { TextSizes } from '../Text/types'

export enum LabelDefaults {
  TYPE = 'DEFAULT',
  SIZE = 'base',
  SPACE = '2xs',
  TEXT_SIZE = 'base',
}

export type LabelTypes = Types.Job.StatusTypes | 'OUTLINED' | 'SECONDARY'

export type LabelSizes = 'lg' | 'base' | 'sm'

export type LabelSpaces = SpaceType

export interface LabelProps extends TextProps {
  type: LabelTypes
  text: string
  size?: LabelSizes
  textSize?: TextSizes
  space?: LabelSpaces
  rounded?: boolean
  transparent?: boolean
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  leftElement?: JSX.Element | JSX.Element[]
  rightElement?: JSX.Element | JSX.Element[]
}
