import { Orbit, Types } from '@pickstar/orbit'
import { AppState } from '@redux/store'
import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { ApiResponseError } from '@utils/types/auth'
import { iUserInitialState } from '@utils/types/user'

const initialState: iUserInitialState = {
  user: null,
  users: null,
  listMeta: null,
  getStatus: 'idle',
  listStatus: 'idle',
  updateStatus: 'idle',
  jobUsers: null,
  managementUsers: null,
}

export const listUsers = createAsyncThunk(
  'users/all',
  async (payload: Types.User.iUserListParams, { rejectWithValue }): Promise<Types.User.iUserListResponse | any> => {
    try {
      const response = await Orbit.Services.userService.list(payload)
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const getUser = createAsyncThunk(
  'users/user',
  async (eid: string, { rejectWithValue }): Promise<Types.User.iUserListResponse | any> => {
    try {
      const response = await Orbit.Services.userService.getUser({ eid })
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const listJobUsers = createAsyncThunk(
  'users/job/all',
  async (payload: Types.User.iUserListParams, { rejectWithValue }): Promise<Types.User.iUserListResponse | any> => {
    try {
      const response = await Orbit.Services.userService.listJobUsers(payload)
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const listManagementUsers = createAsyncThunk(
  'users/managers/all',
  async (payload: Types.User.iUserListParams, { rejectWithValue }): Promise<Types.User.iUserListResponse | any> => {
    try {
      const response = await Orbit.Services.userService.listTalentUsers(payload)
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const updateUserRoles = createAsyncThunk(
  'users/updateRoles',
  async (
    { userEid, payload }: { userEid: string; payload: Types.User.iUserUpdateRolePayload },
    { rejectWithValue }
  ): Promise<Types.User.iUser | any> => {
    try {
      const response = await Orbit.Services.userService.updateUserRole({ userEid, payload })
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUserSlice: () => initialState,
    resetUserList: (state: iUserInitialState) => {
      state.users = null
      state.listMeta = null
    },
    setUsers: (state: iUserInitialState, action: PayloadAction<Types.User.iUser[] | null>) => {
      state.users = action.payload
    },
    setUser: (state: iUserInitialState, action: PayloadAction<Types.User.iUser | null>) => {
      state.user = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listUsers.fulfilled, (state: iUserInitialState, action: PayloadAction<Types.User.iUserListResponse>) => {
        state.users = action.payload.data
        state.listMeta = action.payload.meta
        state.listStatus = 'success'
      })
      .addCase(listJobUsers.fulfilled, (state: iUserInitialState, action: PayloadAction<Types.User.iUserListResponse>) => {
        state.jobUsers = action.payload.data
        state.listMeta = action.payload.meta
        state.listStatus = 'success'
      })
      .addCase(listManagementUsers.fulfilled, (state: iUserInitialState, action: PayloadAction<Types.User.iUserListResponse>) => {
        state.managementUsers = action.payload.data
        state.listMeta = action.payload.meta
        state.listStatus = 'success'
      })
      .addCase(updateUserRoles.fulfilled, (state, action) => {
        state.updateStatus = 'success'
      })
      .addCase(updateUserRoles.pending, (state) => {
        state.updateStatus = 'pending'
      })
      .addCase(updateUserRoles.rejected, (state) => {
        state.updateStatus = 'failed'
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.getStatus = 'success'
        state.user = action.payload.data
      })
      .addCase(getUser.pending, (state) => {
        state.getStatus = 'pending'
      })
      .addCase(getUser.rejected, (state) => {
        state.getStatus = 'failed'
      })
      .addMatcher(isAnyOf(listUsers.rejected, listJobUsers.rejected, listManagementUsers.rejected), (state) => {
        state.listStatus = 'failed'
      })
      .addMatcher(isAnyOf(listUsers.pending, listJobUsers.pending, listManagementUsers.pending), (state) => {
        state.listStatus = 'pending'
      })
  },
})

export const { resetUserList, setUsers, resetUserSlice, setUser } = usersSlice.actions
export const { reducer } = usersSlice
export const selectUsers = (state: AppState) => state.users
