import { GlobalStyles } from '@andromeda'
import { ModalHeader } from '@components'
import { Button, Stack, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { acceptInviteAsExistingUser, listInvites, rejectInvite } from '@redux/reducers/inviteReducer'
import { fetchAllWorkspaces } from '@redux/reducers/workspaceReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useMe } from '@utils/query/useMe'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export const AcceptInvite = () => {
  const dispatch = useAppDispatch()

  const { options, closeModal } = useContext(ModalContext)
  const invite = options?.modalProps?.acceptInvite

  const [isLoading, setIsLoading] = useState(false)

  const { data: profile } = useMe()

  const handleAcceptInvite = () => {
    setIsLoading(true)
    toast.promise(
      dispatch(acceptInviteAsExistingUser({ eid: invite?.eid as string })).then((response) => {
        setIsLoading(false)
        if (response.meta.requestStatus === 'rejected') {
          toast.error(response.payload.error?.message || 'Accepting failed.')
        } else {
          toast.success('Invite accepted.')
          dispatch(listInvites())
          dispatch(fetchAllWorkspaces())
          closeModal(ModalPages.ACCEPT_INVITE)
        }
      }),
      {
        pending: 'Accepting Invite..',
      }
    )
  }

  const handleRejectInvite = () => {
    setIsLoading(true)
    trackEvent({
      event: TrackingEventEnums.Invite.INVITE_DECLINE,
      eventProperties: {
        invite: invite?.eid,
      },
    })

    toast.promise(
      dispatch(rejectInvite({ eid: invite?.eid as string })).then((res) => {
        setIsLoading(false)
        if (res.meta.requestStatus === 'rejected') {
          toast.error(res.payload.error?.message || 'Rejecting failed.')
        } else {
          toast.success('Invite rejected.')
          dispatch(listInvites())
          dispatch(fetchAllWorkspaces())
          closeModal(ModalPages.ACCEPT_INVITE)
        }
      }),
      {
        pending: 'Rejecting Invite..',
      }
    )
  }

  return (
    <Stack className="dialog-container" style={{ backgroundColor: GlobalStyles.MONO_WHITE }} width={{ xxs: '100%', sm: '500px' }}>
      <ModalHeader modalTitle="Accept Invitation" modalContext={ModalPages.ACCEPT_INVITE} />
      <Stack className="dialog-content" rowGap="8px">
        <Typography style={{ color: GlobalStyles.MONO_BLACK, textAlign: 'left' }}>
          {invite?.sender.name} has invited you to join {invite?.workspace.name} workspace.
          <br />
          {`You're currently logged in as ${profile?.name}.`}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="end" columnGap="8px" className="dialog-footer">
        <Button variant="outlined" onClick={handleRejectInvite} disabled={isLoading}>
          Decline
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            trackEvent({
              event: TrackingEventEnums.Invite.INVITE_JOIN_EXISTING,
              eventProperties: {
                invite: invite?.eid,
              },
            })

            handleAcceptInvite()
          }}
          disabled={isLoading}>
          Accept
        </Button>
      </Stack>
    </Stack>
  )
}
