import { GlobalStyles } from '@andromeda'
import { Header } from '@components'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { useAppSelector } from '@redux/hooks'
import { selectAuth } from '@redux/reducers/authReducer'
import { FeatureFlagsContext } from '@utils/context/FeatureFlag.context'
import { useGlobalSearch } from '@utils/hooks/useGlobalSearch'
import { LegacyRef, ReactNode, useContext, useRef } from 'react'
import { Drawer, DrawerContext, WorkspacesDrawer } from '../drawer'
import { AppStyles } from '@styles'
import { useDocHeight } from '@utils/hooks/useDocHeight'
import { useWorkspaces } from '@utils/query/useWorkspaces'

type ContainerLayoutProps = {
  workspacesDrawer?: boolean
  navBar?: boolean
  authed?: boolean
  innerDrawer?: boolean
  children: ReactNode
  banner?: ReactNode
  headerButtons?: Array<JSX.Element>
}

export default function Container({
  children,
  workspacesDrawer = true,
  navBar = true,
  innerDrawer = true,
  headerButtons,
  banner,
}: ContainerLayoutProps) {
  const { token } = useAppSelector(selectAuth)

  const { open } = useContext(DrawerContext)
  const { features } = useContext(FeatureFlagsContext)

  const { renderSearchModal } = useGlobalSearch('popup')
  const { innerHeight } = useDocHeight()
  const { data: workspaceList } = useWorkspaces()

  const navigationRef = useRef()

  const menuItemHeightXSS = navBar ? AppStyles.MENU_ITEM_HEIGHT.xxs : '0px'
  const menuItemHeightSM = navBar ? AppStyles.MENU_ITEM_HEIGHT.sm : '0px'
  const height = {
    xxs: `calc(${innerHeight} - ${menuItemHeightXSS} - ${menuItemHeightXSS} - env(safe-area-inset-bottom) - env(safe-area-inset-top))`,
    sm: `calc(${innerHeight} - ${menuItemHeightSM})`,
  }

  return (
    <Stack className="container-height">
      {features.isGlobalSearchEnabled && renderSearchModal()}
      <Stack flex={1} direction={'row'} sx={{ backgroundColor: GlobalStyles.SLATE_50 }}>
        {workspacesDrawer && <WorkspacesDrawer />}
        {!!workspaceList?.length && innerDrawer && (
          <Box display={{ xxs: 'none', sm: 'flex' }}>
            <Drawer />
          </Box>
        )}
        <Stack flex={1} width={`calc(100% - ${workspacesDrawer ? menuItemHeightSM : '0px'} - ${innerDrawer ? menuItemHeightSM : '0px'} )`}>
          {navBar && (
            <div className=" bg-slate" ref={navigationRef as unknown as LegacyRef<HTMLDivElement> | undefined}>
              <Header headerButtons={headerButtons} description={''} />
            </div>
          )}
          <Stack sx={{ overflowX: 'hidden', overflowY: 'auto' }} flex={1} minHeight={height} maxHeight={height}>
            <Stack
              role="main"
              aria-label="content"
              id="content"
              className={`box-border w-full`}
              sx={{
                minHeight: {
                  xxs: `calc(100% - ${menuItemHeightXSS})`,
                  sm: `calc(100% - ${menuItemHeightSM})`,
                },
                alignItems: 'center',
              }}>
              {banner}
              <Stack
                sx={{
                  width: '100%',
                  position: 'relative',
                }}>
                {children}
              </Stack>
            </Stack>
          </Stack>
          {innerDrawer && !open && (
            <Stack
              sx={{
                display: { xxs: 'flex', sm: 'none' },
                paddingBottom: 'env(safe-area-inset-bottom)',
                backgroundColor: GlobalStyles.SLATE_800,
                width: '100%',
                zIndex: 20,
              }}>
              <Drawer />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
