import { Orbit, Types } from '@pickstar/orbit'
import { Draft, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiResponseError } from '@utils/types/auth'
import { iCommentInitialState } from '@utils/types/comments'
import { AppState } from '../store'

const initialState: iCommentInitialState = {
  comments: [],
  requestStatus: {
    create: 'idle',
    read: 'idle',
    update: 'idle',
    delete: 'idle',
    list: 'idle',
  },
}

export const getComments = createAsyncThunk(
  'comments/list',
  async (payload: { type: string; eid: string }, { rejectWithValue }): Promise<[Types.Comment.iComment] | any> => {
    try {
      const response = await Orbit.Services.commentService.list({
        type: payload.type,
        eid: payload.eid,
      })
      return response.data
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const createComment = createAsyncThunk(
  'comments/create',
  async (
    payload: { type: string; eid: string; payload: Types.Comment.iCommentPayload },
    { rejectWithValue }
  ): Promise<Types.Comment.iComment | any> => {
    try {
      const response = await Orbit.Services.commentService.create({ type: payload.type, eid: payload.eid, payload: payload.payload })
      return response.data
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const updateComment = createAsyncThunk<Draft<Types.Comment.iComment>, { eid: string; payload: Types.Comment.iCommentPayload }>(
  'comments/update',
  async ({ eid, payload }): Promise<Types.Comment.iComment> => {
    const response = await Orbit.Services.commentService.update({ eid, payload })
    return response.data
  }
)

export const deleteComment = createAsyncThunk<Draft<any>, { eid: string }>('comments/delete', async ({ eid }): Promise<any> => {
  const response = await Orbit.Services.commentService.delete({ eid })
  return response.data
})

export const commentSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setComments: (state, action) => {
      state.comments = action.payload
    },
    resetCommentSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComments.pending, (state, action) => {
        state.requestStatus.list = 'pending'
        state.comments = []
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.comments = action.payload
        state.requestStatus.list = 'success'
      })
      .addCase(getComments.rejected, (state, action) => {
        state.comments = []
        state.requestStatus.list = 'failed'
      })
      .addCase(createComment.rejected, (state, action) => {})
  },
})

export const { resetCommentSlice, setComments } = commentSlice.actions
export const { reducer } = commentSlice
export const selectComments = (state: AppState) => state.comments
