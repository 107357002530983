import { Button, GlobalStyles, Icon, Stack, Text } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { archiveJobById, selectJobs } from '@redux/reducers/jobsReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { NavigationContext } from '@utils/context/Navigation.context'
import { useContext } from 'react'
import { toast } from 'react-toastify'

export const ArchiveJob = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { viewedJob, deleteJobStatus } = useAppSelector(selectJobs)

  const { closeModal } = useContext(ModalContext)
  const { goToRequests } = useContext(NavigationContext)

  const _handleSubmit = () => {
    toast
      .promise(dispatch(archiveJobById({ jobEid: viewedJob.eid })), {
        pending: 'Archiving job...',
      })
      .then(async (response) => {
        toast.dismiss()
        if (response.meta.requestStatus === 'fulfilled') {
          toast.success('Job has been archived.')
          goToRequests()
        }
        closeModal(ModalPages.ARCHIVE_JOB)
      })
  }

  return (
    <Stack rounded space="none" height="auto" direction="column" style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader modalContext={ModalPages.ARCHIVE_JOB} modalTitle={'Archive Job?'} />
      <Stack
        direction="column"
        align="flex-start"
        space="xs"
        style={{
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingVertical: GlobalStyles.PADDING_SIZES.md,
        }}>
        <Text size="md">You are about to archive this Job. This action cannot be undone. Do you wish to proceed?</Text>
      </Stack>

      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{
          shadowOpacity: 0,
          justifyContent: 'flex-end',
          paddingBottom: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
        }}
        buttonStyles={{
          paddingVertical: 12,
          paddingHorizontal: 18,
          borderRadius: 8,
          backgroundColor: GlobalStyles.MONO_WHITE,
        }}
        buttonTextStyle={{
          color: GlobalStyles.STATUS_NEGATIVE,
          fontWeight: '500',
        }}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => closeModal(ModalPages.ARCHIVE_JOB)}
            textStyle={{ color: GlobalStyles.PRIMARY_500, fontWeight: '500' }}
            text="Cancel"
          />
        }
        onButtonPress={_handleSubmit}
        buttonText={'Archive'}
        loading={deleteJobStatus === 'loading'}
        loadingType={'blue'}
      />
    </Stack>
  )
}
