import { Orbit, Types } from '@pickstar/orbit'
import { Draft, PayloadAction, createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { dataUrlToFile, uploadFileToUrl } from '@utils/functions/helperFunctions'
import { ApiResponseError } from '@utils/types/auth'
import { iTalentErrorMessages, iTalentProfileInitialState } from '@utils/types/talent-profile'
import axios from 'axios'
import { toast } from 'react-toastify'
import { AppState } from '../store'

const initialState: iTalentProfileInitialState = {
  details: {
    display_name: null,
    role: null,
    bio: null,
    location: undefined,
    email: null,
    profileImage: null,
    isCreate: true,
    eid: null,
    profile_image_eid: null,
    files: [],
  },
  aboutMe: null,
  deliverables: null,
  industries: null,
  specialists: null,
  interests: null,
  others: null,
  apiStatus: 'idle',
  viewApiStatus: 'idle',
  managers: null,
  errorMessages: null,
  uploadStatus: 'idle',
  attachTalentFileStatus: 'idle',
}

export const createTalent = createAsyncThunk(
  'talent/create',
  async (payload: Types.Talent.iTalentCreatePayload, { rejectWithValue }): Promise<Types.Talent.iTalentCreateResponse | any> => {
    try {
      const response = await Orbit.Services.talentService.create(payload)
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const updateTalent = createAsyncThunk(
  'talent/update',
  async (
    payload: { eid: string; payload: Types.Talent.iTalentCreatePayload },
    { rejectWithValue }
  ): Promise<Types.Talent.iTalentCreateResponse | any> => {
    try {
      const response = await Orbit.Services.talentService.update(payload)
      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const viewTalent = createAsyncThunk<Draft<Types.Talent.iViewTalentResponse>, { eid: string }>(
  'talents/view',
  async ({ eid }): Promise<Types.Talent.iViewTalentResponse> => {
    const response = await Orbit.Services.talentService.fetchTalentById(eid)

    return response
  }
)

export const updateManagement = createAsyncThunk(
  'talent/updateManagement',
  async (
    payload: { eid: string; payload: Types.Talent.iTalentManagementPayload },
    { rejectWithValue }
  ): Promise<Types.Talent.iTalent | any> => {
    try {
      const response = await Orbit.Services.talentService.updateManagement(payload)
      return response.data
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const updateProfilePicture = createAsyncThunk(
  'talent/updateProfilePicture',
  async (payload: { fileMeta: File; profilePicture: string }, { rejectWithValue }): Promise<Types.User.iUser | any> => {
    try {
      const response = await Orbit.Services.talentService.generateProfileImageUploadUrl({ file_name: payload.fileMeta.name })
      const file = await dataUrlToFile(payload.profilePicture, payload.fileMeta.name)
      await axios.put(response.data.upload_presign_url, file, {
        headers: {
          'Content-Type': payload.fileMeta.type,
        },
      })

      return { profile_image_eid: response.data.eid, full_url: response.data.full_url }
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const uploadTalentAttachment = createAsyncThunk(
  'talents/uploadTalentAttachment',
  async ({ eid, files }: { eid: string; files: File[] }, { rejectWithValue }): Promise<any> => {
    try {
      const file_names = files.map(({ name }) => name)
      const generateURLResponse = await Orbit.Services.talentService.generateTalentFileUploadUrl({ eid, file_names })

      await Promise.all(
        files.map(async (file, index) => {
          const res = await uploadFileToUrl(file, generateURLResponse.data[index].upload_presign_url)
        })
      )

      const response = await Orbit.Services.talentService.attachFiles({
        eid,
        payload: { files: generateURLResponse.data.map(({ eid }) => eid) },
      })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const detachTalentAttachment = createAsyncThunk(
  'talents/detachTalentAttachment',
  async ({ eid, files }: { eid: string; files: string[] }, { rejectWithValue }): Promise<any> => {
    try {
      const response = await Orbit.Services.talentService.detachFiles({
        eid,
        payload: { files },
      })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const generateTalentFileDownloadUrl = createAsyncThunk(
  'talents/generateTalentFileDownloadUrl',
  async ({ eid, files }: { eid: string; files: string[] }, { rejectWithValue }): Promise<any> => {
    try {
      const response = await Orbit.Services.talentService.generateFileDownloadUrl({
        eid,
        payload: { files },
      })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const talentProfileSlice = createSlice({
  name: 'talentProfile',
  initialState,
  reducers: {
    resetTalentProfileSlice: () => initialState,
    resetManagers: (state) => {
      state.managers = null
    },
    setProfileImage: (state: iTalentProfileInitialState, action: PayloadAction<any>) => {
      state.details.profileImage = action.payload
    },
    setDetails: (state: iTalentProfileInitialState, action: PayloadAction<any>) => {
      state.details = action.payload
    },
    setAboutMe: (state: iTalentProfileInitialState, action: PayloadAction<any>) => {
      state.aboutMe = action.payload
    },
    setDeliverables: (state: iTalentProfileInitialState, action: PayloadAction<any>) => {
      state.deliverables = action.payload
    },
    setIndustries: (state: iTalentProfileInitialState, action: PayloadAction<any>) => {
      state.industries = action.payload
    },
    setInterests: (state: iTalentProfileInitialState, action: PayloadAction<any>) => {
      state.interests = action.payload
    },
    setOthers: (state: iTalentProfileInitialState, action: PayloadAction<any>) => {
      state.others = action.payload
    },
    setSpecialists: (state: iTalentProfileInitialState, action: PayloadAction<any>) => {
      state.specialists = action.payload
    },
    resetProfile: (state) => {
      state.details = initialState.details
    },
    setManagers: (state: iTalentProfileInitialState, action: PayloadAction<Types.Talent.iTalentManager[]>) => {
      state.managers = action.payload
    },
    setErrorMessages: (state: iTalentProfileInitialState, action: PayloadAction<iTalentErrorMessages | null>) => {
      state.errorMessages = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewTalent.fulfilled, (state, action: PayloadAction<Types.Talent.iViewTalentResponse>) => {
        const { data } = action.payload

        // @ts-expect-error
        state.details = {
          ...data,
          email: null,
          profileImage:
            data.profile_image && typeof data.profile_image === 'string' ? data.profile_image : data.profile_image?.full_url || null,
          isCreate: false,
        }

        state.managers = data.active_managers

        state.viewApiStatus = 'success'
      })
      .addCase(viewTalent.pending, (state) => {
        state.viewApiStatus = 'pending'
      })
      .addCase(viewTalent.rejected, (state) => {
        state.viewApiStatus = 'failed'
      })
      .addCase(createTalent.fulfilled, (state, action: PayloadAction<Types.Talent.iTalentCreateResponse>) => {
        state.details = initialState.details
        state.errorMessages = null
        state.apiStatus = 'success'
      })
      .addCase(updateTalent.fulfilled, (state: iTalentProfileInitialState, action: PayloadAction<Types.Talent.iTalentCreateResponse>) => {
        // state.details.profileImage = action.payload.data.profile_image?.full_url || null
        const { data } = action.payload

        // @ts-expect-error
        state.details = {
          ...data,
          email: null,
          profileImage:
            data.profile_image && typeof data.profile_image === 'string' ? data.profile_image : data.profile_image?.full_url || null,
          isCreate: false,
        }

        state.managers = data.active_managers
        state.apiStatus = 'success'
      })
      .addCase(updateManagement.fulfilled, (state: iTalentProfileInitialState, action: PayloadAction<Types.Talent.iTalent>) => {
        state.managers = action.payload.active_managers
        state.apiStatus = 'success'
      })
      .addCase(updateManagement.rejected, (state) => {
        state.apiStatus = 'failed'
      })
      .addCase(updateProfilePicture.fulfilled, (state: iTalentProfileInitialState) => {
        state.uploadStatus = 'success'
      })
      .addCase(updateProfilePicture.pending, (state) => {
        state.uploadStatus = 'pending'
      })
      .addCase(updateProfilePicture.rejected, (state) => {
        state.uploadStatus = 'failed'
      })
      .addCase(uploadTalentAttachment.fulfilled, (state: iTalentProfileInitialState, action) => {
        state.details.files = action.payload.files
      })
      .addMatcher(isAnyOf(uploadTalentAttachment.pending, detachTalentAttachment.pending), (state: iTalentProfileInitialState) => {
        state.attachTalentFileStatus = 'loading'
      })
      .addMatcher(isAnyOf(uploadTalentAttachment.rejected, detachTalentAttachment.rejected), (state: iTalentProfileInitialState) => {
        state.attachTalentFileStatus = 'failed'
      })
      .addMatcher(isAnyOf(createTalent.pending, updateTalent.pending, updateManagement.pending), (state) => {
        state.apiStatus = 'pending'
      })
      .addMatcher(isAnyOf(createTalent.rejected, updateTalent.rejected), (state, action) => {
        state.errorMessages = (action.payload as { errors: iTalentErrorMessages }).errors
        toast.error((action.payload as { message: string }).message)
        state.apiStatus = 'failed'
      })
  },
})

export const {
  setAboutMe,
  setErrorMessages,
  setDeliverables,
  setDetails,
  setIndustries,
  setInterests,
  setManagers,
  setOthers,
  setProfileImage,
  setSpecialists,
  resetProfile,
  resetTalentProfileSlice,
  resetManagers,
} = talentProfileSlice.actions
export const { reducer } = talentProfileSlice
export const selectTalentProfile = (state: AppState) => state.talentProfile
