import { Orbit, Types } from '@pickstar/orbit'
import { AppState } from '@redux/store'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiResponseError } from '@utils/types/auth'
import { iSearchInitialState } from '@utils/types/search'

const initialState: iSearchInitialState = {
  results: null,
  pageResults: null,
  searchStatus: 'idle',
  searchKeyword: '',
  meta: null,
  pageMeta: null,
}

export const globalSearch = createAsyncThunk(
  'search',
  async (searchQuery: Types.Search.iSearchPayload, { rejectWithValue }): Promise<any> => {
    try {
      let response = await Orbit.Services.searchService.search({ per_page: '20', ...searchQuery })
      return { data: response, shouldRecordAggregates: !searchQuery.models.length }
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const globalPageSearch = createAsyncThunk(
  'pageSearch',
  async (searchQuery: Types.Search.iSearchPayload, { rejectWithValue }): Promise<any> => {
    try {
      let response = await Orbit.Services.searchService.search({ per_page: '50', ...searchQuery })
      return { data: response, shouldRecordAggregates: !searchQuery.models.length, searchKeyword: searchQuery.keyword }
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const searchSlice = createSlice({
  name: 'searchSlice',
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.results = null
      state.meta = null
    },
    resetSearchPageResults: (state) => {
      state.pageResults = null
      state.pageMeta = null
    },
    setSearchKeyword: (state, action) => {
      state.searchKeyword = action.payload
    },
    copyResultsToPageResults: (state) => {
      state.pageResults = state.results
    },
    resetPageResults: (state) => {
      state.pageResults = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalSearch.pending, (state, action) => {
      state.searchStatus = 'loading'
    })
    builder.addCase(globalSearch.fulfilled, (state, action) => {
      state.searchStatus = 'success'
      state.results = action.payload.data.data
      state.meta = {
        aggregations: action.payload.shouldRecordAggregates ? action.payload.data.meta.aggregations : state.meta?.aggregations,
        pagination: {
          ...action.payload.data.meta.pagination,
          total_results: action.payload.shouldRecordAggregates
            ? action.payload.data.meta.pagination.total_results
            : state.meta?.pagination.total_results,
        },
      }
    })
    builder.addCase(globalPageSearch.fulfilled, (state, action) => {
      state.searchKeyword = action.payload.searchKeyword
      state.searchStatus = 'success'
      state.pageResults = action.payload.data.data
      state.pageMeta = {
        aggregations: action.payload.shouldRecordAggregates ? action.payload.data.meta.aggregations : state.pageMeta?.aggregations,
        pagination: {
          ...action.payload.data.meta.pagination,
          total_results: action.payload.shouldRecordAggregates
            ? action.payload.data.meta.pagination.total_results
            : state.pageMeta?.pagination.total_results,
        },
      }
    })
  },
})

export const { resetSearch, resetSearchPageResults, setSearchKeyword, copyResultsToPageResults, resetPageResults } = searchSlice.actions
export const { reducer } = searchSlice
export const selectSearch = (state: AppState) => state.search
