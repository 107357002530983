import { Orbit, Types } from '@pickstar/orbit'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

export const useDeliverable = (deliverableId?: string) => {
  const router = useRouter()
  const queryClient = useQueryClient()

  const jobEid = useMemo(() => router.query.requestId || router.query.jobEid, [router.query])
  const deliverableEid = useMemo(() => deliverableId || router.query.deliverableId, [deliverableId, router.query.deliverableId])
  const deliverables = queryClient.getQueryData<Types.Deliverable.iDeliverable[]>(['deliverables', jobEid])

  return useQuery({
    queryKey: ['deliverable', deliverableEid],
    queryFn: async () => {
      const res = await Orbit.Services.deliverableService.get({ eid: deliverableEid as string })
      return res.data
    },
    enabled: !!deliverableEid,
    initialData: deliverables?.find(({ eid }) => eid === deliverableEid) ?? undefined,
  })
}
