import { Orbit, Types } from '@pickstar/orbit'
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

export const useDeliverables = (_jobEid?: string) => {
  const router = useRouter()
  const queryClient = useQueryClient()

  const jobEid = useMemo(
    () => _jobEid || router.query.requestId || (router.query.jobEid as string),
    [router.isReady, _jobEid, router.query.jobEid, router.query.requestId]
  )

  const queryKey = ['deliverables', jobEid]

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await Orbit.Services.jobService.getDeliverables({ jobEid: jobEid as string })
      return res.data
    },
    enabled: !!jobEid,
    placeholderData: keepPreviousData,
  })

  const update = (data: Types.Deliverable.iDeliverable[]) => {
    queryClient.setQueryData(queryKey, data)
  }

  const updateDeliverable = (deliverable: Types.Deliverable.iDeliverable) => {
    const deliverables = query.data || []
    if (deliverables.findIndex((d) => d.eid === deliverable.eid) > -1) {
      queryClient.setQueryData(
        ['deliverables', jobEid],
        deliverables.map((d) => (d.eid === deliverable.eid ? { ...d, ...deliverable } : d))
      )
    } else {
      queryClient.setQueryData(['deliverables', jobEid], [deliverable, ...deliverables])
    }
  }

  return { ...query, update, updateDeliverable }
}
