import { Autocomplete, TextField } from '@mui/material'
import { Types } from '@pickstar/orbit'
import { GoogleMapsApiContext } from '@utils/context/GoogleMapApi.context'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useFormContext, ControllerRenderProps, FieldError, Merge, FieldErrorsImpl } from 'react-hook-form'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'

type tAutoCompleteLocation = {
  hasPadding?: boolean
  placeholder?: string
  defaultValue?: string
  onChange: (location: Types.Location.iLocation) => void
  field?: ControllerRenderProps<any, any>
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>
}

export const AutoCompleteLocation = ({ defaultValue, onChange, placeholder, hasPadding = false, field, error }: tAutoCompleteLocation) => {
  const { isLoaded } = useContext(GoogleMapsApiContext)

  const [textfieldValue, setTextfieldValue] = useState({ label: defaultValue ?? '' })

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({ callbackName: 'YOUR_CALLBACK_NAME', debounce: 300 })

  useEffect(() => {
    setTextfieldValue({ label: defaultValue ?? '' })
  }, [defaultValue])

  const handleGetDetails = useCallback(
    (id?: string) => {
      getDetails({ placeId: id as string, fields: ['address_component', 'geometry'] })
        .then((details) => {
          if (typeof details !== 'string' && details.address_components && details.geometry?.location) {
            const MAPPING: { [key: string]: string } = {
              administrative_area_level_1: 'state',
              intersection: 'address_1',
              street_number: 'address_1',
              sublocality_level_1: 'address_2',
              sublocality: 'address_2',
              route: 'address_1',
              locality: 'city',
              country: 'country',
              postal_code: 'postcode',
            }
            const location = details.address_components?.reduce(
              (acc, component) => ({
                ...acc,
                ...(MAPPING[component.types[0]]
                  ? {
                      [MAPPING[component.types[0]]]: (acc[MAPPING[component.types[0]]]
                        ? `${acc[MAPPING[component.types[0]]]} `
                        : ''
                      ).concat(component.long_name),
                    }
                  : {}),
              }),
              {
                address_1: '',
                address_2: null,
                address_3: null,
                city: '',
                country: '',
                state: '',
                postcode: '',
                latitude: details.geometry.location.lat(),
                longitude: details.geometry.location.lng(),
              } as { [key: string]: string | null | number }
            )
            location && onChange(location as unknown as Types.Location.iLocation)
          }
        })
        .catch((err) => {
          console.error('err', err)
        })
    },
    [onChange]
  )

  return (
    <Autocomplete
      value={textfieldValue}
      style={{ width: '100%' }}
      sx={{
        '.MuiAutocomplete-input': { ...(hasPadding ? {} : { padding: '0 !important' }), fontSize: '14px' },
      }}
      onChange={(_, newValue) => {
        // @ts-expect-error
        handleGetDetails(newValue?.id)
        // @ts-expect-error
        setTextfieldValue(newValue)
      }}
      freeSolo
      disabled={!isLoaded || !ready}
      disablePortal
      options={data.map((place) => ({ label: place.description, id: place.place_id, ...place }))}
      renderInput={(params) => (
        <TextField
          {...params}
          {...(field ? field : {})}
          error={!!error}
          onChange={(e) => {
            setValue(e.target.value)
            onChange({
              address_1: e.target.value,
              address_2: null,
              address_3: null,
              city: '',
              country: '',
              state: '',
              postcode: '',
            } as unknown as Types.Location.iLocation)
          }}
          placeholder={placeholder}
        />
      )}
    />
  )
}

export const FormAutoCompleteLocation = ({ name, field, ...props }: tAutoCompleteLocation & { name: string }) => {
  const { formState } = useFormContext()

  const error = Object.keys(formState.errors).length
    ? name.split('.').length === 1
      ? formState.errors[name]
      : // @ts-expect-error
        name.split('.').reduce((acc, curr) => {
          return acc?.[curr]
        }, formState.errors)
    : undefined

  return <AutoCompleteLocation {...props} field={field} error={error} />
}
