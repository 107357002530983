import { Orbit, Types } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { setComments } from '@redux/reducers/commentsReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

export default function useCommentsQuery({ commentableId, type }: { commentableId: string; type: string }) {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const { workspace } = useAppSelector(selectWorkspace)
  const getKey = ['getComments', workspace, type, commentableId]
  const { data, status, isLoading, refetch } = useQuery({
    queryKey: getKey,
    queryFn: async () => {
      const response = await Orbit.Services.commentService.list({ type, eid: commentableId })
      return response.data
    },
    enabled: !!commentableId && !!type && !!workspace,
  })

  const create = useMutation({
    mutationKey: ['createComment', workspace, type, commentableId],
    mutationFn: async ({ payload }: { payload: Types.Comment.iCommentPayload; parent_eid?: string }) => {
      const response = await Orbit.Services.commentService.create({ type, eid: commentableId, payload })
      return response.data
    },
    onSuccess: (createdData, { parent_eid }) => {
      if (parent_eid) {
        queryClient.setQueryData(
          getKey,
          (data || []).map((comment) => {
            if (comment.eid === parent_eid) {
              return {
                ...comment,
                children: [createdData, ...(comment.children || [])],
              }
            }

            return comment
          })
        )
      } else {
        queryClient.setQueryData(getKey, [createdData, ...(data || [])])
      }
    },
    onSettled: () => refetch({ cancelRefetch: true }),
  })

  const update = useMutation({
    mutationKey: ['updateComment', workspace, type, commentableId],
    mutationFn: async (payload: { eid: string; payload: Types.Comment.iCommentPayload }) => {
      const response = await Orbit.Services.commentService.update(payload)
      return response.data
    },
    onSuccess: (updatedData) => {
      function updateCommentData(comment: Types.Comment.iComment) {
        let creator = updatedData.creator

        if (!updatedData.creator.profile_image) {
          creator = {
            ...creator,
            profile_image: comment.creator.profile_image,
          }
        }

        return {
          ...comment,
          ...updatedData,
          creator,
        }
      }

      queryClient.setQueryData(
        getKey,
        (data || []).map((comment) => {
          let _comment = structuredClone(comment)

          if (comment.eid === updatedData.eid) {
            _comment = updateCommentData(comment)
          }

          if (_comment.children?.length) {
            _comment.children = _comment.children.map((reply) => {
              if (reply.eid === updatedData.eid) {
                return updateCommentData(reply)
              }

              return reply
            })
          }

          return _comment
        })
      )
    },
    onSettled: () => refetch({ cancelRefetch: true }),
  })

  const deleteComment = useMutation({
    mutationKey: ['deleteComment', workspace, type, commentableId],
    mutationFn: async (args: { eid: string }) => {
      const response = await Orbit.Services.commentService.delete(args)
      return response.data
    },
    onSuccess: (_data, { eid }) => {
      queryClient.setQueryData(
        getKey,
        (data || [])
          .map((comment) => {
            return {
              ...comment,
              children: (comment.children || []).filter((reply) => reply.eid !== eid),
            }
          })
          .filter((reply) => reply.eid !== eid)
      )
    },
    onSettled: () => refetch({ cancelRefetch: true }),
  })

  useEffect(() => {
    if (data) {
      dispatch(setComments(data))
    }
  }, [dispatch, data])

  return {
    comments: data,
    status,
    isLoading,
    refetch,

    createComment: create.mutate,
    createCommentAsync: create.mutateAsync,
    createCommentStatus: create.status,

    updateComment: update.mutate,
    updateCommentAsync: update.mutateAsync,
    updateCommentStatus: update.status,

    deleteComment: deleteComment.mutate,
    deleteCommentAsync: deleteComment.mutateAsync,
    deleteCommentStatus: deleteComment.status,
  }
}
