import { Button, GlobalStyles, Icon, Stack, Text, View } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { markCompleteDeliverable, selectDeliverable } from '@redux/reducers/deliverableReducer'
import { ModalContext } from '@utils/context/Modal.context'
import { useContext } from 'react'

export const MarkCompleteDeliverable = () => {
  const { toggleModal, options } = useContext(ModalContext)
  const props = options?.modalProps?.markCompleteDel

  const { requestStatus } = useAppSelector(selectDeliverable)
  const dispatch = useAppDispatch()

  const _handleSubmit = () => {
    dispatch(
      markCompleteDeliverable({
        payload: {
          eid: props.deliverableEid,
        },
      })
    ).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        props.onConfirm(response)
      }
    })
  }

  return (
    <Stack rounded space="none" height="auto" direction="column" style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        modalContext="markCompleteDel"
        modalTitle={'Complete Deliverable?'}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
        style={{
          paddingBottom: 0,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.md,
          alignItems: 'center',
        }}
      />

      <View style={{ paddingHorizontal: GlobalStyles.PADDING_SIZES.md, paddingTop: GlobalStyles.PADDING_SIZES.sm }}>
        <Text style={{ fontWeight: '400' }}>Completing this deliverable will lock it from further changes.</Text>
      </View>

      <Stack
        direction="column"
        align="flex-start"
        space="xs"
        style={{
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingVertical: GlobalStyles.PADDING_SIZES.sm,
        }}>
        <Text size="sm" style={{ color: GlobalStyles.MONO_BLACK }}>
          If you require more talent to perform this deliverable you can choose to:
        </Text>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">Enable automated reminders</Text>
        </Stack>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">Enable prompts to complete this job</Text>
        </Stack>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">Improve job sorting on the listing page</Text>
        </Stack>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">Improve reporting accuracy</Text>
        </Stack>
      </Stack>

      {/* <View style={{ paddingHorizontal: GlobalStyles.PADDING_SIZES.md, width: '100%' }}>
                <Divider style={{ borderColor: GlobalStyles.SLATE_100, marginVertical: 0 }} />
            </View> */}

      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{
          shadowOpacity: 0,
          justifyContent: 'flex-end',
          paddingBottom: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
        }}
        buttonStyles={{
          paddingVertical: 12,
          paddingHorizontal: 18,
          borderRadius: 8,
        }}
        buttonTextStyle={{
          color: GlobalStyles.MONO_WHITE,
        }}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => toggleModal('markCompleteDel')}
            textStyle={{ color: GlobalStyles.PRIMARY_500 }}
            text="Cancel"
          />
        }
        onButtonPress={_handleSubmit}
        buttonText={'Mark Complete'}
        loading={requestStatus.markComplete === 'loading'}
        buttonVariant="primary"
        disabled={false}
      />
    </Stack>
  )
}
