import {
  Alert,
  Avatar,
  CardV2,
  CommentThread,
  GlobalStyles,
  Icon,
  Loading,
  RichEditor,
  StackV2,
  Text,
  View,
  iCommentThread,
} from '@andromeda'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Types } from '@pickstar/orbit'
import { useAppSelector } from '@redux/hooks'
import { selectComments } from '@redux/reducers/commentsReducer'
import { getInitials } from '@utils/functions/helperFunctions'
import { useEffect, useState } from 'react'
import { labelStyles } from '../drawer/DeliverableDrawer/Overview'
import { trackEvent } from '@utils/tracking/helpers'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { Stack, StackProps } from '@mui/material'
import CommentsList from './CommentsList'
import { useMe } from '@utils/query/useMe'
import useCommentsQuery from '@utils/hooks/useCommentsQuery'
import { useRouter } from 'next/router'

const Comments = ({
  type,
  commentableId,
  readonly = false,
  permissions = [],
  showAvatar = true,
  mentions = [],
  autoScroll = false,
  scrollToEID,
  sx,
}: {
  type: 'job' | 'deliverable'
  commentableId: string
  readonly?: boolean
  permissions?: Array<string>
  showAvatar?: boolean
  mentions?: Types.User.iUser[]
  sx?: StackProps['sx']
  autoScroll?: boolean
  scrollToEID?: string
}) => {
  const { requestStatus: commentRequestStatus } = useAppSelector(selectComments)
  const { workspace } = useAppSelector(selectWorkspace)
  const [editComment, setEditComment] = useState<iCommentThread | null>(null)
  const [replyComment, setReplyComment] = useState<iCommentThread | null>(null)
  const [isSavingNewComment, setIsSavingNewComment] = useState<boolean>(false)
  const [isSavingComment, setIsSavingComment] = useState<boolean | undefined>(undefined)
  const [isDeletingComment, setIsDeletingComment] = useState<boolean>(false)
  const [actionCommentId, setActionCommentId] = useState<string | number | null>(null)

  const [draftComment, setDraftComment] = useState<string | undefined>(undefined)
  const { comments, createComment, updateComment, deleteComment, status } = useCommentsQuery({ commentableId, type })

  const { data: profile } = useMe()
  const router = useRouter()

  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout
    let cleanupTimeout: NodeJS.Timeout

    if (status === 'success' && autoScroll && !!scrollToEID?.trim()) {
      scrollTimeout = setTimeout(() => {
        const focusElement = document.querySelector(`input[value="${scrollToEID}"]`)?.parentElement

        if (focusElement) {
          focusElement.scrollIntoView({ behavior: 'smooth' })
          focusElement.classList.add('shake-horizontal')

          cleanupTimeout = setTimeout(() => {
            const newQuery = { ...router.query }
            delete newQuery['commentEid']
            router
              .replace(
                {
                  pathname: router.pathname,
                  query: newQuery,
                },
                undefined,
                { shallow: true }
              )
              .finally(() => {
                // clear shake class after animation 1.4s
                focusElement.classList.remove('shake-horizontal')
              })
          }, 1400)
        }
      }, 500)
    }

    return () => {
      scrollTimeout && clearTimeout(scrollTimeout)
      cleanupTimeout && clearTimeout(cleanupTimeout)
    }
  }, [autoScroll, scrollToEID, status, router])

  const _updateDraftComment = (e: string) => {
    setDraftComment(e)
  }

  const _handleSubmit = (e: any, isNew = false, onSuccess: (callback: () => void) => void) => {
    trackEvent({ event: TrackingEventEnums.Others.ADD_COMMENT, eventProperties: { workspace, context: type } })
    if (readonly) return

    const mentions: Record<string, string> = {}

    if (e.mentions?.length) {
      e.mentions.forEach(({ eid, name }: Types.User.iUser) => {
        mentions[eid] = name
      })
    }

    if (!isNew) {
      if (editComment && editComment.eid) {
        setIsSavingComment(true)
        const payload = { content: e.formatted, mentions } as Types.Comment.iCommentPayload
        updateComment(
          { eid: editComment.eid as string, payload },
          {
            onSettled: () => onSuccess(() => setIsSavingComment(false)),
          }
        )
      } else if (replyComment && replyComment.eid) {
        setIsSavingComment(true)
        const payload = {
          content: e.formatted,
          parent_eid: replyComment.eid as string,
          mentions,
        } as Types.Comment.iCommentPayload
        createComment(
          { payload, parent_eid: replyComment.eid.toString() },
          {
            onSettled: () => onSuccess(() => setIsSavingComment(false)),
          }
        )
      }
    } else {
      setIsSavingNewComment(true)
      const payload = { content: e.formatted, mentions } as Types.Comment.iCommentPayload
      createComment(
        { payload },
        {
          onSettled: () => onSuccess(() => setIsSavingNewComment(false)),
        }
      )
    }

    setEditComment(null)
    setReplyComment(null)
    _updateDraftComment('')
  }

  const _handleDelete = (comment: iCommentThread) => {
    if (comment.eid) {
      setActionCommentId(comment.eid)
      setIsDeletingComment(true)
      deleteComment(
        { eid: comment.eid.toString() },
        {
          onSuccess: () => setActionCommentId(null),
          onSettled: () => setIsDeletingComment(false),
        }
      )
    }
  }

  return (
    <StackV2
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      rowGap="24px"
      sx={{ padding: '12px', backgroundColor: 'white', border: `1px solid ${GlobalStyles.SLATE_100}`, borderRadius: '6px', ...sx }}>
      <StackV2 direction="row" gap="12px" alignItems="center" height="40px">
        <Icon.MessageSquare color={GlobalStyles.SLATE_500} width={20} height={20} />
        <Typography {...labelStyles} color={GlobalStyles.SLATE_500}>
          CHATS
        </Typography>
      </StackV2>
      <StackV2>
        <StackV2 alignItems={'flex-start'} direction="row" spacing={2}>
          {showAvatar && (
            <Avatar
              size={'sm'}
              image={profile?.profile_image?.full_url || undefined}
              placeHolder={
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: GlobalStyles.FONT_SIZES.LARGE,
                  }}>
                  {getInitials(profile?.name)}
                </Text>
              }
            />
          )}
          <StackV2 flex={1} rowGap="14px">
            <RichEditor
              onClickSave={(e, onSuccess) => _handleSubmit(e, true, onSuccess)}
              onClickCancel={() => {}}
              isSaving={isSavingNewComment}
              initialValue={draftComment}
              onChange={_updateDraftComment}
              disabled={readonly}
              mentions={mentions}
              type={type}
            />
            <StackV2 direction={'row'} alignItems={'center'} columnGap="6px">
              <Icon.Info size={15} color={GlobalStyles.SLATE_500} />
              <Typography variant={'caption'} style={{ flexWrap: 'wrap', fontSize: '11px', color: GlobalStyles.SLATE_500 }}>
                {`This message will only be shared to viewers on this ${type}`}
              </Typography>
            </StackV2>
          </StackV2>
        </StackV2>
      </StackV2>
      {comments && comments.length > 0 && (
        <StackV2>
          {commentRequestStatus.list === 'pending' ? <Loading /> : <></>}
          {commentRequestStatus.list === 'failed' ? (
            <View style={{ justifyContent: 'center' }}>
              <Alert
                type="warning"
                style={{
                  backgroundColor: GlobalStyles.STATUS_NEGATIVE,
                  borderRadius: 12,
                  // @ts-expect-error
                  paddingVertical: 24,
                  width: '100%',
                }}
                textStyle={{ fontSize: GlobalStyles.FONT_SIZES.SMALL, color: GlobalStyles.MONO_WHITE }}
                innerWrapper={{
                  justifyContent: 'flex-start',
                }}
                leftElement={
                  <Text style={{ marginRight: 12 }}>
                    <Icon.Info size={GlobalStyles.FONT_SIZES.BASE} color={GlobalStyles.MONO_WHITE} />
                  </Text>
                }>
                Error: An error occured while retrieving job comments!
              </Alert>
            </View>
          ) : (
            <Stack
              rowGap="16px"
              sx={{
                borderTop: `1px solid ${GlobalStyles.SLATE_100}`,
              }}>
              <Box />
              <CommentsList
                comments={comments}
                profile={profile}
                mentions={mentions}
                onSubmit={(e, onSuccess) => _handleSubmit(e, false, onSuccess)}
                onPressEdit={(e: iCommentThread | null) => setEditComment(e)}
                onPressReply={(e: iCommentThread | null) => setReplyComment(e)}
                onPressDelete={_handleDelete}
                isDeletingComment={isDeletingComment}
                actionCommentId={actionCommentId}
                isSavingComment={isSavingComment}
                type={type}
              />
            </Stack>
          )}
        </StackV2>
      )}
    </StackV2>
  )
}

export { Comments }
