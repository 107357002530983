import { Avatar, GlobalStyles, Icon } from '@andromeda'
import { ModalHeader } from '@components'
import { Button, CircularProgress, Pagination, Stack, Typography } from '@mui/material'
import { Types } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { resetUsers, selectRoles } from '@redux/reducers/rolesReducer'
import { selectUsers } from '@redux/reducers/usersReducer'
import { listUsers } from '@redux/reducers/usersReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { getInitials } from '@utils/functions/helperFunctions'
import { useContext, useEffect, useMemo, useState } from 'react'

export const InviteUsers = () => {
  const dispatch = useAppDispatch()
  const { users, listMeta, listStatus } = useAppSelector(selectUsers)
  const { users: attachedUsers, listUsersStatus } = useAppSelector(selectRoles)

  const { closeModal, options } = useContext(ModalContext)

  const [selected, setSelected] = useState<Types.User.iUser[]>([])
  const [page, setPage] = useState(1)

  const modalProps = options?.modalProps?.inviteUsers
  // @ts-expect-error
  const userList: Array<Types.User.iUser & { disabled: boolean }> | null = useMemo(() => {
    const attachedUserEids = modalProps?.attachedUserEids || attachedUsers?.map((user) => user.eid)

    if (attachedUserEids) {
      // @ts-expect-error
      return users?.reduce((acc, curr) => {
        return [...acc, { ...curr, disabled: attachedUserEids?.some((eid) => eid === curr.eid) }]
      }, [])
    }
    return users
  }, [attachedUsers, modalProps?.attachedUserEids, users])
  const pagination = useMemo(() => {
    if (listMeta?.pagination) {
      const currentPage = listMeta?.pagination.current_page || 0
      const totalPage = listMeta?.pagination.total_number_of_pages

      return {
        currentPage,
        totalPage,
        nextPage: currentPage + 1 > totalPage ? 0 : currentPage + 1,
        previousPage: currentPage - 1 <= 0 ? 0 : currentPage - 1,
      }
    }
  }, [listMeta])

  useEffect(() => {
    dispatch(listUsers({ page: page.toString() }))
  }, [dispatch, page])

  useEffect(() => {
    return () => {
      dispatch(resetUsers())
    }
  }, [dispatch])

  return (
    <Stack sx={{ backgroundColor: GlobalStyles.MONO_WHITE, borderRadius: '8px', width: { xxs: '100vw', sm: '450px' } }}>
      <ModalHeader
        modalContext={ModalPages.INVITE_USERS}
        modalTitle={'Invite Users'}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
        leftIcon={<Icon.Users size={30} />}
        onCancel={() => closeModal(ModalPages.INVITE_USERS)}
      />
      <Stack padding="12px 24px" rowGap="14px">
        <Stack className="paper-border" maxHeight="500px" overflow="auto">
          {listUsersStatus === 'loading' || listStatus === 'pending' ? (
            <Stack sx={{ justifyContent: 'center', alignSelf: 'center' }}>
              <CircularProgress />
            </Stack>
          ) : (
            userList?.map((user, index) => {
              const { name, profile_image, eid } = user

              return (
                <Stack
                  direction="row"
                  columnGap="12px"
                  alignItems="center"
                  key={eid}
                  padding="16px"
                  borderBottom={index < userList.length - 1 ? `1px solid ${GlobalStyles.SLATE_100}` : 'none'}>
                  <Avatar
                    size={'md'}
                    image={typeof profile_image == 'string' ? profile_image : profile_image?.full_url}
                    imageStyle={{ backgroundColor: GlobalStyles.SLATE_800 }}
                    placeHolder={
                      <Typography fontSize="18px" color="white" textAlign="center">
                        {getInitials(name)}
                      </Typography>
                    }
                  />
                  <Stack justifyContent="center" flex={1}>
                    <Typography fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM} color={GlobalStyles.MONO_BLACK}>
                      {name}
                    </Typography>
                  </Stack>
                  {selected.some((user) => user.eid === eid) ? (
                    <Button
                      variant="contained"
                      sx={{ height: '40px', width: '40px', padding: 0 }}
                      onClick={() => {
                        setSelected((prev) => prev.filter((x) => x.eid !== eid))
                      }}>
                      <Icon.Check size={14} />
                    </Button>
                  ) : (
                    <Button
                      aria-label="add user"
                      variant="outlined"
                      sx={{ height: '40px', width: '40px', padding: 0 }}
                      onClick={() => {
                        setSelected([...selected, user])
                      }}
                      disabled={user.disabled}>
                      <Icon.Plus size={14} />
                    </Button>
                  )}
                </Stack>
              )
            })
          )}
        </Stack>
        <Stack justifyContent="center">
          <Pagination
            page={page}
            size="medium"
            count={pagination?.totalPage}
            shape="rounded"
            onChange={(_, page) => {
              setPage(page)
            }}
            sx={{
              '&.MuiPagination-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          />
        </Stack>
        <Stack direction="row" columnGap="10px" justifyContent="end">
          <Button variant="outlined" onClick={() => closeModal(ModalPages.INVITE_USERS)}>
            Cancel
          </Button>

          <Button variant="contained" onClick={() => modalProps?.onSubmit(selected)}>
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
