import { Orbit, Types } from '@pickstar/orbit'
import { createAsyncThunk, createSlice, Draft, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { ApiResponseError } from '@utils/types/auth'
import { AppState } from '../store'
import { uploadFileToUrl } from '@utils/functions/helperFunctions'

interface iAgreementsState {
  markStatus: string
  createStatus: string
  createDeliverableStatus: string
  updateStatus: string
  agreementList: Types.Agreements.iJobAgreementList[]
}

const initialState: iAgreementsState = {
  markStatus: 'idle',
  createStatus: 'idle',
  createDeliverableStatus: 'idle',
  updateStatus: 'idle',
  agreementList: [],
}

export const createJobAgreement = createAsyncThunk<
  Draft<Types.Agreements.iJobAgreementResponse>,
  { payload: Types.Agreements.iCreateJobAgreementPayload }
>('jobAgreement/create', async (payload): Promise<Types.Agreements.iJobAgreementResponse> => {
  const response = await Orbit.Services.agreementService.createJobAgreement(payload)

  return response
})

export const createDeliverableAgreement = createAsyncThunk<
  Draft<Types.Agreements.iDeliverableAgreementResponse>,
  { payload: Types.Agreements.iCreateDeliverableAgreementPayload }
>('jobAgreement/createDeliverable', async (payload): Promise<Types.Agreements.iDeliverableAgreementResponse> => {
  const response = await Orbit.Services.agreementService.createDeliverableAgreement(payload)

  return response
})

export const listAgreements = createAsyncThunk<Draft<Types.Agreements.iJobAgreementListResponse>, { jobEid: string }>(
  'jobAgreement/list',
  async ({ jobEid }): Promise<Types.Agreements.iJobAgreementListResponse> => {
    const response = await Orbit.Services.agreementService.listAgreements({ jobEid })

    return response
  }
)

export const updateAgreementFee = createAsyncThunk<
  Draft<Types.Agreements.iJobAgreementListResponse>,
  { payload: Types.Agreements.iUpdateAgreementFeePayload }
>('jobAgreement/updateAgreement', async (payload): Promise<Types.Agreements.iJobAgreementListResponse> => {
  const response = await Orbit.Services.agreementService.updateAgreementFee(payload)

  return response
})

export const markAsBooked = createAsyncThunk<
  Draft<Types.Agreements.iJobAgreementListResponse>,
  { jobAgreementEid: string; payload: Types.Agreements.iBookedAgreementPayload }
>('jobAgreement/markAsBooked', async ({ jobAgreementEid, payload }): Promise<Types.Agreements.iJobAgreementListResponse> => {
  const response = await Orbit.Services.agreementService.markAsBooked({ jobAgreementEid, payload })

  return response
})

export const markAsDone = createAsyncThunk<
  Draft<Types.Agreements.iJobAgreementListResponse>,
  { jobAgreementEid: string; payload: Types.Agreements.iMarkAgreementPayload }
>('jobAgreement/markAsDone', async ({ jobAgreementEid, payload }): Promise<Types.Agreements.iJobAgreementListResponse> => {
  const response = await Orbit.Services.agreementService.markAsDone({ jobAgreementEid, payload })

  return response
})

export const markAsAssigned = createAsyncThunk<
  Draft<Types.Agreements.iJobAgreementListResponse>,
  { jobAgreementEid: string; payload: Types.Agreements.iMarkAgreementPayload }
>('jobAgreement/markAsAssigned', async ({ jobAgreementEid, payload }): Promise<Types.Agreements.iJobAgreementListResponse> => {
  const response = await Orbit.Services.agreementService.markAsAssigned({ jobAgreementEid, payload })

  return response
})

export const markAsRemoved = createAsyncThunk<
  Draft<Types.Agreements.iJobAgreementListResponse>,
  { jobAgreementEid: string; payload: Types.Agreements.iRemovedAgreementPayload }
>('jobAgreement/markAsRemoved', async ({ jobAgreementEid, payload }): Promise<Types.Agreements.iJobAgreementListResponse> => {
  const response = await Orbit.Services.agreementService.markAsRemoved({ jobAgreementEid, payload })

  return response
})

export const completeJobAgreement = createAsyncThunk(
  'jobAgreement/complete',
  async (payload: { jobAgreementEid: string }, { rejectWithValue }): Promise<Types.Agreements.iJobAgreementListResponse | any> => {
    try {
      const response = await Orbit.Services.agreementService.completeJobAgreement(payload)
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const updateMultipleDeliverableAgreement = createAsyncThunk(
  'updateMultipleDeliverableAgreement',
  async (
    payload: Types.Agreements.iDeliverableAgreementPayload,
    { rejectWithValue }
  ): Promise<Types.Agreements.iJobAgreementListResponse | any> => {
    try {
      const response = await Orbit.Services.agreementService.updateMultipleDeliverableAgreements({ payload })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const uploadAgreementAttachment = createAsyncThunk(
  'jobAgreement/uploadAgreementAttachment',
  async (
    { eid, files, deliverableEid, expandedJobEid }: { eid: string; files: File[]; deliverableEid: string; expandedJobEid?: string },
    { rejectWithValue }
  ): Promise<Types.User.iUser | any> => {
    try {
      const file_names = files.map(({ name }) => name)
      const generateURLResponse = await Orbit.Services.agreementService.generateFileUploadUrl({ eid, file_names })

      await Promise.all(
        files.map(async (file, index) => {
          const res = await uploadFileToUrl(file, generateURLResponse.data[index].upload_presign_url)
        })
      )

      const response = await Orbit.Services.agreementService.attachFiles({
        eid,
        payload: { files: generateURLResponse.data.map(({ eid }) => eid) },
      })
      return { ...response.data, deliverableEid, expandedJobEid }
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const detachAgreementAttachment = createAsyncThunk(
  'jobAgreement/detachAgreementAttachment',
  async (
    { eid, files, deliverableEid, expandedJobEid }: { eid: string; files: string[]; deliverableEid: string; expandedJobEid?: string },
    { rejectWithValue }
  ): Promise<Types.User.iUser | any> => {
    try {
      const response = await Orbit.Services.agreementService.detachFiles({
        eid,
        payload: { files },
      })
      return { ...response.data, deliverableEid, expandedJobEid }
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const generateAgreementFileDownloadUrl = createAsyncThunk(
  'jobAgreement/generateAgreementFileDownloadUrl',
  async ({ eid, files }: { eid: string; files: string[] }, { rejectWithValue }): Promise<Types.User.iUser | any> => {
    try {
      const response = await Orbit.Services.agreementService.generateFileDownloadUrl({
        eid,
        payload: { files },
      })
      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const agreementSlice = createSlice({
  name: 'agreements',
  initialState,
  reducers: {
    resetAgreementSlice: () => initialState,
    setAgreementList: (state, action: PayloadAction<Types.Agreements.iJobAgreementList[]>) => {
      state.agreementList = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        createDeliverableAgreement.fulfilled,
        (state: iAgreementsState, action: PayloadAction<Types.Agreements.iDeliverableAgreementResponse>) => {
          state.createDeliverableStatus = 'success'
        }
      )
      .addCase(listAgreements.fulfilled, (state: iAgreementsState, action: PayloadAction<Types.Agreements.iJobAgreementListResponse>) => {
        state.agreementList = action.payload.data
      })
      .addCase(createDeliverableAgreement.pending, (state) => {
        state.createDeliverableStatus = 'loading'
      })
      .addCase(updateAgreementFee.fulfilled, (state) => {
        state.updateStatus = 'success'
      })
      .addCase(updateAgreementFee.pending, (state) => {
        state.updateStatus = 'loading'
      })
      .addCase(updateAgreementFee.rejected, (state) => {
        state.updateStatus = 'rejected'
      })
      .addCase(updateMultipleDeliverableAgreement.fulfilled, (state) => {
        state.updateStatus = 'success'
      })
      .addCase(updateMultipleDeliverableAgreement.pending, (state) => {
        state.updateStatus = 'loading'
      })
      .addCase(updateMultipleDeliverableAgreement.rejected, (state) => {
        state.updateStatus = 'rejected'
      })
      .addMatcher(
        isAnyOf(
          markAsBooked.pending,
          markAsAssigned.pending,
          markAsDone.pending,
          markAsRemoved.pending,
          markAsBooked.pending,
          completeJobAgreement.pending
        ),
        (state) => {
          state.markStatus = 'loading'
        }
      )
      .addMatcher(
        isAnyOf(
          markAsBooked.fulfilled,
          markAsAssigned.fulfilled,
          markAsDone.fulfilled,
          markAsRemoved.fulfilled,
          markAsBooked.fulfilled,
          completeJobAgreement.fulfilled
        ),
        (state) => {
          state.markStatus = 'success'
        }
      )
      .addMatcher(
        isAnyOf(
          markAsBooked.rejected,
          markAsAssigned.rejected,
          markAsDone.rejected,
          markAsRemoved.rejected,
          markAsBooked.rejected,
          completeJobAgreement.rejected
        ),
        (state) => {
          state.markStatus = 'rejected'
        }
      )
  },
})

export const { setAgreementList, resetAgreementSlice } = agreementSlice.actions

export const { reducer } = agreementSlice
export const selectAgreement = (state: AppState) => state.agreements
