import { Dropdown, DropdownOption, GlobalStyles, StackV2, Text } from '@andromeda'
import { Types } from '@pickstar/orbit'
import { StyleProp, ViewStyle } from 'react-native'
import { iMenuOption } from '../types'
import JobProgressList from './JobProgressList'

export interface JobProgressProps {
  joblist: Array<Types.Job.iJobList>
  listStyle: StyleProp<ViewStyle>
  onStatusChange: (status: iMenuOption) => void
  onSortingChange: (status: iMenuOption) => void
  statusOptions?: DropdownOption[]
  sortOptions?: DropdownOption[]
  lazyLoad?: () => void
  onClickItem?: (item: Types.Job.iJobList) => void
}

// TODO: Determine the options
const STATUS_OPTIONS: Array<iMenuOption> = [
  { id: 1, label: 'live', value: 'Status 1' },
  { id: 2, label: 'draft', value: 'Status 2' },
]

const SORT_OPTIONS = [
  { id: 1, value: 'Due Date (soonest)', label: 'due-soonest' },
  { id: 2, value: 'Due Date (furthest)', label: 'due-furthest' },
  { id: 3, value: 'Recently updated', label: 'recently-updated' },
  { id: 4, value: 'Least recently updated', label: 'least-recently-updated' },
]

const JobProgress = ({
  joblist,
  listStyle,
  onStatusChange,
  onSortingChange,
  statusOptions = STATUS_OPTIONS,
  sortOptions = SORT_OPTIONS,
  lazyLoad,
  onClickItem = () => {},
}: JobProgressProps) => {
  return (
    <StackV2>
      <StackV2
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        style={{
          flexWrap: 'wrap',
          borderBottomWidth: 1,
          borderBottomColor: GlobalStyles.SLATE_100,
          paddingBottom: '10px',
        }}>
        <Dropdown
          style={{ flex: 1 }}
          labelStyle={{
            color: GlobalStyles.PRIMARY_500,
            fontWeight: GlobalStyles.FONT_WEIGHT_MEDIUM,
          }}
          onSelect={(val) => {
            onStatusChange && onStatusChange(val)
          }}
          selectedItem={statusOptions[0]}
          options={statusOptions}
          optionLabel="label"
          disabled={!joblist.length}
        />
        <StackV2 direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
          <Text size="sm" bold style={{ color: GlobalStyles.SLATE_700 }}>
            Sort by
          </Text>
          <Dropdown
            style={{ backgroundColor: 'red' }}
            labelStyle={{
              color: GlobalStyles.PRIMARY_500,
              fontWeight: GlobalStyles.FONT_WEIGHT_MEDIUM,
            }}
            onSelect={(val) => {
              onSortingChange && onSortingChange(val)
            }}
            type="shadow"
            selectedItem={sortOptions[0]}
            options={sortOptions}
            disabled={!joblist.length}
          />
        </StackV2>
      </StackV2>
      <JobProgressList items={joblist} style={listStyle} lazyLoad={lazyLoad} onClickItem={onClickItem} />
    </StackV2>
  )
}

export default JobProgress
