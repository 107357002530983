import { Orbit, Types } from '@pickstar/orbit'
import { setToken } from '@redux/reducers/authReducer'
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export const BrowserRefContext = createContext<{
  browserRef: any
  setBrowserRef: Dispatch<SetStateAction<any>> | (() => void)
}>({
  browserRef: null,
  setBrowserRef: () => {},
})

export const BrowserRefProvider = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch()

  const [browserRef, setBrowserRef] = useState<any>(null)

  useEffect(() => {
    const eventHandler = (event: any) => {
      dispatch(
        setToken({
          access_token: event.data.token,
        } as Types.Authentication.iAuthToken)
      )
      Orbit.Services.AxiosBaseInstance.defaults.headers.common['Authorization'] = `Bearer ${event.data.token}`
      browserRef?.close()
    }

    if (browserRef) {
      browserRef.addEventListener('message', eventHandler)
    }

    return () => {
      if (browserRef) {
        browserRef.removeEventListener('message', eventHandler)
      }
    }
  }, [browserRef])

  return <BrowserRefContext.Provider value={{ browserRef, setBrowserRef }}>{children}</BrowserRefContext.Provider>
}
