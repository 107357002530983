import {
  Alert,
  Avatar,
  AvatarGroup,
  Button,
  Card,
  DropdownOption,
  GlobalStyles,
  Icon,
  Loading,
  StackV2,
  Text,
  Tooltip,
  View,
  applyResponsiveProp,
  useScreenDimensions,
} from '@andromeda'
import { Chip, ModalHeader, NoMatchResult } from '@components'
import { Stack, Button as MUIButton } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import MuiPagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'
import { Types } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { fetchJobById, fetchJobUsers, leaveJob, selectJobs, setDraftJobUserPayload, syncJobUsers } from '@redux/reducers/jobsReducer'
import { listJobUsers, resetUserList, selectUsers } from '@redux/reducers/usersReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { AppStyles } from '@styles'
import { ModalContext, ModalOptions, ModalPages } from '@utils/context/Modal.context'
import { JobPermission } from '@utils/enum/permissions'
import { JOB_STATUS } from '@utils/enum/status/job'
import { hasPermission } from '@utils/functions/permission'
import { useScreenDimensions as screenDimension } from '@utils/hooks/dimensions'
import { FilterOptions } from '@utils/types/filter'
import { iUser, iUserPermission } from '@utils/types/user'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { InviteUserForm } from 'src/components/user/invite'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { selectRoles } from '@redux/reducers/rolesReducer'
import { useMe } from '@utils/query/useMe'

enum JobMembership {
  OWNER = 'OWNER',
  VIEWER = 'VIEWER',
}

interface iPermissionOption {
  eid: JobMembership
  value: string
  label: string
}

export const PERMISSION_OPTIONS: Array<iPermissionOption> = [
  {
    eid: JobMembership.OWNER,
    value: 'Owner',
    label: 'Owner',
  },
  // {
  //     eid: JobMembership.OWNER,
  //     value: 'Editor',
  //     label: 'Editor',
  // },
  {
    eid: JobMembership.VIEWER,
    value: 'Viewer',
    label: 'Viewer',
  },
]
const NEW_USER_DEFAULT_VALUES = { firstName: '', lastName: '', email: '', roles: [], note: '', ownership_type: PERMISSION_OPTIONS[1].value }

const UserPermission = () => {
  // redux
  const dispatch = useAppDispatch()
  const { jobUsers: users, listMeta: listMeta, listStatus } = useAppSelector(selectUsers)
  const { workspace } = useAppSelector(selectWorkspace)
  const { viewedJobUsers } = useAppSelector(selectJobs)
  const { roles } = useAppSelector(selectRoles)

  const router = useRouter()
  const { requestId } = router.query

  const { closeModal, options, toggleModal } = useContext(ModalContext)
  const { size: screenSize } = useScreenDimensions()
  const { screenSize: dimensionSize } = screenDimension()
  const screenIsMedium = dimensionSize.md

  const [queryString, setQueryString] = useState<string | undefined>(undefined)
  const [usersList, setUsersList] = useState<Array<Types.User.iUser> | null>([])
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [job, setJob] = useState<Types.Job.iJob | undefined>(undefined)
  const [selectedFilters, setSelectedFilters] = useState<FilterOptions[]>([])
  const [searchKeys, setSearchKeys] = useState<string[]>([])
  const [jobIsCompleted, setJobIsCompleted] = useState<boolean>(false)
  const [showMembersOnly, setShowMembersOnly] = useState<boolean>(false)
  const [isCreatingNewUser, setiIsCreatingNewUser] = useState(false)
  // const [form, setForm] = useState<UserFormType>(NEW_USER_DEFAULT_VALUES)

  const guestRole = useMemo(() => (roles ? (roles.find((role) => role.name === 'guest') as Types.Roles.iRole) : undefined), [roles])

  const { data: loggedInUser } = useMe()

  /**
   * Permissions
   */

  const hasUpdatePermissions = hasPermission({
    permissions: job?.permissions,
    allowedPermissions: [JobPermission.UPDATE],
    checkMethod: 'some',
  })

  const hasAddUserPermissions = hasPermission({
    permissions: job?.permissions,
    allowedPermissions: [JobPermission.ADD_USER],
    checkMethod: 'some',
  })

  const [draftSelection, setDraftSelection] = useState<Types.Job.iJobUsersResponse>({ users: [], invites: [] })
  const [draftList, setDraftList] = useState<Array<Types.User.iUser>>([])

  const doFetchUsers = useCallback(
    (params: Types.User.iUserListParams) => {
      dispatch(listJobUsers(params))
    },
    [dispatch]
  )

  /**
   * Fetch the users assigned to this job
   */
  const doFetchJobUsers = useCallback(
    (jobEid: string) => {
      if (requestId) {
        dispatch(fetchJobUsers({ jobEid }))
      }
    },
    [dispatch]
  )

  const doReset = useCallback(() => {
    dispatch(resetUserList())
  }, [dispatch, requestId])

  const doLeaveJob = useCallback(() => {
    if (requestId) {
      toast
        .promise(dispatch(leaveJob({ eid: requestId as string })), {
          pending: 'Leaving job...',
          success: 'Left job successfully!',
          error: 'Error leaving job.',
        })
        .then((res) => {
          closeModal(ModalPages.USERPERMISSION)
          router.replace({
            pathname: '/[workspace]/jobs',
            query: { workspace },
          })
        })
    } else {
      toast.error('You cannot leave job while creating...')
    }
  }, [dispatch])

  const prepareDraftSelection = () => {
    let draftUsers: Types.Job.iJobUsersResponse = { users: [], invites: [] }
    if (viewedJobUsers) {
      draftUsers = { ...viewedJobUsers }
      setDraftList([...(viewedJobUsers.users ?? []), ...(viewedJobUsers.invites ?? [])])
    }
    setDraftSelection(draftUsers)
  }

  useEffect(() => {
    if (users) {
      setUsersList([...(viewedJobUsers?.invites && viewedJobUsers.invites.length ? viewedJobUsers.invites : []), ...users])
    }
  }, [users, viewedJobUsers?.invites])

  // useEffect(() => {
  //     const guestRoleOption: DropdownOption = { id: guestRole?.eid, name: guestRole?.name, value: guestRole?.label }
  //     setForm((prev) => ({ ...prev, roles: [guestRoleOption] }))
  // }, [roles])

  useEffect(() => {
    if (viewedJobUsers) {
      prepareDraftSelection()
    }
  }, [viewedJobUsers])

  useEffect(() => {
    if (options?.modalProps && options.modalProps[ModalPages.USERPERMISSION]) {
      const jobProp = options.modalProps[ModalPages.USERPERMISSION].job
      setJobIsCompleted(jobProp.status === JOB_STATUS.COMPLETED)
      setJob(jobProp)
    }
  }, [options?.modalProps])

  useEffect(() => {
    if (job && viewedJobUsers === null) {
      doFetchJobUsers(job.eid)
    }

    if (hasUpdatePermissions) {
      setShowMembersOnly(false)
      doFetchUsers({ page: '1' })
    } else {
      setShowMembersOnly(true)
    }

    return () => {
      doReset()
    }
  }, [job])

  const _paginationData = useMemo(() => {
    if (listMeta) {
      const currentPage = listMeta?.pagination.current_page || 0
      const totalPage = listMeta?.pagination.total_number_of_pages
      const nextPage = currentPage + 1 > totalPage ? 0 : currentPage + 1
      const previousPage = currentPage - 1 <= 0 ? 0 : currentPage - 1
      const totalResult = listMeta?.pagination.total_results || 0

      return {
        currentPage,
        totalPage,
        nextPage,
        previousPage,
        totalResult,
      }
    }
  }, [listMeta])

  const _filterDraftUsers = (newSearchKeys: Array<string>) => {
    if (newSearchKeys.length > 0) {
      const filteredDraft = draftList.filter((draft) => {
        let hit = 0
        newSearchKeys.forEach((key) => {
          if (draft.name.toLowerCase().includes(key.toLowerCase())) hit++
        })
        return hit > 0
      })
      setDraftList(filteredDraft)
    } else if (viewedJobUsers) {
      setDraftList([...viewedJobUsers.users])
    }
  }

  const _handleSearch = (e: any) => {
    if (e.nativeEvent.key === 'Enter') {
      const searchPillKey = e.target.value.toLowerCase().replace(/[^a-z-A-Z0-9]/g, '')
      const searchPill = { eid: searchPillKey, value: e.target.value }
      const key = e.target.value.trim()

      const params: Types.User.iUserListParams = { page: '1' }
      if (key && !selectedFilters.find((selected) => selected.eid === searchPillKey)) {
        setSelectedFilters([...selectedFilters, searchPill])
        const newSearchKeys = [...searchKeys, key]
        setSearchKeys(newSearchKeys)
        if (newSearchKeys.length) params.query = newSearchKeys
      }

      if (showMembersOnly) {
        _filterDraftUsers(searchKeys)
      } else {
        doFetchUsers(params)
      }
    }
  }

  const _handleUserSelect = (e: { selected: boolean; details: Types.User.iUser }, index: number) => {
    trackEvent({
      event: e.selected ? TrackingEventEnums.Job.JOB_USER_SELECTED : TrackingEventEnums.Job.JOB_USER_UNSELECTED,
      eventProperties: { workspace },
    })
    setHasChanges(true)
    let draftClone: Types.Job.iJobUsersResponse = { users: [], invites: [] }

    if (draftSelection) {
      draftClone = { ...draftSelection }
      if (e.selected) {
        draftClone.users = [...draftClone.users, { ...e.details, ownership_type: 'OWNER' }]
        setDraftSelection(draftClone)
      } else {
        const hasOneRemainingOwner = draftClone?.users?.filter((owner) => owner.ownership_type === 'OWNER').length === 1
        if (hasOneRemainingOwner) {
          // if (draftClone.owners.length === 1) {
          const modalOptions: ModalOptions = {
            hideOthers: false,
          }

          toggleModal(ModalPages.OWNER_REQUIRED, modalOptions)
        } else {
          const isCurrentUser = e.details.eid === loggedInUser?.eid
          if (isCurrentUser) {
            const modalOptions: ModalOptions = {
              hideOthers: false,
              modalProps: {
                [ModalPages.LEAVE_JOB]: {
                  onConfirm: () => {
                    closeModal(ModalPages.LEAVE_JOB)
                    doLeaveJob()
                  },
                },
              },
            }
            toggleModal(ModalPages.LEAVE_JOB, modalOptions)
            return
          }
          draftClone.users = draftClone?.users?.filter((viewer) => viewer.eid !== e.details.eid)
        }
      }
      //
    }
    setDraftSelection(draftClone)
  }

  const _getUserInitials = (user: iUser | iUserPermission) => {
    // const thisUser = users?.find((u) => u.eid === user.eid)
    const words = user.name.split(' ')
    let initials = ''
    words.forEach((word: string) => {
      initials += word[0]
    })
    return initials.substring(0, 2).toUpperCase()
  }

  const _saveUsers = () => {
    if (!hasUpdatePermissions || !hasAddUserPermissions) return false
    // doSaveUsers
    const jobSyncPayload: Types.Job.iJobSyncPayload = {
      viewers: draftSelection?.users?.filter((user) => user.ownership_type === 'VIEWER').map((user) => user.eid),
      owners: draftSelection?.users?.filter((user) => user.ownership_type === 'OWNER').map((user) => user.eid),
    }

    if (requestId) {
      toast
        .promise(dispatch(syncJobUsers({ eid: requestId as string, payload: jobSyncPayload })), {
          pending: 'Saving user selection...',
        })
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            if (requestId) {
              dispatch(fetchJobById({ jobEid: requestId as string }))
              toast.success('Users saved successfully.', { autoClose: 500 })
            }
            closeModal(ModalPages.USERPERMISSION)
          }
          if (response.meta.requestStatus === 'rejected') {
            toast.error('An error occured saving the users. Please try again.', { autoClose: 1000 })
          }
        })
    } else {
      dispatch(setDraftJobUserPayload(draftSelection))
      closeModal(ModalPages.USERPERMISSION)
    }
  }

  const _getTalentProfileImage = (user: iUser | iUserPermission) => {
    if (typeof user.profile_image === 'string') {
      return user.profile_image
    } else if (user.profile_image && user.profile_image.full_url) {
      return user.profile_image.full_url
    }
    return undefined
  }

  const _handlePermissionChange = (e: any) => {
    setHasChanges(true)
    let draftClone = { users: [...draftSelection.users], invites: [...draftSelection.invites] }
    const userIndex = draftClone.users.findIndex((user) => user.eid === e.user.eid)
    const inviteIndex = draftClone.invites.findIndex((user) => user.eid === e.user.eid)

    if (userIndex > -1) {
      draftClone.users[userIndex] = {
        ...draftClone.users[userIndex],
        ownership_type: (e.item as DropdownOption).eid.toUpperCase(),
      }
    }
    if (inviteIndex > -1) {
      draftClone.invites[inviteIndex] = {
        ...draftClone.invites[inviteIndex],
        ownership_type: (e.item as DropdownOption).eid.toUpperCase(),
      }
    }

    setDraftSelection(draftClone)
  }

  const _goToDirectory = () => {
    closeModal(ModalPages.USERPERMISSION)
    router.isReady && router.push(`/${workspace}/directory/users`)
  }

  const _removeFilterFromDropdown = (index: number) => {
    const newSelectedFitlers: FilterOptions[] = []
    let key: FilterOptions | null = null
    selectedFilters.forEach((pill: any, pillIndex: number) => {
      if (index !== pillIndex) newSelectedFitlers.push(pill)
      else key = pill
    })

    let newParams: Types.User.iUserListParams = { page: '1' }
    let oldSearchKeys = searchKeys
    if (key) {
      const newSearchKeys: Array<string> = []
      searchKeys.forEach((str) => {
        if (str !== key?.value) newSearchKeys.push(str)
      })
      oldSearchKeys = newSearchKeys
      setSearchKeys(newSearchKeys)
      if (newSearchKeys.length) newParams.query = newSearchKeys
    }

    setSelectedFilters(newSelectedFitlers)

    if (showMembersOnly) {
      _filterDraftUsers(oldSearchKeys)
    } else {
      doFetchUsers(newParams)
    }
  }

  // const handleChange = (newVal: Partial<UserFormType>) => {
  //     setForm((prev) => ({ ...prev, ...newVal }))
  // }

  const handleInvite = (inviteData: Types.Invite.iInviteCreatePayload) => {
    // const inviteData: Types.Invite.iInviteCreatePayload = {
    //     name: `${form?.firstName} ${form?.lastName}`,
    //     email: form?.email,
    //     roles: form.roles.map((role) => role.id as string),
    //     note: form.note ?? null,
    // }
    if (guestRole && !inviteData?.roles?.some((roleId) => roleId === guestRole?.eid)) {
      inviteData.roles = [...(inviteData.roles ?? []), guestRole?.eid]
    }

    const jobSyncPayload: Types.Job.iJobSyncPayload = {
      owners: draftSelection?.users?.filter((user) => user.ownership_type === 'OWNER').map((user) => user.eid),
      viewers: draftSelection?.users?.filter((user) => user.ownership_type === 'VIEWER').map((user) => user.eid),
      // @ts-expect-error
      invites: [inviteData],
    }

    if (requestId) {
      toast
        .promise(dispatch(syncJobUsers({ eid: requestId as string, payload: jobSyncPayload })), {
          pending: 'Creating invite..',

          error: 'Failed to select users.',
        })
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('Users saved successfully.')
            setiIsCreatingNewUser(false)
            // setForm(NEW_USER_DEFAULT_VALUES)
            // setForm(NEW_USER_DEFAULT_VALUES)
          } else {
            toast.error(response.payload.message)
          }
        })
    }
  }

  return (
    <StackV2
      direction="column"
      overflow={'hidden'}
      height={'auto'}
      className="dialog-container"
      sx={{ backgroundColor: GlobalStyles.MONO_WHITE, width: { sm: '450px' } }}>
      {isCreatingNewUser ? (
        <>
          <ModalHeader
            modalContext={ModalPages.USERPERMISSION}
            leftIcon={<Icon.UserPlus size={20} color={GlobalStyles.SLATE_300} />}
            modalTitle="Create New User"
            backButton={
              <MUIButton
                variant="outlined"
                style={{ height: '40px' }}
                onClick={() => {
                  setiIsCreatingNewUser(false)
                  // setForm(NEW_USER_DEFAULT_VALUES)
                }}>
                <Icon.ArrowLeft size={16} />
              </MUIButton>
            }
            noCloseButton
          />
          <Stack height="700px">
            <InviteUserForm
              // form={form}
              // onChange={handleChange}
              submitOverrideAction={(form) => {
                trackEvent({ event: TrackingEventEnums.Invite.INVITE_USER_TO_JOB_CONFIRM, eventProperties: { workspace } })
                handleInvite(form)
              }}
              showRoleSelection={false}
              submitButtonText="Create New User and Continue"
              miniLabels
              additionalInfo="You will receive an alert when this new user has completed the sign up process. They will be added automatically to this
                            job as well."
              hasOwnerShipType
            />
          </Stack>
        </>
      ) : (
        <StackV2 direction={'column'} justifyContent={'flex-start'}>
          <ModalHeader
            modalContext={ModalPages.USERPERMISSION}
            leftIcon={<Icon.UserPlus size={20} color={GlobalStyles.SLATE_300} />}
            modalTitle="Add Users"
          />
          {/**
           * Search result count and Talent List
           */}
          <StackV2 className="dialog-content" style={{ overflowY: 'auto' }} direction={'column'} flex={1}>
            <StackV2 direction={'column'} justifyContent={'flex-start'} rowGap="16px">
              <StackV2 direction={'row'} style={{ backgroundColor: GlobalStyles.SLATE_50, borderRadius: '6px' }} padding="8px">
                <IconButton type="button" sx={{ sm: { padding: '10px' } }} aria-label="search">
                  <Icon.Search color={GlobalStyles.SLATE_400} />
                </IconButton>
                <InputBase
                  style={{ color: GlobalStyles.SLATE_400, flex: 1 }}
                  inputProps={{ 'aria-label': 'Search items' }}
                  name={'talent-search-string'}
                  placeholder={'Search for user'}
                  value={queryString || ''}
                  onChange={(text) => setQueryString(text.target.value)}
                  onKeyPress={_handleSearch}
                />
              </StackV2>

              {/**
               * Alert
               */}
              {!showMembersOnly && (
                <Stack direction="row" columnGap="12px" alignItems="center">
                  <Icon.Info size={AppStyles.ICON_SIZES.XS} color={GlobalStyles.SLATE_500} />
                  <Text style={{ color: GlobalStyles.SLATE_500 }} size={'tiny'}>
                    Adding users will allow them to receive updates on the job
                  </Text>
                </Stack>
              )}
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text size={'sm'}>
                  <b>{`${_paginationData?.totalResult || 0} Users`}</b>&nbsp;match your criteria
                </Text>
                <MUIButton
                  variant="outlined"
                  startIcon={<Icon.PlusCircle size={16} />}
                  onClick={() => {
                    trackEvent({ event: TrackingEventEnums.Invite.INVITE_USER_TO_JOB, eventProperties: { workspace } })
                    setiIsCreatingNewUser(true)
                  }}>
                  Create New User
                </MUIButton>
              </Stack>

              {/**
               * Selected filters chips
               */}
              {selectedFilters.length > 0 && (
                <StackV2 key={'selected-filters-section'} direction={'row'} height={'auto'}>
                  {selectedFilters.map((chip: FilterOptions, index: number) => {
                    return (
                      <Chip
                        key={`filter-${chip.eid}-${index}`}
                        text={chip.value}
                        textStyle={{ fontSize: GlobalStyles.FONT_SIZES.BASE, color: GlobalStyles.MONO_BLACK }}
                        rightElement={
                          <Button
                            type={'secondary'}
                            style={{
                              width: AppStyles.CHIP_BUTTON_SIZE,
                              height: AppStyles.CHIP_BUTTON_SIZE,
                              padding: 0,
                              backgroundColor: 'transparent',
                            }}
                            onPress={() => _removeFilterFromDropdown(index)}>
                            <Text style={{ color: GlobalStyles.SLATE_700 }}>
                              <Icon.XCircle size={AppStyles.ICON_SIZES.SM} />
                            </Text>
                          </Button>
                        }
                        style={{ marginBottom: AppStyles.MARGIN_SIZES.XS, backgroundColor: '#EEEEEE' }}
                      />
                    )
                  })}
                </StackV2>
              )}
              {listStatus === 'pending' ? (
                <View style={{ justifyContent: 'center' }}>
                  <Loading />
                </View>
              ) : (
                <>
                  {!showMembersOnly && usersList && usersList.length > 0 && (
                    <StackV2 direction={'column'} maxHeight={450} flex={1} spacing={2}>
                      <StackV2 flex={1} key={draftSelection?.users?.length} direction={'column'} spacing={2}>
                        {usersList?.map((user, index) => {
                          let isSelected = false
                          let userRole = PERMISSION_OPTIONS[0]
                          let permissionOptions = PERMISSION_OPTIONS
                          let isOwner =
                            user.eid === loggedInUser?.eid && draftSelection?.users?.some((owner) => owner.eid === loggedInUser?.eid)
                          const isSelectedIndex = draftSelection?.users?.findIndex((owner) => owner.eid === user.eid)
                          if (isSelectedIndex > -1) {
                            isSelected = true
                            switch (draftSelection.users[isSelectedIndex].ownership_type) {
                              case 'OWNER':
                                userRole = PERMISSION_OPTIONS[0]
                                break
                              case 'VIEWER':
                                userRole = PERMISSION_OPTIONS[1]
                                break
                            }
                          }
                          if (draftSelection?.invites?.some((invite) => invite.eid === user.eid)) {
                            isSelected = true
                            userRole = user.ownership_type
                              ? PERMISSION_OPTIONS.find(({ value }) => value.toLowerCase() === user.ownership_type?.toLowerCase()) ??
                                PERMISSION_OPTIONS[1]
                              : PERMISSION_OPTIONS[1]
                          }
                          if (isOwner || hasUpdatePermissions || !requestId) {
                            permissionOptions = PERMISSION_OPTIONS
                          } else {
                            permissionOptions = PERMISSION_OPTIONS.filter((opt) => opt.eid !== JobMembership.OWNER)
                          }
                          const isInvited = user.eid.includes('IN')
                          const userHasNoEditAccess = user.eid === loggedInUser?.eid && !hasUpdatePermissions
                          if (user)
                            return (
                              <Card.User
                                key={Math.random()}
                                userData={{ ...user }}
                                onSelect={_handleUserSelect}
                                selected={isSelected}
                                type={isOwner ? 'owner' : 'user'}
                                avatarPlaceholder={
                                  isInvited ? (
                                    <Stack alignItems="center">
                                      <Icon.Clock size={40} color={GlobalStyles.SLATE_200} />
                                    </Stack>
                                  ) : (
                                    <Text
                                      style={{
                                        textAlign: 'center',
                                        fontSize: GlobalStyles.FONT_SIZES.BASE,
                                      }}>
                                      {_getUserInitials(user)}
                                    </Text>
                                  )
                                }
                                subDetails={
                                  user.eid.includes('IN') ? (
                                    <Typography color={GlobalStyles.SLATE_700} fontSize="12px">
                                      New User - Pending
                                    </Typography>
                                  ) : undefined
                                }
                                showPermissionSelect={!userHasNoEditAccess}
                                showContactButton={false}
                                dropdownOptions={permissionOptions}
                                selectedItem={userRole}
                                onLeave={() => _handleUserSelect({ selected: false, details: user }, index)}
                                onPermissionSelect={_handlePermissionChange}
                                disableMessage={true}
                                showMessageButton={false}
                                nameOverride={user.eid === loggedInUser?.eid ? 'You' : undefined}
                                style={{ borderRadius: AppStyles.BORDER_RADIUS_SIZES.XS }}
                                disabled={isInvited || ((!hasUpdatePermissions || !hasAddUserPermissions) && requestId) || jobIsCompleted}
                                buttonStyle={[
                                  (!hasUpdatePermissions || !hasAddUserPermissions) &&
                                    (requestId || jobIsCompleted) && {
                                      backgroundColor: GlobalStyles.SLATE_100,
                                    },
                                ]}
                                preventSelect={false}
                                showHiddenComponent={userHasNoEditAccess}
                                hiddenComponent={
                                  <Button
                                    type={'outlined'}
                                    text={screenIsMedium ? 'Request Edit Access' : undefined}
                                    textStyle={{
                                      color: GlobalStyles.PRIMARY_500,
                                      fontSize: GlobalStyles.FONT_SIZES.SMALL,
                                    }}
                                    space={screenIsMedium ? '2xs' : 'none'}
                                    style={{
                                      width: 'auto',
                                      height: GlobalStyles.BUTTON_SIZES.MEDIUM,
                                      backgroundColor: GlobalStyles.SLATE_100,
                                    }}
                                    leftElement={
                                      <View>
                                        <Icon.Unlock color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />
                                      </View>
                                    }
                                    disabled={true}
                                  />
                                }
                              />
                            )
                        })}
                      </StackV2>
                    </StackV2>
                  )}
                  {showMembersOnly && draftSelection?.users?.length > 0 && (
                    <StackV2
                      flex={1}
                      key={draftSelection?.users?.length}
                      direction={'column'}
                      style={{
                        paddingLeft: AppStyles.PADDING_SIZES.BASE,
                        paddingRight: AppStyles.PADDING_SIZES.BASE,
                        ...applyResponsiveProp(
                          {
                            lg: {
                              paddingLeft: AppStyles.PADDING_SIZES.XL,
                              paddingRight: AppStyles.PADDING_SIZES.XL,
                            },
                            xl: {
                              paddingLeft: AppStyles.PADDING_SIZES.XL,
                              paddingRight: AppStyles.PADDING_SIZES.XL,
                            },
                            xxl: {
                              paddingLeft: AppStyles.PADDING_SIZES.XL,
                              paddingRight: AppStyles.PADDING_SIZES.XL,
                            },
                            default: null,
                          },
                          screenSize
                        ),
                      }}>
                      {draftList.map((user, index) => {
                        let isSelected = false
                        let userRole = PERMISSION_OPTIONS[0]
                        let permissionOptions = PERMISSION_OPTIONS
                        let isOwner =
                          user.eid === loggedInUser?.eid && draftSelection?.users?.some((owner) => owner.eid === loggedInUser?.eid)

                        if (draftSelection?.users?.some((owner) => owner.eid === user.eid)) {
                          isSelected = true
                          userRole = PERMISSION_OPTIONS[0]
                        }
                        if (draftSelection?.users?.some((viewer) => viewer.eid === user.eid)) {
                          isSelected = true
                          userRole = PERMISSION_OPTIONS[1]
                        }
                        if (isOwner || hasUpdatePermissions || !requestId) {
                          permissionOptions = PERMISSION_OPTIONS
                        } else {
                          permissionOptions = PERMISSION_OPTIONS.filter((opt) => opt.eid !== JobMembership.OWNER)
                        }

                        const userHasNoEditAccess = user.eid === loggedInUser?.eid && !hasUpdatePermissions

                        return (
                          <Card.User
                            key={Math.random()}
                            userData={{
                              ...user,
                            }}
                            subDetails={true ? <Typography>New User - Pending</Typography> : undefined}
                            onSelect={_handleUserSelect}
                            selected={isSelected}
                            type={isOwner ? 'owner' : 'user'}
                            avatarPlaceholder={
                              <Text
                                style={{
                                  textAlign: 'center',
                                  fontSize: GlobalStyles.FONT_SIZES.BASE,
                                }}>
                                {_getUserInitials(user)}
                              </Text>
                            }
                            showPermissionSelect={!userHasNoEditAccess}
                            showContactButton={false}
                            dropdownOptions={permissionOptions}
                            selectedItem={userRole}
                            onLeave={() => _handleUserSelect({ selected: false, details: user }, index)}
                            onPermissionSelect={_handlePermissionChange}
                            disableMessage={true}
                            showMessageButton={false}
                            nameOverride={user.eid === loggedInUser?.eid ? 'You' : undefined}
                            style={{ borderRadius: AppStyles.BORDER_RADIUS_SIZES.XS }}
                            disabled={((!hasUpdatePermissions || !hasAddUserPermissions) && requestId) || jobIsCompleted}
                            buttonStyle={[
                              (!hasUpdatePermissions || !hasAddUserPermissions) &&
                                (requestId || jobIsCompleted) && {
                                  backgroundColor: GlobalStyles.SLATE_100,
                                },
                            ]}
                            preventSelect={false}
                            showHiddenComponent={userHasNoEditAccess}
                            hiddenComponent={
                              <Button
                                type={'outlined'}
                                transparent
                                text={screenIsMedium ? 'Request Edit Access' : undefined}
                                textStyle={{
                                  color: GlobalStyles.SLATE_300,
                                  fontSize: GlobalStyles.FONT_SIZES.SMALL,
                                }}
                                space={screenIsMedium ? '2xs' : 'none'}
                                style={{
                                  width: 'auto',
                                  height: GlobalStyles.BUTTON_SIZES.MEDIUM,
                                  backgroundColor: GlobalStyles.SLATE_100,
                                  borderColor: GlobalStyles.SLATE_100,
                                }}
                                leftElement={
                                  <View>
                                    <Icon.Unlock color={GlobalStyles.SLATE_300} size={GlobalStyles.FONT_SIZES.SMALL} />
                                  </View>
                                }
                                disabled={true}
                              />
                            }
                          />
                        )
                      })}
                    </StackV2>
                  )}
                  {((!showMembersOnly && (!usersList || usersList?.length === 0)) ||
                    (showMembersOnly && draftSelection?.users?.length === 0)) && (
                    <View
                      style={[
                        {
                          width: '100%',
                          paddingHorizontal: AppStyles.PADDING_SIZES.BASE,
                        },
                        applyResponsiveProp(
                          {
                            lg: { paddingHorizontal: AppStyles.PADDING_SIZES.XL },
                            xl: { paddingHorizontal: AppStyles.PADDING_SIZES.XL },
                            xxl: { paddingHorizontal: AppStyles.PADDING_SIZES.XL },
                            default: null,
                          },
                          screenSize
                        ),
                      ]}>
                      <NoMatchResult onPress={_goToDirectory} />
                    </View>
                  )}
                </>
              )}
            </StackV2>
          </StackV2>
          {usersList && usersList.length > 0 && !showMembersOnly && (
            <StackV2 alignItems="center" width="100%" paddingY="16px">
              <MuiPagination
                page={_paginationData?.currentPage || 0}
                size="large"
                count={_paginationData?.totalPage || 0}
                shape="rounded"
                onChange={(_, page) => {
                  const params: Types.User.iUserListParams = { page: page.toString() }
                  if (searchKeys.length) params.query = searchKeys
                  doFetchUsers(params)
                }}
              />
            </StackV2>
          )}
          <StackV2
            direction="row"
            justifyContent={'space-between'}
            alignItems={'center'}
            height={'auto'}
            spacing={2}
            className="dialog-footer"
            style={{
              borderTop: `1px solid ${GlobalStyles.SLATE_100}`,
              borderBottomLeftRadius: GlobalStyles.BORDER_RADIUS,
              borderBottomRightRadius: GlobalStyles.BORDER_RADIUS,
            }}>
            {hasUpdatePermissions && (
              <>
                <StackV2 flex={1} direction="row" height={'auto'} alignItems="flex-start" width={'auto'} style={{ flexWrap: 'wrap' }}>
                  <AvatarGroup offset={5} extraAvatarProps={{ size: 'xs' }}>
                    {[...(draftSelection?.users ?? []), ...(draftSelection.invites ?? [])]?.map((user, index) => (
                      <Tooltip key={user.eid} text={user.name ?? ''} showIcon={false}>
                        <Avatar
                          size={'md'}
                          style={{
                            borderWidth: 2,
                            borderColor: GlobalStyles.MONO_WHITE,
                            alignItems: 'center',
                          }}
                          placeHolder={
                            <Text style={{ textAlign: 'center', fontSize: GlobalStyles.FONT_SIZES.MEDIUM }}>{_getUserInitials(user)}</Text>
                          }
                          image={_getTalentProfileImage(user)}
                        />
                      </Tooltip>
                    ))}
                  </AvatarGroup>
                </StackV2>
                <StackV2 alignItems={'flex-start'} height={'auto'} justifyContent={'flex-end'}>
                  <MUIButton variant="contained" onClick={_saveUsers}>
                    {`Confirm Selection (${draftSelection?.users?.length ?? 0})`}
                  </MUIButton>
                </StackV2>
              </>
            )}
          </StackV2>
        </StackV2>
      )}
    </StackV2>
  )
}

export { UserPermission }
