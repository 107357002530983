import { Orbit, Types } from '@pickstar/orbit'
import { AppState } from '@redux/store'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiResponseError } from '@utils/types/auth'
import { StatusTypes } from '@utils/types/jobs'

type iIdpInitialState = {
  list: Types.IDP.iIDP[]
  status: StatusTypes
}

const initialState: iIdpInitialState = {
  list: [],
  status: 'idle',
}

export const listIdp = createAsyncThunk('idp/list', async (_, { rejectWithValue }) => {
  try {
    return await Orbit.Services.idpService.list()
  } catch (e) {
    console.error(e)
    return rejectWithValue((e as ApiResponseError).response.data)
  }
})
export const callbackIdp = createAsyncThunk(
  'idp/callback',
  async (body: { idp: string } & Types.IDP.iRedirectPayload, { rejectWithValue }) => {
    try {
      const { idp, ...payload } = body
      const response = await Orbit.Services.idpService.callback(idp, payload)

      return response
    } catch (e) {
      console.error(e)
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const redirectIdp = createAsyncThunk('idp/redirect', async (slug: string, { rejectWithValue }) => {
  try {
    const response = await Orbit.Services.idpService.redirect(slug)

    return response
  } catch (e) {
    console.error(e)
    return rejectWithValue((e as ApiResponseError).response.data)
  }
})

export const idpSlice = createSlice({
  name: 'idp',
  initialState,
  reducers: {
    resetIdpSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(listIdp.fulfilled, (state, action) => {
        state.status = 'success'
        state.list = action.payload.data
      })
      .addCase(listIdp.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(listIdp.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const { resetIdpSlice } = idpSlice.actions
export const { reducer } = idpSlice
export const selectIdp = (state: AppState) => state.idp
