import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { publishJob, selectJobs, setViewedJob } from '@redux/reducers/jobsReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { useDeliverables } from '@utils/query/useDeliverables'

export const useJobActions = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { viewedJob } = useAppSelector(selectJobs)
  const { workspace } = useAppSelector(selectWorkspace)
  const { data: deliverables } = useDeliverables()

  const { closeModal, options, toggleModal } = useContext(ModalContext)

  const handlePublishJob = () => {
    toast
      .promise(dispatch(publishJob(viewedJob.eid)), {
        pending: 'Publishing job...',
        success: 'Job has been published to live.',
      })
      .then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(setViewedJob(response.payload))
          queryClient.setQueryData(['jobDetails', workspace, viewedJob.eid], response.payload)
          trackEvent({
            event: TrackingEventEnums.Job.JOB_PUBLISH,
            eventProperties: {
              component_name: 'Job Details Page Header',
              workspace: workspace,
            },
          })
        } else {
          toast.error(response.payload.message || 'Error publishing job.')
        }
      })
  }

  const handleSetJobLive = () => {
    if (viewedJob.eid && deliverables?.length) {
      const missingRequiredDetails = deliverables.find((deliverable) => !deliverable.start_datetime)
      if (missingRequiredDetails) {
        toggleModal(ModalPages.KEY_INFO_MISSING, {
          modalProps: {
            [ModalPages.KEY_INFO_MISSING]: {
              onConfirm: () => {
                closeModal(ModalPages.KEY_INFO_MISSING)
                handlePublishJob()
              },
              addDetails: () => {
                closeModal(ModalPages.KEY_INFO_MISSING)
              },
            },
          },
        })
      } else handlePublishJob()
    }
    toast
  }
  return { handleSetJobLive }
}
