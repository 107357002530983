import { DropdownOption } from '@andromeda'
import { Types } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { getEventTypes, selectJobs } from '@redux/reducers/jobsReducer'
import { useEffect, useState } from 'react'

export const useEventTypes = ({ selectedEvents }: { selectedEvents?: Types.Events.iEventTypes[] }) => {
  const dispatch = useAppDispatch()
  const { events } = useAppSelector(selectJobs)

  const [eventTypeOptions, setEventTypeOptions] = useState<DropdownOption[]>()

  useEffect(() => {
    dispatch(getEventTypes())
  }, [dispatch])

  useEffect(() => {
    if (events && events.length > 0) {
      setEventTypeOptions(
        events
          .map((type: Types.Events.iEventTypes) => {
            return {
              eid: type.eid,
              value: type.name,
              name: type.name,
              subText: type.description,
            }
          })
          .filter((item) => !selectedEvents?.find((selected) => selected.eid === item.eid))
      )
    }
  }, [events, selectedEvents])

  return {
    eventTypeOptions,
  }
}
