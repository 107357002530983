import { DropdownOption } from '@andromeda'
import { Types } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { listOrganisationUsers, listOrganisations, selectRoles } from '@redux/reducers/rolesReducer'
import { listJobUsers, selectUsers } from '@redux/reducers/usersReducer'
import { useEffect, useMemo } from 'react'
import { useLoadOnScroll } from './useLoadOnScroll'

const filterClients = (users: Types.User.iUser[]) =>
  users?.filter(({ roles }) => roles?.some(({ name }) => name.toLowerCase() === 'client'))

export const useClientOrg = ({
  clientId,
  clientName,
  organisation_eid,
  external_client,
}: {
  clientId?: string | null
  clientName?: string | null
  organisation_eid?: string | null
  external_client?: Types.Job.iExternalClient
}) => {
  const dispatch = useAppDispatch()
  const { organisations: organizations, listOrganisationsStatus, organisationslistMeta, users: orgUsers } = useAppSelector(selectRoles)
  const { jobUsers } = useAppSelector(selectUsers)

  const orgLoadOnScroll = useLoadOnScroll({
    list: organizations || [],
    listMeta: organisationslistMeta,
    status: listOrganisationsStatus,
    labelField: 'name',
    fetchList: ({ page, searchTerm }) => dispatch(listOrganisations({ page: String(page), query: searchTerm as string })),
  })

  const orgUserOptions = useMemo<DropdownOption[]>(() => {
    if (organisation_eid) {
      return orgUsers?.length ? filterClients(orgUsers)?.map(({ eid, name }) => ({ id: eid, name })) : []
    }
    return jobUsers?.length ? filterClients(jobUsers).map(({ eid, name }) => ({ id: eid, name })) : []
  }, [organisation_eid, jobUsers, orgUsers])

  const organisationOptions = useMemo<DropdownOption[]>(() => {
    const clientUser = jobUsers?.find(({ eid, name }) => (clientId ? eid === clientId : clientName === name))
    const _organisations = orgLoadOnScroll.options || []

    if (clientId && clientUser?.organisations?.length) {
      return [
        ...(_organisations?.length
          ? _organisations
              .filter(({ eid }) => clientUser.organisations?.some((clientOrg) => clientOrg.eid === eid))
              .map(({ eid, name }) => ({ eid: eid, name: name }))
          : []),
        ...(external_client?.organisation &&
        typeof external_client?.organisation === 'object' &&
        external_client?.organisation?.eid &&
        external_client?.organisation?.name &&
        // @ts-expect-error
        !clientUser.organisations?.some((clientOrg) => clientOrg.eid === external_client?.organisation?.eid)
          ? [{ eid: external_client?.organisation?.eid, name: external_client?.organisation?.name }]
          : []),
      ]
    } else {
      return [
        ...(_organisations?.length ? _organisations?.map(({ eid, name }) => ({ eid: eid, name: name })) : []),
        ...(external_client?.organisation &&
        typeof external_client?.organisation === 'object' &&
        external_client?.organisation?.eid &&
        external_client?.organisation?.name
          ? [{ eid: external_client?.organisation?.eid, name: external_client?.organisation?.name }]
          : []),
      ]
    }
  }, [jobUsers, clientId, clientName, orgLoadOnScroll.options, external_client?.organisation])

  useEffect(() => {
    if (organisation_eid) {
      dispatch(listOrganisationUsers(organisation_eid))
    }
  }, [dispatch, organisation_eid])

  useEffect(() => {
    dispatch(listJobUsers({ page: '1', per_page: '50' }))
  }, [dispatch])

  return {
    orgUserOptions,
    organisationOptions,
    organisationOptionsLoads: orgLoadOnScroll.isLoading,
    searchOrgOptions: orgLoadOnScroll.searchOption,
    loadMoreOrgOptions: orgLoadOnScroll.loadMore,
  }
}
