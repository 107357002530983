import { Badge, GlobalStyles, Icon } from '@andromeda'
import { Box, Button, Chip, Drawer, Tab, Tabs, Typography, Stack, Input, IconButton, Menu, MenuItem } from '@mui/material'
import { Types } from '@pickstar/orbit'
import { NavigationContext, PathEnums } from '@utils/context/Navigation.context'
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Comments } from 'src/components/comments'
import { STATUS_BG_COLORS, STATUS_MAPPING } from '../../job-card/job-card'
import { OverviewTab } from './Overview'
import { useDeliverableActions } from './useDeliverableActions'
import { DELIVERABLE_STATUS } from '@utils/enum/status/deliverable'
import { AppStyles } from '@styles'
import { DeliverableActionsContext } from '@utils/context/DeliverableActions.context'
import { TalentPanel } from './TalentPanel'
import { useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { ActivityPanel } from './Activity'
import { trackEvent } from '@utils/tracking/helpers'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import Link from 'next/link'
import { useMenu } from '@utils/hooks/useMenu'
import { FormProvider, useForm } from 'react-hook-form'
import { Textfield } from 'src/components/form/Textfield'
import moment from 'moment'
import { useRouter } from 'next/router'
import { DELIVERABLE_DRAWER_TABS } from './constants'
import { Status } from './Status'
import { API_DATE_FORMAT } from '@utils/constants/date'
import { IsDirtyContext } from '@utils/context/isDirty.context'
import { useDeliverable } from '@utils/query/useDeliverable'

export type DeliverableDrawerContext = {
  setIsEditable: Dispatch<SetStateAction<boolean>>
  setIsDirty: Dispatch<SetStateAction<boolean>>
  selectedTab: string
}

interface Props {
  onClose: () => void
  onExit: (callback: () => void, context: DeliverableDrawerContext) => void
  onSuccess?: (deliverable?: Types.Deliverable.iDeliverable) => void
  jobEid?: string
}

const tabIconStyles = {
  width: 16,
  height: 16,
}

const tabStyles = {
  padding: '0px 10px',
  borderRight: `1px solid ${GlobalStyles.SLATE_100}`,
  minHeight: '30px',
  height: '30px',
  flex: 1,
}

export const isEditableContext = createContext<{ state: boolean; setState: Dispatch<SetStateAction<boolean>> | (() => void) }>({
  state: false,
  setState: () => {},
})

export const DeliverableFormContext = createContext<{
  deliverableFormState?: any
  setDeliverableFormState: (() => void) | Dispatch<any>
}>({ setDeliverableFormState: () => {} })

interface TabPanelProps {
  children?: React.ReactNode
  value: string
  tabValue: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, tabValue, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== tabValue} {...other}>
      {children}
    </div>
  )
}

export const DeliverableDrawer = ({ jobEid, onClose, onExit, onSuccess, ...props }: Props) => {
  const router = useRouter()

  const { viewedJob, viewedDeliverableUsers } = useAppSelector(selectJobs)
  const { workspace } = useAppSelector(selectWorkspace)

  const { goToRequestById } = useContext(NavigationContext)
  const { isDirty, setIsDirty } = useContext(IsDirtyContext)
  const { setDeliverableToDuplicate, setIsAddingDeliverable } = useContext(DeliverableActionsContext)

  const [selectedTab, setSelectedTab] = useState((router.query.focusPanel as string) ?? 'overview')
  const [isEditable, setIsEditable] = useState(false)
  const [deliverableFormState, setDeliverableFormState] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTalentId, setSelectedTalentId] = useState<string | undefined>(undefined)
  const [visibleEditIcon, setVisibleEditIcon] = useState(false)

  const overviewForm = useForm({ mode: 'onChange' })
  const { data: deliverable, status: deliverableStatusLoads } = useDeliverable()
  const { handlePublish, handleSubmit, handleComplete, handleArchive } = useDeliverableActions({
    costMode: viewedJob.cost_mode,
    deliverable,
    deliverableFormState,
    overviewForm,
    isDirty,
    selectedTab,
    setDeliverableFormState,
    setIsDirty,
    setIsEditable,
    setIsLoading,
    onSuccess,
    onClose,
  })
  const { anchorEl, handleClick, handleClose, open } = useMenu()

  const isDraft = useMemo(() => deliverable?.status === DELIVERABLE_STATUS.DRAFT, [deliverable?.status])
  const assignedTalentEids = useMemo(() => {
    return deliverable?.deliverable_agreements?.length
      ? // @ts-expect-error
        deliverable?.deliverable_agreements?.reduce((acc, curr) => {
          const { talent } = curr.jobAgreement || {}
          // @ts-expect-error
          if (talent && !acc.find((t) => t.eid === talent?.eid)) {
            return [...acc, talent?.eid]
          } else {
            return acc
          }
        }, [])
      : []
  }, [deliverable?.deliverable_agreements])

  const deliverableStatus = (deliverable?.status ?? 'DEFAULT') as Types.Job.StatusTypes

  useEffect(() => {
    setSelectedTalentId(undefined)
  }, [deliverable?.eid])

  useEffect(() => {
    if (router.query.focusPanel) {
      setSelectedTab(router.query.focusPanel as string)
    }
  }, [router.query.focusPanel])

  useEffect(() => {
    setIsDirty(overviewForm.formState.isDirty)
  }, [overviewForm.formState.isDirty, setIsDirty])

  const handleOnExit = (callback: () => void) => {
    onExit(callback, { setIsEditable, setIsDirty, selectedTab })
  }

  useEffect(() => {
    if (deliverable && deliverable.eid !== overviewForm.getValues('eid')) {
      // disable editing upon change
      setIsEditable(false)

      // reset and update form values
      overviewForm.reset({
        name: deliverable.name,
        eid: deliverable.eid,
        selectedCategory: deliverable.deliverable_type.category
          ? {
              id: deliverable.deliverable_type['category_eid'],
              value: deliverable.deliverable_type.category,
            }
          : undefined,
        selectedType:
          deliverable?.deliverable_type &&
          deliverable?.deliverable_type.category &&
          deliverable?.deliverable_type.eid &&
          deliverable?.deliverable_type.name
            ? {
                id: deliverable?.deliverable_type?.eid,
                value: deliverable?.deliverable_type?.name,
              }
            : undefined,
        number_of_talents_required: deliverable?.number_of_talents_required,
        description: deliverable?.description ?? '',
        start_date: deliverable?.start_datetime ? moment(deliverable?.start_datetime, API_DATE_FORMAT) : null,
        end_date: deliverable?.end_datetime ? moment(deliverable?.end_datetime, API_DATE_FORMAT) : null,
        deliverable_cost_per_talent: deliverable?.deliverable_cost_per_talent,
        custom_fields: deliverable?.custom_field_data,
      })
    }
  }, [deliverable?.eid])

  return (
    <Drawer
      key={deliverable?.eid}
      open={!!deliverable}
      variant="persistent"
      anchor="right"
      role="presentation"
      aria-label="deliverable drawer">
      {selectedTalentId ? (
        <TalentPanel
          talentId={selectedTalentId}
          onClose={onClose}
          onBack={() => setSelectedTalentId(undefined)}
          onSuccess={onSuccess}
          costMode={viewedJob.cost_mode}
        />
      ) : deliverable ? (
        <FormProvider key="overview" {...overviewForm}>
          <form>
            <Box
              padding={{ xxs: '12px', sm: '24px' }}
              marginTop="env(safe-area-inset-top)"
              borderBottom={`1px solid ${GlobalStyles.SLATE_100}`}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap="6px" alignItems="center">
                  <Icon.Target width={20} height={20} color={GlobalStyles.SLATE_400} />
                  <Badge
                    role="status"
                    aria-label={deliverableStatus}
                    style={{ width: '12px', height: '12px', backgroundColor: STATUS_BG_COLORS[deliverableStatus.toLowerCase()] }}
                  />
                </Box>
                <Box display="flex" gap="6px" alignItems="center">
                  {selectedTab !== DELIVERABLE_DRAWER_TABS.ACTIVITIES ? (
                    <>
                      <Button
                        variant="outlined"
                        size="large"
                        sx={{ fontSize: '14px' }}
                        onClick={selectedTab === DELIVERABLE_DRAWER_TABS.OVERVIEW ? overviewForm.handleSubmit(handleSubmit) : handleSubmit}
                        disabled={!(overviewForm.formState.isDirty || isDirty) || isLoading}>
                        {isDraft ? 'Save Draft' : 'Save Changes'}
                      </Button>
                      {isDraft && (
                        <Button variant="contained" size="large" sx={{ fontSize: '14px' }} onClick={handlePublish} disabled={isLoading}>
                          Publish
                        </Button>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() =>
                      handleOnExit(() => {
                        onClose()
                      })
                    }
                    sx={{ height: '40px', width: '40px', padding: 0, border: `1px solid ${GlobalStyles.SLATE_100}` }}
                    aria-label="close deliverable drawer">
                    <Icon.X width={16} height={16} />
                  </Button>
                </Box>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                onClick={() => {
                  trackEvent({
                    event: TrackingEventEnums.Others.EDIT_MODE,
                    eventProperties: {
                      workspace,
                      context: 'deliverable',
                    },
                  })
                  setIsEditable(true)
                }}
                onMouseEnter={() => setVisibleEditIcon(true)}
                onMouseLeave={() => setVisibleEditIcon(false)}>
                {isEditable ? (
                  <Stack flex={1}>
                    <Textfield
                      name="name"
                      placeholder="Deliverable Name"
                      sx={{
                        '.MuiOutlinedInput-input': {
                          backgroundColor: 'white',
                          padding: '6px',
                          fontSize: '24px',
                        },
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                    <Typography
                      variant="h2"
                      color={GlobalStyles.MONO_BLACK}
                      fontSize={`${GlobalStyles.FONT_SIZES.XTRA_LARGE}px`}
                      fontWeight={500}
                      lineHeight="32px">
                      {deliverable?.name}
                    </Typography>
                    {visibleEditIcon && <Icon.Edit2 size={16} color={GlobalStyles.PRIMARY_500} />}
                  </Stack>
                )}

                <IconButton
                  size="small"
                  aria-label="deliverable actions"
                  sx={{ minWidth: { xs: 16 }, color: GlobalStyles.SLATE_800, padding: '12px' }}
                  onClick={(e) => {
                    handleClick(e)
                    trackEvent({
                      event: TrackingEventEnums.Deliverable?.DELIVERABLE_ACTIONS_OPEN,
                      eventProperties: {
                        workspace: workspace,
                      },
                    })
                  }}>
                  <Icon.MoreVertical width={12} height={12} />
                </IconButton>
                <Menu
                  id="job-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(e) => {
                    handleClose(e)
                    trackEvent({
                      event: TrackingEventEnums.Deliverable?.DELIVERABLE_ACTIONS_CLOSE,
                      eventProperties: {
                        workspace: workspace,
                      },
                    })
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}>
                  <MenuItem onClick={handleComplete}>Complete</MenuItem>
                  <MenuItem onClick={handleArchive}>Archive</MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleOnExit(() => {
                        setDeliverableToDuplicate(deliverable)
                        setIsAddingDeliverable(true)
                        onClose()
                      })
                    }>
                    Duplicate
                  </MenuItem>
                </Menu>
              </Stack>

              <Stack direction="row" justifyContent="space-between" alignItems="center" marginTop="14px">
                <Link
                  href={{
                    pathname: PathEnums.REQUEST_BY_ID,
                    query: { workspace: workspace, requestId: viewedJob?.eid },
                  }}
                  style={{ textDecoration: 'none' }}>
                  <Typography color={GlobalStyles.PRIMARY_500} fontSize={`${GlobalStyles.FONT_SIZES.TINY}px`}>
                    {viewedJob?.name}
                  </Typography>
                </Link>
              </Stack>
            </Box>
            <Box
              paddingX={{ xxs: '12px', sm: '24px' }}
              paddingY="14px"
              boxSizing="border-box"
              display="flex"
              flexDirection="column"
              gap="14px"
              flex={1}>
              <Tabs
                sx={{
                  '.MuiTabs-indicator': {
                    bottom: '15px',
                    width: { xxs: '70px !important', sm: '80px !important' },
                    margin: '0 10px',
                  },
                }}
                value={selectedTab}
                onChange={(_, val) => {
                  handleOnExit(() => {
                    setSelectedTab(val)
                    router.push(
                      {
                        query: { ...router.query, focusPanel: val },
                      },
                      undefined,
                      { shallow: true }
                    )

                    trackEvent({
                      event: TrackingEventEnums.Deliverable?.DELIVERABLE_PANEL_TAB_SWITCH,
                      eventProperties: {
                        component_name: 'L2 Deliverable Panel',
                        workspace: workspace,
                        tab: ['Overview', 'Status', 'Conversations', 'Activity'][val],
                      },
                    })
                  })
                }}>
                <Tab value={DELIVERABLE_DRAWER_TABS.OVERVIEW} sx={tabStyles} icon={<Icon.Eye {...tabIconStyles} aria-label="Overview" />} />
                <Tab value={DELIVERABLE_DRAWER_TABS.STATUS} sx={tabStyles} icon={<Icon.Users {...tabIconStyles} />} aria-label="Status" />
                <Tab
                  value={DELIVERABLE_DRAWER_TABS.COMMENTS}
                  sx={tabStyles}
                  icon={<Icon.MessageSquare {...tabIconStyles} aria-label="Comments" />}
                />
                <Tab
                  value={DELIVERABLE_DRAWER_TABS.ACTIVITIES}
                  sx={{ ...tabStyles, borderRight: 'none' }}
                  icon={<Icon.Activity {...tabIconStyles} aria-label="Activity" />}
                />
              </Tabs>
              <isEditableContext.Provider value={{ state: isEditable, setState: setIsEditable }}>
                <DeliverableFormContext.Provider value={{ deliverableFormState, setDeliverableFormState }}>
                  <CustomTabPanel value={DELIVERABLE_DRAWER_TABS.OVERVIEW} tabValue={selectedTab}>
                    <OverviewTab deliverable={deliverable} cost_mode={viewedJob.cost_mode} />
                  </CustomTabPanel>
                  <CustomTabPanel value={DELIVERABLE_DRAWER_TABS.STATUS} tabValue={selectedTab}>
                    <Status
                      costMode={viewedJob.cost_mode}
                      eid={deliverable?.eid}
                      jobEid={deliverable?.job?.eid as string}
                      deliverable_agreements={deliverable?.deliverable_agreements}
                      onSuccess={onSuccess}
                      onClickTalent={(talentId) => setSelectedTalentId(talentId)}
                      assignedTalentEids={assignedTalentEids as unknown as string[]}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={DELIVERABLE_DRAWER_TABS.COMMENTS} tabValue={selectedTab}>
                    <Comments
                      showAvatar={false}
                      key={deliverable?.eid as string}
                      type="deliverable"
                      commentableId={deliverable?.eid as string}
                      readonly={false}
                      mentions={viewedDeliverableUsers?.users || []}
                      autoScroll={!!router.query.commentEid && deliverableStatusLoads === 'success'}
                      scrollToEID={router.query.commentEid as string}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={DELIVERABLE_DRAWER_TABS.ACTIVITIES} tabValue={selectedTab}>
                    <ActivityPanel eid={deliverable?.eid} />
                  </CustomTabPanel>
                </DeliverableFormContext.Provider>
              </isEditableContext.Provider>
            </Box>
          </form>
        </FormProvider>
      ) : (
        <></>
      )}
    </Drawer>
  )
}
