import { Orbit, Types } from '@pickstar/orbit'
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { ApiResponseError } from '@utils/types/auth'
import { iRolesInitialState } from '@utils/types/roles'
import { AppState } from '../store'
import { dataUrlToFile } from '@utils/functions/helperFunctions'
import axios from 'axios'

const initialState: iRolesInitialState = {
  departments: null,
  organisations: null,
  roles: null,
  status: 'idle',
  listOrganisationsStatus: 'idle',
  listDepartmentsStatus: 'idle',
  organisationslistMeta: null,
  departmentslistMeta: null,
  createStatus: 'idle',
  listUsersStatus: 'idle',
  users: null,
  listMeta: null,
}

export const listRoles = createAsyncThunk('roles/list', async (_, { rejectWithValue }): Promise<Array<Types.Roles.iRole> | any> => {
  try {
    const response = await Orbit.Services.rolesService.listRoles()

    return response.data
  } catch (e) {
    return rejectWithValue((e as ApiResponseError).response.data)
  }
})

export const listOrganisations = createAsyncThunk(
  'organisations/list',
  async (
    params: Types.Organisation.iListOrganisationsParams,
    { rejectWithValue }
  ): Promise<Array<Types.Organisation.iOrganisation> | any> => {
    try {
      const response = await Orbit.Services.organisationsService.listOrganisations(params)

      return response
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const createOrganisation = createAsyncThunk(
  'organisations/create',
  async (
    payload: Types.Organisation.iOrganisationPayload,
    { rejectWithValue }
  ): Promise<Types.Organisation.iOrganisationResponse | any> => {
    try {
      const response = await Orbit.Services.organisationsService.create(payload)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const updateOrganisation = createAsyncThunk(
  'organisations/update',
  async (
    { eid, payload }: { eid: string; payload: Types.Organisation.iOrganisationPayload },
    { rejectWithValue }
  ): Promise<Types.Organisation.iOrganisationResponse | any> => {
    try {
      const response = await Orbit.Services.organisationsService.update(eid, payload)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const createDepartment = createAsyncThunk(
  'department/create',
  async (payload: Types.Department.iDepartmentPayload, { rejectWithValue }): Promise<Types.Department.iDepartmentResponse | any> => {
    try {
      const response = await Orbit.Services.departmentsService.create(payload)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const updateDepartment = createAsyncThunk(
  'department/update',
  async (
    { eid, payload }: { eid: string; payload: Types.Department.iDepartmentPayload },
    { rejectWithValue }
  ): Promise<Types.Department.iDepartmentResponse | any> => {
    try {
      const response = await Orbit.Services.departmentsService.update(eid, payload)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const listDepartments = createAsyncThunk(
  'departments/list',
  async (params: Types.Organisation.iListOrganisationsParams, { rejectWithValue }): Promise<Array<Types.Department.iDepartment> | any> => {
    try {
      const response = await Orbit.Services.departmentsService.listDepartments(params)

      return response
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const listDepartmentUsers = createAsyncThunk(
  'departments/listUsers',
  async (eid: string, { rejectWithValue }): Promise<Array<Types.User.iUser> | any> => {
    try {
      const response = await Orbit.Services.departmentsService.listUsers(eid)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const listOrganisationUsers = createAsyncThunk(
  'organisations/listUsers',
  async (eid: string, { rejectWithValue }): Promise<Array<Types.User.iUser> | any> => {
    try {
      const response = await Orbit.Services.organisationsService.listUsers(eid)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const attachDepartmentUsers = createAsyncThunk(
  'department/attachUsers',
  async (
    { eid, payload }: { eid: string; payload: Types.Department.iAttachUsersToDepartmentPayload },
    { rejectWithValue }
  ): Promise<Types.Department.iDepartmentResponse | any> => {
    try {
      const response = await Orbit.Services.departmentsService.attachUsers(eid, payload)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)
export const attachOrganisationUsers = createAsyncThunk(
  'organisations/attachUsers',
  async (
    { eid, payload }: { eid: string; payload: Types.Organisation.iAttachUsersToOrganisationPayload },
    { rejectWithValue }
  ): Promise<Types.Organisation.iOrganisationResponse | any> => {
    try {
      const response = await Orbit.Services.organisationsService.attachUsers(eid, payload)

      return response.data
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const updateOrganisationPicture = createAsyncThunk(
  'organisations/updateOrganisationPicture',
  async (payload: { fileMeta: File; profilePicture: string }, { rejectWithValue }): Promise<Types.User.iUser | any> => {
    try {
      const response = await Orbit.Services.organisationsService.generateUploadUrl({ file_name: payload.fileMeta.name })
      const file = await dataUrlToFile(payload.profilePicture, payload.fileMeta.name)
      await axios.put(response.data.upload_presign_url, file, {
        headers: {
          'Content-Type': payload.fileMeta.type,
        },
      })

      return { profile_image_eid: response.data.eid, full_url: response.data.full_url }
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const updateDepartmentPicture = createAsyncThunk(
  'departments/updateDepartmentPicture',
  async (payload: { fileMeta: File; profilePicture: string }, { rejectWithValue }): Promise<Types.User.iUser | any> => {
    try {
      const response = await Orbit.Services.departmentsService.generateUploadUrl({ file_name: payload.fileMeta.name })
      const file = await dataUrlToFile(payload.profilePicture, payload.fileMeta.name)
      await axios.put(response.data.upload_presign_url, file, {
        headers: {
          'Content-Type': payload.fileMeta.type,
        },
      })

      return { profile_image_eid: response.data.eid, full_url: response.data.full_url }
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    resetRoles: () => initialState,
    resetUsers: (state) => ({ ...state, users: null }),
    setDepartments: (state, action) => {
      state.departments = action.payload
    },
    setOrganisations: (state, action) => {
      state.organisations = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listRoles.fulfilled, (state, action) => {
        state.roles = action.payload
        state.status = 'success'
      })
      .addCase(listRoles.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(listRoles.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(listOrganisations.fulfilled, (state, action) => {
        state.organisations = action.payload.data
        state.status = state.listOrganisationsStatus = 'success'
        state.listMeta = state.organisationslistMeta = action.payload.meta
      })
      .addCase(listOrganisations.pending, (state) => {
        state.status = state.listOrganisationsStatus = 'loading'
      })
      .addCase(listOrganisations.rejected, (state) => {
        state.status = state.listOrganisationsStatus = 'failed'
      })
      .addCase(listDepartments.fulfilled, (state, action) => {
        state.departments = action.payload.data
        state.status = state.listDepartmentsStatus = 'success'
        state.listMeta = state.departmentslistMeta = action.payload.meta
      })
      .addCase(listDepartments.pending, (state) => {
        state.status = state.listDepartmentsStatus = 'loading'
      })
      .addCase(listDepartments.rejected, (state) => {
        state.status = state.listDepartmentsStatus = 'failed'
      })
      .addCase(updateOrganisation.fulfilled, (state, action) => {
        let updateOrganisation = [...(state.organisations?.length ? state.organisations : [])]
        let index = updateOrganisation.findIndex((item) => item.eid === action.payload.eid)
        updateOrganisation[index] = action.payload
        state.organisations = updateOrganisation
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        let updateDepartment = [...(state.departments?.length ? state.departments : [])]
        let index = updateDepartment.findIndex((item) => item.eid === action.payload.eid)
        updateDepartment[index] = action.payload
        state.departments = updateDepartment
      })
      .addMatcher(
        isAnyOf(createOrganisation.fulfilled, createDepartment.fulfilled, updateOrganisation.fulfilled, updateDepartment.fulfilled),
        (state) => {
          state.createStatus = 'success'
        }
      )
      .addMatcher(
        isAnyOf(createOrganisation.pending, createDepartment.pending, updateOrganisation.pending, updateDepartment.pending),
        (state) => {
          state.createStatus = 'loading'
        }
      )
      .addMatcher(
        isAnyOf(createOrganisation.rejected, createDepartment.rejected, updateOrganisation.rejected, updateDepartment.rejected),
        (state) => {
          state.createStatus = 'failed'
        }
      )
      .addMatcher(isAnyOf(listOrganisationUsers.fulfilled, listDepartmentUsers.fulfilled), (state, action) => {
        state.listUsersStatus = 'success'
        state.users = action.payload
      })
      .addMatcher(isAnyOf(listOrganisationUsers.pending, listDepartmentUsers.pending), (state) => {
        state.listUsersStatus = 'loading'
      })
      .addMatcher(isAnyOf(listOrganisationUsers.rejected, listDepartmentUsers.rejected), (state) => {
        state.listUsersStatus = 'failed'
      })
  },
})

export const { resetRoles, resetUsers, setOrganisations, setDepartments } = rolesSlice.actions
export const { reducer } = rolesSlice
export const selectRoles = (state: AppState) => state.roles
