import { DropdownOption, GlobalStyles } from '@andromeda'
import { Stack, Typography, Button, TypographyProps } from '@mui/material'
import { UserFormType } from 'src/modals/invite/invite-user'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { listRoles, selectRoles } from '@redux/reducers/rolesReducer'
import { FormField, Label, Row } from './invite'
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { FormAutoComplete } from '../form/AutoComplete'
import { DevTool } from '@hookform/devtools'
import { Types } from '@pickstar/orbit'
import { toast } from 'react-toastify'
import { updateUserRoles } from '@redux/reducers/usersReducer'
import { ModalContext } from '@utils/context/Modal.context'
import { useMe } from '@utils/query/useMe'
import { RolePermissions } from '@utils/enum/permissions'

const borderStyle = `1px solid ${GlobalStyles.SLATE_100}`
const textValueStyles: TypographyProps = {
  fontSize: `${GlobalStyles.FONT_SIZES.SMALL}px`,
  fontWeight: GlobalStyles.FONT_WEIGHT_MEDIUM,
  color: GlobalStyles.SLATE_700,
  height: '21px',
}

export const ManageUserForm = ({ form = { firstName: '', lastName: '', email: '', roles: [], note: '' } }: { form: UserFormType }) => {
  const dispatch = useAppDispatch()
  const { data: me } = useMe()
  const { roles } = useAppSelector(selectRoles)

  const { options, closeModal } = useContext(ModalContext)
  const { userEid = '', onSuccess = () => {} } = options?.modalProps?.inviteUser || {}

  const [roleOptions, setRoleOptions] = useState<DropdownOption[]>([])
  const hookForm = useForm<Pick<UserFormType, 'roles'>>({ defaultValues: { roles: form.roles } })
  const rolesFormState = useWatch({ name: 'roles', control: hookForm.control })

  const existingRestrictedRoles = useMemo(() => {
    return form.roles.filter((role) => !role.permissions?.includes(RolePermissions.ADD_USER))
  }, [form.roles])

  useEffect(() => {
    if (!roles) {
      dispatch(listRoles())
    }
  }, [dispatch, roles])

  useEffect(() => {
    if (roles) {
      setRoleOptions(
        roles.filter((role) => {
          const hasPermission = role.permissions?.includes(RolePermissions.ADD_USER)

          if (existingRestrictedRoles.findIndex((r) => r.eid === role.eid) > -1) return true

          return hasPermission && !rolesFormState?.some((formRole) => formRole.eid === role.eid)
        })
      )
    }
  }, [roles, rolesFormState, existingRestrictedRoles])

  useEffect(() => {
    if (!hookForm.getValues('roles').length) hookForm.setValue('roles', form.roles)
  }, [form.roles, hookForm])

  const handleSubmit: SubmitHandler<Pick<UserFormType, 'roles'>> = (data) => {
    const payload: Types.User.iUserUpdateRolePayload = {
      roles: data.roles.map((role) => role.eid as string),
    }

    if (existingRestrictedRoles.some((role) => payload.roles.findIndex((r) => r === role.eid) < 0)) {
      toast.error(`You do not have permission to update ${existingRestrictedRoles.map((r) => r.label).join(', ')} roles.`)
      return
    }

    toast
      .promise(dispatch(updateUserRoles({ userEid, payload })), {
        pending: 'Updating user..',
      })
      .then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          const { data } = response.payload
          data.roles = payload.roles.map((role) => roles?.find((r) => r.eid === role) as Types.Roles.iRole)
          toast.success('User updated.')
          onSuccess(data)
        } else {
          toast.error(response?.payload?.message || 'Updating user failed.')
        }
      })
  }

  return (
    <FormProvider {...hookForm}>
      <DevTool control={hookForm.control} />
      <form onSubmit={hookForm.handleSubmit(handleSubmit)}>
        <Stack className="dialog-content">
          <Stack border={borderStyle} borderRadius="6px">
            <Row>
              <Label>First Name</Label>
              <FormField disabled>
                <Typography {...textValueStyles}>{form.firstName}</Typography>
              </FormField>
            </Row>
            <Row>
              <Label>Last Name</Label>
              <FormField disabled>
                <Typography {...textValueStyles}>{form.lastName}</Typography>
              </FormField>
            </Row>
            <Row>
              <Label>Organisation</Label>
              <FormField disabled>
                <Typography {...textValueStyles}>{form.organisation || ' '}</Typography>
              </FormField>
            </Row>
            <Row>
              <Label>Role(s)*</Label>
              <FormField>
                <FormAutoComplete multiple name="roles" options={roleOptions} getOptionLabel={(option) => option.label} />
              </FormField>
            </Row>
            <Row>
              <Label>Email</Label>
              <FormField disabled>
                <Typography {...textValueStyles}>{form.email || ' '}</Typography>
              </FormField>
            </Row>
          </Stack>
        </Stack>
        <Stack justifyContent="end" direction="row" className="dialog-footer">
          <Button type="submit" variant="contained" sx={{ width: '130px', padding: '10px 16px', fontSize: GlobalStyles.FONT_SIZES.SMALL }}>
            Save Changes
          </Button>
        </Stack>
      </form>
    </FormProvider>
  )
}
