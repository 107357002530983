import { Avatar, GlobalStyles, Icon } from '@andromeda'
import { ModalHeader } from '@components'
import { Box, Button, Stack, SxProps } from '@mui/material'
import { Types } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { createDepartment, selectRoles } from '@redux/reducers/rolesReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { FeatureFlagsContext } from '@utils/context/FeatureFlag.context'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useImageUpload } from '@utils/hooks/useImageUpload'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { useContext } from 'react'
import { SubmitHandler, useController, useForm, FormProvider } from 'react-hook-form'
import { toast } from 'react-toastify'
import { FieldRow } from './form'
import { FormAutoCompleteLocation } from 'src/components/autoCompleteLocation'
import { Textfield } from 'src/components/form/Textfield'
import { useQueryClient } from '@tanstack/react-query'

export type DepartmentFormState = Types.Department.iDepartmentPayload & { location: Types.Location.iLocation; logo_eid: string | null }

const borderStyle = `1px solid ${GlobalStyles.SLATE_100}`
const inputFieldStyle: SxProps = {
  fontSize: '14px',
}

export const CreateDepartment = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { createStatus } = useAppSelector(selectRoles)
  const { workspace } = useAppSelector(selectWorkspace)

  const { closeModal } = useContext(ModalContext)
  const { features } = useContext(FeatureFlagsContext)

  // React Hook Form Actions
  const form = useForm<DepartmentFormState>({
    defaultValues: {
      name: '',
      location: undefined,
      logo_eid: null,
      phone_number: '',
      note: '',
    },
    mode: 'onSubmit',
  })
  const {
    handleSubmit: doSubmit,
    control,
    reset,
    register,
    formState: { dirtyFields, isDirty, errors },
  } = form

  const {
    field: { value: logoEidValue, onChange: logoEidOnChange },
  } = useController<DepartmentFormState>({ name: 'logo_eid', control })
  const { field: locationField } = useController<DepartmentFormState>({ name: 'location', control })

  const onSubmit: SubmitHandler<DepartmentFormState> = (data) => {
    trackEvent({ event: TrackingEventEnums.Role.DEPARTMENT_CREATED, eventProperties: { workspace } })
    const changes = Object.fromEntries(Object.keys(dirtyFields).map((key: any) => [key, data[key as keyof DepartmentFormState]]))

    toast.promise(
      dispatch(createDepartment({ ...changes })).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          queryClient.refetchQueries({ queryKey: ['listDepartment', workspace], stale: true, type: 'active' })
          closeModal(ModalPages.CREATE_DEPARTMENT)
        } else {
          toast.error(res.payload.message || 'Failed to create department')
        }
      }),
      {
        pending: 'Creating Department...',
      }
    )
  }

  const { toggleModal } = useContext(ModalContext)
  const { image, _handleAvatarSave } = useImageUpload({ onChange: (val) => logoEidOnChange(val), type: 'department' })

  return (
    <FormProvider {...form}>
      <Stack sx={{ backgroundColor: GlobalStyles.MONO_WHITE, borderRadius: '8px' }}>
        <ModalHeader
          modalContext={ModalPages.CREATE_DEPARTMENT}
          modalTitle={'Create New Department'}
          textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
          leftIcon={<Icon.Grid size={30} />}
          onCancel={() => closeModal(ModalPages.CREATE_DEPARTMENT)}
        />

        <Stack padding="12px 24px" rowGap="24px">
          <Stack borderRadius="12px" border={borderStyle}>
            <FieldRow
              label="Logo"
              formComponent={
                <Box position="relative" width={'120px'} height={'120px'}>
                  <Avatar
                    size="lg"
                    image={image?.full_url ?? undefined}
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: 100,
                      // @ts-expect-error
                      border: `2px solid ${GlobalStyles.SLATE_100}`,
                    }}
                    imageStyle={{ width: '120px', height: '120px' }}
                  />
                  <Button
                    variant="outlined"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      borderRadius: '100%',
                      height: '56px',
                      width: '56px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() =>
                      toggleModal(ModalPages.PROFILE_PICTURE_FORM, {
                        modalProps: {
                          profilePictureForm: {
                            title: 'Department Logo',
                            description: 'A logo helps people recognise the department.',
                            uploadText: 'This logo will be used for your department for all users',
                            _handleAvatarSave: _handleAvatarSave,
                          },
                        },
                      })
                    }>
                    <Icon.Camera size="20px" />
                  </Button>
                </Box>
              }
              noBorders
              labelAlignment="center"
            />
            <FieldRow
              label="Name"
              formComponent={
                <Textfield
                  name="name"
                  placeholder={`Add department Name`}
                  sx={{
                    '.MuiOutlinedInput-input': {
                      backgroundColor: 'white',
                      padding: '14px',
                      fontSize: '14px',
                    },
                  }}
                />
              }
            />
            <FieldRow
              label="Address"
              formComponent={
                features.isGoogleMapsApiEnabled ? (
                  <FormAutoCompleteLocation
                    name="location"
                    onChange={locationField.onChange}
                    placeholder={`Add department Address`}
                    hasPadding
                  />
                ) : (
                  <Textfield name="location" placeholder={`Add department Address`} />
                )
              }
            />
            <FieldRow
              label="Phone"
              formComponent={
                <Textfield
                  name="phone_number"
                  registerOptions={{ required: false }}
                  placeholder="Add Phone Number"
                  type="phone"
                  sx={{
                    '.MuiOutlinedInput-input': {
                      backgroundColor: 'white',
                      padding: '14px',
                      fontSize: '14px',
                    },
                  }}
                />
              }
            />
            <FieldRow
              label="Add a note"
              formComponent={
                <Textfield name="note" registerOptions={{ required: false }} multiline placeholder="Add a note to welcome your new user" />
              }
              minHeight="128px"
            />
          </Stack>
          <Stack direction="row" justifyContent="end">
            <Button variant="contained" onClick={doSubmit(onSubmit)} disabled={createStatus === 'loading'}>
              Confirm
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  )
}
