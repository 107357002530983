import { Avatar, AvatarGroup, Badge, GlobalStyles, Icon, Tooltip } from '@andromeda'
import { Box, Button, Chip, Grid, Grid2Props, IconButton, Stack, Typography } from '@mui/material'
import { Types } from '@pickstar/orbit'
import { useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { DeliverableActionsContext } from '@utils/context/DeliverableActions.context'
import { DeliverablePermission } from '@utils/enum/permissions'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { PermissionWrapper } from '../hocs'
import {
  ColumnHeader,
  DELIVERABLE_ROW_SIZES,
  JOB_ROW_SIZES,
  OVERFLOW_TEXT,
  STATUS_BG_COLORS,
  STATUS_MAPPING,
  TYPE_CHIP_STYLES,
} from '../job-card/job-card'
import { useCardWidth } from '../job-card/useCardWidth'
import { QuickLook } from '../QuickLook'
import { DeliverableOnlyInlineCreate } from '../inline-create/deliverable-only'
import { DELIVERABLE_DRAWER_TABS } from '../drawer/DeliverableDrawer/constants'
import { useCurrencyCode } from '@utils/hooks/useCurrencyCode'
import { useCurrencyFormat } from '@utils/hooks/useCurrencyFormat'

const getDuration = (start_datetime: string, end_datetime: string | null) => {
  const diffInDays = Math.abs(moment(end_datetime).diff(start_datetime, 'days'))
  if (diffInDays === 0) {
    if (moment(end_datetime).diff(start_datetime, 'hours') === 0) {
      return `${Math.abs(moment(end_datetime).diff(start_datetime, 'minutes'))} mins`
    }
    return `${Math.abs(moment(end_datetime).diff(start_datetime, 'hours'))} hours`
  }
  return `${diffInDays} days`
}

const StartEndDate = ({
  deliverable_end_datetime,
  deliverable_start_datetime,
  selectedDeliverableEid,
}: {
  deliverable_start_datetime: string
  deliverable_end_datetime: string
  selectedDeliverableEid?: string
}) => (
  <Typography
    {...OVERFLOW_TEXT}
    whiteSpace={{ xxs: 'normal', md: selectedDeliverableEid ? 'normal' : 'nowrap' }}
    variant="subtitle2"
    sx={{ display: { sm: 'none' } }}
    fontSize={'12px'}
    color={GlobalStyles.SLATE_700}>
    {`${deliverable_start_datetime} - ${deliverable_end_datetime}`}
  </Typography>
)

const AvatarEl = ({ users, label }: { users?: Array<Types.User.iUser | Types.Talent.iTalent> | null; label: string }) =>
  users?.length ? (
    <QuickLook
      data={{
        [label]: users.map((user) => ({
          eid: user.eid,
          name: (user as Types.Talent.iTalent).display_name || (user as Types.User.iUser)['name'],
          profile_image: user.profile_image?.full_url,
        })),
      }}>
      <Button variant="text" size="small" sx={{ padding: 0, width: 'fit-content' }} aria-label="toggle deliverable panel status tab">
        <AvatarGroup offset={3} extraAvatarProps={{ size: 'xs' }}>
          {users?.map((user) => {
            if (!user) return <></>
            return (
              <Avatar
                key={user.eid}
                size="xs"
                image={user.profile_image?.full_url ?? undefined}
                placeHolder={<Icon.User size={GlobalStyles.AVATAR_SIZES.XS - 5} color={GlobalStyles.SLATE_200} strokeWidth={1} />}
                style={{
                  borderWidth: 2,
                  borderColor: GlobalStyles.SLATE_200,
                }}
              />
            )
          })}
        </AvatarGroup>
      </Button>
    </QuickLook>
  ) : (
    <></>
  )

const ChatEl = ({ onClickDeliverable }: { onClickDeliverable: (e: any) => void }) => (
  <Stack direction="row" justifyContent="space-between" onClick={onClickDeliverable}>
    <IconButton
      size="small"
      sx={{ minWidth: 15, color: GlobalStyles.SLATE_700, padding: 0 }}
      aria-label="toggle deliverable panel comments tab">
      <Icon.MessageSquare size={20} color={GlobalStyles.PRIMARY_500} />
    </IconButton>
  </Stack>
)

export type tDeliverableList = {
  deliverables: Types.Deliverable.iDeliverable[]
  onClickDeliverable: (deliverable: Types.Deliverable.iDeliverable | null, tab?: string) => void
  selectedDeliverableEid?: string
  gridProps?: (isPanelOpen: boolean) => {
    title: Grid2Props
    type: Grid2Props
    date: Grid2Props
    duration: Grid2Props
    budget: Grid2Props
    talents: Grid2Props
    users: Grid2Props
    action: Grid2Props
  }
  hasBorders?: boolean
  jobEid: string
  onSuccessCreate: (deliverable?: Types.Deliverable.iDeliverable) => void
  costMode: Types.Job.iJob['cost_mode']
  talents?: Types.Talent.iTalent[]
}

export const DeliverableList = ({
  deliverables = [],
  onClickDeliverable,
  selectedDeliverableEid,
  gridProps = DELIVERABLE_ROW_SIZES,
  hasBorders = false,
  jobEid,
  onSuccessCreate,
  costMode,
  talents,
}: tDeliverableList) => {
  const { workspace } = useAppSelector(selectWorkspace)
  const { viewedJob } = useAppSelector(selectJobs)

  const { deliverableToDuplicate, setDeliverableToDuplicate, isAddingDeliverable, setIsAddingDeliverable } =
    useContext(DeliverableActionsContext)

  const [isDuplicateVisible, setIsDuplicateVisible] = useState<string | null>(null)
  const [isEditVisible, setIsEditVisible] = useState<string | null>(null)

  const rowRef = useRef<HTMLDivElement>(null)
  const rowSize = useCardWidth(rowRef, selectedDeliverableEid)
  const currenyCodeOptions = useCurrencyCode()
  const formatCurrency = useCurrencyFormat()

  useEffect(() => {
    return () => {
      setIsAddingDeliverable(false)
      setDeliverableToDuplicate(undefined)
    }
  }, [setDeliverableToDuplicate, setIsAddingDeliverable])

  return (
    <Stack>
      {!hasBorders && (
        <Stack direction="row" alignItems="center" columnGap="10px" display={{ sm: 'none' }} marginLeft="24px" paddingY="12px">
          <Icon.Target size={12} color={GlobalStyles.SLATE_400} />
          <ColumnHeader className="!font-bold !text-slate-400" sx={{ overflow: 'visible' }}>
            DELIVERABLE
          </ColumnHeader>
        </Stack>
      )}
      <Stack
        borderBottom={deliverables.length && !hasBorders ? `1px solid ${GlobalStyles.SLATE_100}` : ''}
        marginLeft={{ xxs: '24px', sm: '12px' }}
        marginRight={{ xxs: '24px', sm: '8px' }}
        marginTop={{ xxs: 0, sm: hasBorders ? '0' : '10px' }}
        marginY={{ xxs: '6px', sm: 0 }}
      />
      <Stack role="list">
        {deliverables.length ? (
          <>
            <Stack
              role="listitem"
              direction="row"
              alignItems="center"
              display={{ xxs: 'none', sm: 'flex' }}
              sx={{
                paddingLeft: '24px',
                paddingY: hasBorders ? '12px' : '6px',
                borderBottom: hasBorders ? `1px solid ${GlobalStyles.SLATE_100}` : 'none',
              }}>
              <Stack {...DELIVERABLE_ROW_SIZES(!!selectedDeliverableEid).status}>
                <ColumnHeader sx={{ overflow: 'visible' }}>STATUS</ColumnHeader>
              </Stack>
              <Grid container columnSpacing="6px" display={{ xxs: 'none', sm: 'flex' }} sx={{ marginX: 0 }}>
                <Grid {...DELIVERABLE_ROW_SIZES(!!selectedDeliverableEid).title} className="flex items-center">
                  <ColumnHeader>DELIVERABLE</ColumnHeader>
                </Grid>

                <Grid item sm={13 - JOB_ROW_SIZES(!!selectedDeliverableEid).title.sm}>
                  <Grid container alignItems={'center'} columnSpacing="6px">
                    <Grid {...DELIVERABLE_ROW_SIZES(!!selectedDeliverableEid).type}>
                      <ColumnHeader>TYPE</ColumnHeader>
                    </Grid>
                    <Grid {...DELIVERABLE_ROW_SIZES(!!selectedDeliverableEid).date}>
                      <ColumnHeader>DATES</ColumnHeader>
                    </Grid>
                    <Grid {...DELIVERABLE_ROW_SIZES(!!selectedDeliverableEid).duration}>
                      <ColumnHeader sx={{ overflow: 'unset' }}>DURATION</ColumnHeader>
                    </Grid>
                    <Grid {...DELIVERABLE_ROW_SIZES(!!selectedDeliverableEid).budget}>
                      <ColumnHeader style={{ visibility: costMode === 'PER_JOB' ? 'hidden' : 'visible', textAlign: 'right' }}>
                        COST
                      </ColumnHeader>
                    </Grid>
                    <Grid {...DELIVERABLE_ROW_SIZES(!!selectedDeliverableEid).talents}>
                      <ColumnHeader>TALENT</ColumnHeader>
                    </Grid>
                    <Grid {...DELIVERABLE_ROW_SIZES(!!selectedDeliverableEid).users}>
                      <ColumnHeader>USERS</ColumnHeader>
                    </Grid>
                    <Grid {...DELIVERABLE_ROW_SIZES(!!selectedDeliverableEid).action}>
                      <ColumnHeader sx={{ overflow: 'unset' }}>CHATS</ColumnHeader>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Stack {...DELIVERABLE_ROW_SIZES().duplicate} />
            </Stack>
            {deliverables.map((deliverable, index) => {
              const deliverableStatus = STATUS_MAPPING(deliverable.status ?? 'DEFAULT').toLowerCase()
              const deliverable_start_datetime = deliverable.start_datetime
                ? moment(deliverable.start_datetime.split(' ')[0]).format('DD MMM YY')
                : ''
              const deliverable_end_datetime = deliverable.end_datetime
                ? moment(deliverable.end_datetime.split(' ')[0]).format('DD MMM YY')
                : ''
              return (
                <Stack
                  ref={selectedDeliverableEid === deliverable.eid ? rowRef : null}
                  direction="row"
                  alignItems="center"
                  key={deliverable.eid}
                  onMouseEnter={() => setIsDuplicateVisible(deliverable.eid)}
                  onMouseLeave={() => setIsDuplicateVisible(null)}
                  onClick={() => {
                    onClickDeliverable(deliverable as Types.Deliverable.iDeliverable, DELIVERABLE_DRAWER_TABS.OVERVIEW)
                    setIsAddingDeliverable(false)

                    trackEvent({
                      event: TrackingEventEnums.Others.FOCUS_PANEL_TOGGLED,
                      eventProperties: {
                        context: 'deliverable',
                        workspace,
                        open: true,
                      },
                    })
                  }}
                  sx={{
                    cursor: 'pointer',
                    marginX: { xxs: '24px', sm: 0 },
                    borderBottom: {
                      xxs: deliverables.length - 1 ? `1px solid ${GlobalStyles.SLATE_100}` : 'none',
                      sm: hasBorders ? `1px solid ${GlobalStyles.SLATE_100}` : 'none',
                    },
                    paddingY: { xxs: '12px', sm: hasBorders ? '12px' : '4px' },
                    backgroundColor: selectedDeliverableEid === deliverable.eid ? '#E2E2E2' : '',
                    boxSizing: 'border-box',
                  }}
                  role="listitem"
                  aria-label="deliverable list item"
                  columnGap={{ xxs: '10px', sm: 0 }}>
                  <Stack
                    width={{ xxs: '12px', sm: DELIVERABLE_ROW_SIZES().status.width }}
                    alignItems="center"
                    marginLeft={{ xxs: 0, sm: '24px' }}
                    marginBottom={{ xxs: 'auto', sm: 0 }}
                    marginTop={{ xxs: '4px', sm: 0 }}>
                    <Badge style={{ width: '16px', height: '16px', backgroundColor: STATUS_BG_COLORS[deliverableStatus] }} />
                  </Stack>
                  <Grid container alignItems="center" columnSpacing="6px" sx={{ marginX: 0 }} width={{ xxs: `calc(100% - 22px)` }}>
                    <Grid
                      {...gridProps(!!selectedDeliverableEid).title}
                      onMouseEnter={() => setIsEditVisible(deliverable.eid)}
                      onMouseLeave={() => setIsEditVisible(null)}>
                      <Stack direction="row" alignItems="center">
                        <Button variant="text" size="small" sx={{ padding: 0 }} aria-label={deliverable.name}>
                          <Typography
                            flex={1}
                            {...OVERFLOW_TEXT}
                            variant="subtitle2"
                            fontSize={'14px'}
                            color={GlobalStyles.MONO_BLACK}
                            aria-label={deliverable.name}>
                            {deliverable.name}
                          </Typography>
                        </Button>
                        <Stack
                          marginLeft="auto"
                          display={{ xxs: 'none', sm: 'initial' }}
                          sx={{ visibility: isEditVisible === deliverable.eid ? 'visible' : 'hidden' }}>
                          <Icon.Edit2 size={16} color={GlobalStyles.PRIMARY_500} />
                        </Stack>
                      </Stack>
                      <Stack display={{ xxs: 'none', sm: 'initial' }}>
                        <StartEndDate
                          selectedDeliverableEid={selectedDeliverableEid}
                          deliverable_start_datetime={deliverable_start_datetime}
                          deliverable_end_datetime={deliverable_end_datetime}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xxs={12} sm={13 - JOB_ROW_SIZES(!!selectedDeliverableEid).title.sm}>
                      {/* Displayed in Mobile View */}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        display={{ xxs: 'flex', sm: 'none' }}
                        paddingY="6px">
                        <StartEndDate
                          selectedDeliverableEid={selectedDeliverableEid}
                          deliverable_start_datetime={deliverable_start_datetime}
                          deliverable_end_datetime={deliverable_end_datetime}
                        />
                        <Stack direction="row" columnGap="6px">
                          <AvatarEl
                            users={deliverable?.deliverable_agreements
                              ?.filter(({ jobAgreement }) => !!jobAgreement?.talent)
                              .map(({ jobAgreement }) => jobAgreement!.talent)}
                            label="Talents"
                          />
                          <ChatEl
                            onClickDeliverable={(e) => {
                              e.stopPropagation()
                              onClickDeliverable(deliverable as Types.Deliverable.iDeliverable, DELIVERABLE_DRAWER_TABS.COMMENTS)
                              trackEvent({
                                event: TrackingEventEnums.Deliverable.DELIVERABLE_PANEL_TOGGLED,
                                eventProperties: {
                                  component_name: 'Deliverable Row',
                                  workspace: workspace,
                                  deliverable_name: deliverable.name,
                                  tab: 'Conversations',
                                },
                              })
                            }}
                          />
                        </Stack>
                      </Stack>
                      {/* Hidden in Mobile View */}
                      <Grid container alignItems={'center'} columnSpacing="6px" display={{ xxs: 'none', sm: 'flex' }}>
                        <Grid {...gridProps(!!selectedDeliverableEid).type}>
                          {deliverable.deliverable_type && (deliverable.deliverable_type.category || deliverable.deliverable_type.name) && (
                            <Tooltip
                              showIcon={false}
                              text={`${deliverable.deliverable_type.category} - ${deliverable.deliverable_type.name}`}>
                              <Chip
                                size="small"
                                label={`${deliverable.deliverable_type.category} - ${deliverable.deliverable_type.name}`}
                                style={TYPE_CHIP_STYLES}
                              />
                            </Tooltip>
                          )}
                        </Grid>
                        <Grid {...gridProps(!!selectedDeliverableEid).date}>
                          <Typography
                            {...OVERFLOW_TEXT}
                            fontSize={selectedDeliverableEid ? '12px' : '14px'}
                            variant="subtitle2"
                            className="text-slate-700 !font-normal">
                            {`${deliverable_start_datetime} ${deliverable_end_datetime ? `- ${deliverable_end_datetime}` : ''} `}
                          </Typography>
                        </Grid>
                        <Grid {...gridProps(!!selectedDeliverableEid).duration}>
                          <Typography
                            {...OVERFLOW_TEXT}
                            fontSize={selectedDeliverableEid ? '12px' : '14px'}
                            variant="subtitle2"
                            className="text-slate-700 !font-normal">
                            {deliverable_start_datetime && deliverable_end_datetime
                              ? getDuration(deliverable.start_datetime, deliverable.end_datetime)
                              : ''}
                          </Typography>
                        </Grid>
                        <Grid {...gridProps(!!selectedDeliverableEid).budget}>
                          <Typography
                            {...OVERFLOW_TEXT}
                            fontSize={selectedDeliverableEid ? '12px' : '14px'}
                            variant="subtitle2"
                            className="text-slate-700 !font-normal"
                            visibility={costMode === 'PER_DELIVERABLE' ? 'visible' : 'hidden'}
                            textAlign="right">
                            {!!deliverable.deliverable_cost_per_talent && !!deliverable.deliverable_currency_code
                              ? formatCurrency(deliverable.deliverable_currency_code, deliverable.deliverable_cost_per_talent)
                              : ''}
                          </Typography>
                        </Grid>
                        <Grid
                          {...gridProps(!!selectedDeliverableEid).talents}
                          onClick={(e) => {
                            e.stopPropagation()
                            onClickDeliverable(deliverable as Types.Deliverable.iDeliverable, DELIVERABLE_DRAWER_TABS.STATUS)

                            trackEvent({
                              event: TrackingEventEnums.Deliverable.DELIVERABLE_PANEL_TOGGLED,
                              eventProperties: {
                                component_name: 'Deliverable Row',
                                workspace: workspace,
                                deliverable_name: deliverable.name,
                                tab: 'Status',
                              },
                            })
                          }}>
                          {!!deliverable?.deliverable_agreements?.length ? (
                            <AvatarEl
                              users={deliverable?.deliverable_agreements
                                ?.filter(({ jobAgreement }) => !!jobAgreement?.talent)
                                .map(({ jobAgreement }) => jobAgreement!.talent)}
                              label="Talent"
                            />
                          ) : (
                            <Icon.UserPlus size={20} color={GlobalStyles.PRIMARY_500} />
                          )}
                        </Grid>
                        <Grid
                          {...gridProps(!!selectedDeliverableEid).users}
                          onClick={(e) => {
                            e.stopPropagation()
                            onClickDeliverable(deliverable as Types.Deliverable.iDeliverable, DELIVERABLE_DRAWER_TABS.STATUS)

                            trackEvent({
                              event: TrackingEventEnums.Deliverable.DELIVERABLE_PANEL_TOGGLED,
                              eventProperties: {
                                component_name: 'Deliverable Row',
                                workspace: workspace,
                                deliverable_name: deliverable.name,
                                tab: 'Status',
                              },
                            })
                          }}>
                          {!!deliverable?.users?.length ? (
                            <AvatarEl users={deliverable?.users} label="Users" />
                          ) : (
                            <Icon.UserPlus size={20} color={GlobalStyles.PRIMARY_500} />
                          )}
                        </Grid>
                        <Grid {...gridProps(!!selectedDeliverableEid).action}>
                          <ChatEl
                            onClickDeliverable={(e) => {
                              e.stopPropagation()
                              onClickDeliverable(deliverable as Types.Deliverable.iDeliverable, DELIVERABLE_DRAWER_TABS.COMMENTS)
                              trackEvent({
                                event: TrackingEventEnums.Deliverable.DELIVERABLE_PANEL_TOGGLED,
                                eventProperties: {
                                  component_name: 'Deliverable Row',
                                  workspace: workspace,
                                  deliverable_name: deliverable.name,
                                  tab: 'Conversations',
                                },
                              })
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Stack direction="row" alignItems="center" width="46px" minWidth="46px" display={{ xxs: 'none', sm: 'flex' }}>
                    {isDuplicateVisible === deliverable.eid && (
                      <IconButton
                        size="small"
                        sx={{
                          minWidth: 15,
                          color: GlobalStyles.PRIMARY_500,
                          padding: 0,
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          setDeliverableToDuplicate(deliverable)
                          setIsAddingDeliverable(true)

                          trackEvent({
                            event: TrackingEventEnums.Deliverable.DELIVERABLE_DUPLICATE,
                            eventProperties: {
                              component_name: 'Deliverable Row',
                              workspace: workspace,
                              deliverable_name: deliverable.name,
                            },
                          })
                        }}>
                        <Icon.Copy width={16} height={16} />
                      </IconButton>
                    )}
                  </Stack>

                  {selectedDeliverableEid === deliverable.eid && (
                    <Box
                      sx={{
                        display: { xxs: 'none', sm: 'block' },
                        width: 0,
                        height: 0,
                        borderTop: `${rowSize.height ? rowSize.height / 2 : 28}px solid transparent`,
                        borderBottom: `${rowSize.height ? rowSize.height / 2 : 28}px solid transparent`,
                        borderLeft: `35px solid #E2E2E2`,
                        position: 'absolute',
                        marginLeft: `${rowSize.width}px`,
                      }}
                    />
                  )}
                </Stack>
              )
            })}
          </>
        ) : (
          <></>
        )}
      </Stack>
      {isAddingDeliverable ? (
        <DeliverableOnlyInlineCreate
          job_eid={jobEid}
          costMode={costMode}
          deliverable={deliverableToDuplicate}
          onCancel={() => {
            setIsAddingDeliverable(false)
            onSuccessCreate()
          }}
          onSuccess={(deliverable) => {
            setIsAddingDeliverable(false)
            onSuccessCreate(deliverable)
          }}
          talents={talents}
        />
      ) : (
        <Stack direction="row" alignItems="center" marginLeft={hasBorders ? '24px' : '0'} className="py-[6px] mt-[6px]">
          {!hasBorders && <Stack width="34px" />}

          <PermissionWrapper readonly permissions={viewedJob.permissions} allowedPermissions={[DeliverablePermission.CREATE]}>
            <Button
              variant="text"
              size="small"
              sx={{ padding: 0 }}
              onClick={() => {
                setIsAddingDeliverable(true)
                onClickDeliverable(null)

                trackEvent({
                  event: TrackingEventEnums.Deliverable.DELIVERABLE_ADD,
                  eventProperties: {
                    component_name: 'Deliverable Row',
                    workspace: workspace,
                  },
                })
              }}>
              <Stack direction="row" className="items-center" columnGap="14px">
                <Icon.PlusCircle size={16} />
                Add Deliverable
              </Stack>
            </Button>
          </PermissionWrapper>
        </Stack>
      )}
    </Stack>
  )
}
