import { Button, GlobalStyles, Icon, Stack, Text } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { useAppSelector } from '@redux/hooks'
import { selectDeliverable } from '@redux/reducers/deliverableReducer'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext } from 'react'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'

const ConfirmDeliverableDelete = (): JSX.Element => {
  const { closeModal, options } = useContext(ModalContext)
  const { requestStatus } = useAppSelector(selectDeliverable)
  const { workspace } = useAppSelector(selectWorkspace)
  const props = options?.modalProps ? options.modalProps[ModalPages.DELETE_DELIVERABLE] : undefined

  const _handleSubmit = () => {
    if (props.onConfirm) {
      props.onConfirm()
      trackEvent({ event: TrackingEventEnums.Deliverable.DELIVERABLE_REMOVED, eventProperties: { workspace } })
    } else closeModal(ModalPages.DELETE_DELIVERABLE)
  }

  return (
    <Stack rounded space="none" height="auto" direction="column" style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        modalContext={ModalPages.DELETE_DELIVERABLE}
        modalTitle={'Archive Deliverable?'}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
        style={{
          paddingBottom: 0,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.md,
        }}
      />

      <Stack
        direction="column"
        align="flex-start"
        space="xs"
        style={{
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingVertical: GlobalStyles.PADDING_SIZES.md,
        }}>
        <Text size="md">You are about to archive this Deliverable. This action cannot be undone. Do you wish to proceed?</Text>
      </Stack>

      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{
          shadowOpacity: 0,
          justifyContent: 'flex-end',
          paddingBottom: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
        }}
        buttonStyles={{
          paddingVertical: 12,
          paddingHorizontal: 18,
          borderRadius: 8,
          backgroundColor: GlobalStyles.MONO_WHITE,
        }}
        buttonTextStyle={{
          color: GlobalStyles.STATUS_NEGATIVE,
          fontWeight: '500',
        }}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => closeModal(ModalPages.DELETE_DELIVERABLE)}
            textStyle={{ color: GlobalStyles.PRIMARY_500, fontWeight: '500' }}
            text="Cancel"
          />
        }
        onButtonPress={_handleSubmit}
        buttonText={'Yes'}
        loading={requestStatus.delete === 'pending'}
        loadingType={'blue'}
      />
    </Stack>
  )
}

export { ConfirmDeliverableDelete }
