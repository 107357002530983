import { GlobalStyles } from '@andromeda'
import {
  autocompleteClasses,
  buttonBaseClasses,
  checkboxClasses,
  chipClasses,
  inputBaseClasses,
  menuItemClasses,
  outlinedInputClasses,
  radioClasses,
  tabClasses,
} from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { multiSectionDigitalClockSectionClasses, pickersDayClasses } from '@mui/x-date-pickers'
import { dateRangePickerDayClasses } from '@mui/x-date-pickers-pro'
export const breakpoints = {
  values: {
    xxs: 200,
    xs: 400,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
}
/** @type {*} */
export const theme = createTheme({
  breakpoints,
  components: {
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          [`.${buttonBaseClasses.root}.${menuItemClasses.root}.${multiSectionDigitalClockSectionClasses.item}.Mui-selected`]: {
            backgroundColor: GlobalStyles.PRIMARY_500,
          },
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        root: {
          [`.${pickersDayClasses.root}.Mui-selected`]: {
            backgroundColor: GlobalStyles.PRIMARY_500,
          },
        },
      },
    },
    MuiDateRangeCalendar: {
      styleOverrides: {
        root: {
          [`.${pickersDayClasses.root}.${dateRangePickerDayClasses.day}.Mui-selected`]: {
            backgroundColor: GlobalStyles.PRIMARY_500,
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: GlobalStyles.PRIMARY_500,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTabs-indicator': {
            backgroundColor: GlobalStyles.PRIMARY_500,
          },
          [`.${tabClasses.root}.${tabClasses.selected}`]: {
            color: GlobalStyles.PRIMARY_500,
          },
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        sx: {
          [`&.${autocompleteClasses.popper}`]: {
            // override inline styles
            minWidth: 'fit-content !important',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: '100%',
          [`.${outlinedInputClasses.root}`]: {
            boxSizing: 'border-box',
            padding: '0px 12px',
            minHeight: '40px',
            [` .${autocompleteClasses.input}`]: {
              padding: 0,
            },
          },
          [`.${buttonBaseClasses.root}.${chipClasses.root}`]: {
            height: '24px',
            backgroundColor: GlobalStyles.SLATE_50,
            color: GlobalStyles.SLATE_700,
            borderColor: GlobalStyles.SLATE_100,
          },
          [`&.${autocompleteClasses.root} .${autocompleteClasses.inputRoot}`]: {
            flexWrap: 'unset',
          },
          [`&.${autocompleteClasses.focused}.${autocompleteClasses.root} .${autocompleteClasses.inputRoot} `]: {
            flexWrap: 'wrap',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 47,
          height: 24,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            marginTop: 6,
            marginLeft: 6,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              marginLeft: 12,
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: GlobalStyles.PRIMARY_500,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            // '&.Mui-disabled .MuiSwitch-thumb': {
            //   color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
            // },
            // '&.Mui-disabled + .MuiSwitch-track': {
            //   opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            // },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 12,
            height: 12,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: GlobalStyles.SLATE_200,
            opacity: 1,
            // transition: theme.transitions.create(['background-color'], {
            //   duration: 500,
            // }),
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          [`.${outlinedInputClasses.input}`]: {
            // backgroundColor: 'white',
            padding: '6px 12px',
            fontSize: '14px',
            minHeight: '40px',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
          },
          [`&.${inputBaseClasses.multiline}`]: {
            padding: 0,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`&.${inputBaseClasses.root}`]: {
            borderRadius: '6px',
            [`&.${inputBaseClasses.focused}`]: {
              [`.${outlinedInputClasses.notchedOutline}`]: {
                borderColor: GlobalStyles.PRIMARY_500,
              },
            },
          },
          [`.${outlinedInputClasses.notchedOutline}`]: {
            borderColor: GlobalStyles.SLATE_100,
          },
          [`&.${inputBaseClasses.adornedStart}`]: { paddingLeft: '12px !important' },
          [`&.${inputBaseClasses.root} .${autocompleteClasses.root}`]: {
            width: 'fit-content',
          },
          [`.${autocompleteClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
            border: 'none',
          },
          [`.${autocompleteClasses.root} .${inputBaseClasses.root}`]: {
            padding: 0,
          },
          [`.${autocompleteClasses.root} .${autocompleteClasses.input}`]: {
            color: GlobalStyles.PRIMARY_500,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: '20px',
        },
        labelSmall: {
          padding: '2px',
        },
      },
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            // textTransform: 'none',
            backgroundColor: GlobalStyles.PRIMARY_500,
            // borderRadius: GlobalStyles.BUTTON_BORDER_RADIUS,
            // borderStyle: 'solid',
            // borderWidth: 1,
            // borderColor: GlobalStyles.PRIMARY_500,
            color: GlobalStyles.MONO_WHITE,
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            // textTransform: 'none',
            // backgroundColor: GlobalStyles.PRIMARY_500,
            // borderRadius: GlobalStyles.BUTTON_BORDER_RADIUS,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: GlobalStyles.PRIMARY_500,
            color: GlobalStyles.PRIMARY_500,
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: `1px solid ${GlobalStyles.SLATE_100}`,
          borderRadius: '12px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          minWidth: 10,
          // backgroundColor: 'red',
          // height: 5,
          // width: 5,
          // borderRadius:20
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // width: 'auto !important',
          // backgroundColor: 'red',
          // height: 5,
          // width: 5,
          // borderRadius:20
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: GlobalStyles.SLATE_100,
          // backgroundColor: 'red',
          // height: 5,
          // width: 5,
          // borderRadius:20
        },
      },
    },
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // backgroundColor: 'red'
          borderRadius: 6,
          textTransform: 'none',
          minWidth: 10,
          minHeight: 40,
          padding: `6px 12px`,
          fontSize: 14,
        },
        contained: {
          '&:hover': {
            backgroundColor: GlobalStyles.PRIMARY_500,
            boxShadow: 'none',
          },
        },
        outlinedPrimary: {
          '&:hover': {
            borderColor: GlobalStyles.PRIMARY_500,
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            backgroundColor: GlobalStyles.PRIMARY_500,
            boxShadow: 'none',
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: GlobalStyles.PRIMARY_500,
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            textTransform: 'none',
            backgroundColor: 'transparent',
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: GlobalStyles.PRIMARY_500,
            color: GlobalStyles.PRIMARY_500,
          },
        },
        {
          props: { variant: 'shadowed' },
          style: {
            textTransform: 'none',
            backgroundColor: 'red',
            borderRadius: GlobalStyles.BUTTON_BORDER_RADIUS,
            minWidth: 10,
            // justifyContent: 'center',
            // alignItems: 'center',
            // borderStyle: 'solid',
            // borderWidth: 1,
            // borderColor: GlobalStyles.PRIMARY_500,
            // color: GlobalStyles.PRIMARY_500,
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowOffset: { width: 2, height: 2 },
            shadowOpacity: 0.5,
            shadowRadius: 2,
            elevation: 1,
          },
        },
      ],
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          [`@media (max-width:${breakpoints.values.sm}px)`]: {
            width: '100%',
            height: '100%',
            marginTop: 0,
          },
          width: '450px',
          boxShadow: ' 0px 0px 20px 0px rgba(0, 0, 0, 0.45)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        // @ts-expect-error
        root: {
          borderColor: GlobalStyles.SLATE_100,
          color: GlobalStyles.SLATE_1000,
          fontSize: '14px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: GlobalStyles.SLATE_1000,
          fontSize: '14px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: GlobalStyles.SLATE_200,
        },
        colorPrimary: {
          [`&.${checkboxClasses.checked}`]: {
            color: GlobalStyles.PRIMARY_500,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: GlobalStyles.SLATE_200,
        },
        colorPrimary: {
          [`&.${radioClasses.checked}`]: {
            color: GlobalStyles.PRIMARY_500,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#296F87',
      dark: '#296F87',
      light: '#065DE0',
    },
    slate_50: { main: '#F2F3F5' },
    slate_100: { main: '#E2E4E9' },
    slate_200: { main: '#C2C6D1' },
    slate_300: { main: '#A2A8B9' },
    slate_400: { main: '#828AA1' },
    slate_500: { main: '#656D86' },
    slate_600: { main: '#565C72' },
    slate_700: { main: '#464C5D' },
    slate_800: { main: '#373B49' },
    slate_900: { main: '#282B35' },
  },
  typography: {
    fontFamily: ['Inter', 'Inter UI'].join(','),
  },
})
