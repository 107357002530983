import { GlobalStyles } from '@andromeda'
import { Stack, Typography } from '@mui/material'
import Image from 'next/image'
import LogoPlaceholder from 'public/pickstar-logo.png'
import { OVERFLOW_TEXT } from '../job-card/job-card'
import { useRouter } from 'next/router'
import { PathEnums } from '@utils/context/Navigation.context'
import { useWorkspace } from '@utils/query/useWorkspace'

const BannerSettings = ({
  title,
  description,
  showLogo = true,
  icon,
  useWorkspaceAsTitle,
  heightOverride,
  mini = false,
}: {
  title?: string | JSX.Element
  description?: string
  showLogo?: boolean
  icon?: JSX.Element
  useWorkspaceAsTitle?: boolean
  heightOverride?: string
  mini?: boolean
}) => {
  const router = useRouter()

  const { activeWorkspace } = useWorkspace()

  const logo = activeWorkspace?.logo_image?.full_url ?? LogoPlaceholder.src

  return (
    <Stack
      direction={'row'}
      className="page-banner"
      height={{ xxs: heightOverride ?? '50px', sm: heightOverride ?? '168px' }}
      width="100%"
      sx={{
        backgroundImage: {
          xxs: `url('/pickstar-works-banner-mobile.png')`,
          sm:
            router.pathname === PathEnums.REQUEST_BY_ID
              ? `url('/job-page-banner-desktop.png')`
              : `url('/pickstar-works-banner-desktop.png')`,
        },
      }}>
      <Stack
        direction="row"
        boxSizing="border-box"
        padding={{ xxs: '4px 20px', sm: '0 24px', lg: '0 64px' }}
        sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
        <Stack
          direction="column"
          justifyContent={{ xxs: 'start', sm: 'center' }}
          alignItems={{ sm: 'flex-start' }}
          width={{ xxs: '100%', sm: 'auto' }}>
          <Stack direction={'row'} alignItems={'center'} spacing={2} maxWidth={'100%'}>
            {icon}
            <Typography
              sx={{
                color: GlobalStyles.MONO_WHITE,
                fontSize: { xxs: '24px', md: '40px' },
                ...OVERFLOW_TEXT.sx,
              }}>
              {useWorkspaceAsTitle ? activeWorkspace?.name : title}
            </Typography>
          </Stack>
          <Typography
            sx={{
              display: { xxs: 'none', sm: 'initial' },
              fontSize: '12px',
              fontWeight: '400',
              color: GlobalStyles.MONO_WHITE,
            }}>
            {description}
          </Typography>
        </Stack>
        {!mini && showLogo && logo && (
          <div className="invisible md:visible" style={{ backgroundColor: 'white', width: 120, height: 120, borderRadius: 60 }}>
            <Image alt="WorkspaceLogo" src={logo} width={120} height={120} />
          </div>
        )}
      </Stack>
    </Stack>
  )
}

export { BannerSettings }
