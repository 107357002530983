import { BlockType, LeafType } from 'remark-slate'
import { MentionElement } from 'src/andromeda/RichEditor/custom-types'
import { Editor, Transforms } from 'slate'
import { Types } from '@pickstar/orbit'

function insertMention(editor: Editor, user: Types.User.iUser) {
  const mention: MentionElement & { uid: string } = {
    type: 'mention',
    uid: user.eid,
    fullName: user.name,
    children: [{ text: '' }],
  }

  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
}

function extractMetionedUsers<T extends Types.User.iUser[]>(children: Array<Partial<BlockType & LeafType>>) {
  return children
    .reduce<Array<{ eid: string; name: string }>>((mentions, child) => {
      if (child.type === 'mention') {
        const { uid, fullName } = child as MentionElement
        mentions.push({ eid: uid!, name: fullName! })
      }

      if (child.children) {
        mentions.push(...extractMetionedUsers(child.children))
      }

      return mentions
    }, [])
    .filter((mention, i, mentions) => {
      // filter out duplicate mentions
      return mentions.findIndex((m) => m.eid === mention.eid) === i
    }) as T
}

function extractContent(str: string) {
  const pattern = /@\{\[.*?\]\[.*?\]\}@/g
  const result: Array<{ text: string; isMention: boolean }> = []
  let lastIndex = 0
  let match: RegExpExecArray | null

  while ((match = pattern.exec(str)) !== null) {
    const matchedText = match[0]
    const matchedIndex = match.index

    if (matchedIndex >= lastIndex) {
      const segment = str.substring(lastIndex, matchedIndex)
      if (segment) result.push({ text: segment, isMention: false })

      // add a leading empty string if there is none yet for every mention
      if (!result.length || (result.length && (result[result.length - 1].isMention || result[result.length - 1].text === '\n'))) {
        result.push({ text: '', isMention: false })
      }

      result.push({ text: matchedText, isMention: true })
      lastIndex = pattern.lastIndex
    }
  }

  if (lastIndex < str.length) {
    const segment = str.substring(lastIndex)
    if (segment) result.push({ text: segment, isMention: false })
  }

  // add a trailing empty string if the last node is a mention
  if (result.length && result[result.length - 1].isMention) {
    result.push({ text: '', isMention: false })
  }

  return result.length ? result : [{ text: str, isMention: false }]
}

function parseMentionText(text: string) {
  const [content, mentionStyles] = text.match(/\[(.*?)\]/g) ?? []
  const [uid, fullName] = content?.slice(1, -1).split(':') ?? []
  const styles: Record<string, boolean> = {}

  if (mentionStyles) {
    mentionStyles
      .slice(1, -1)
      .split(',')
      .forEach((s) => {
        let style = ''

        if (s === 'b') {
          style = 'bold'
        } else if (s === 'i') {
          style = 'italic'
        } else if (s === 'u') {
          style = 'underline'
        }

        if (style) styles[style] = true
      })
  }

  return {
    type: 'mention',
    uid,
    fullName,
    children: [{ ...styles, text: '' }],
  } as MentionElement
}

function deserializeMention(nodeBlock: BlockType | LeafType) {
  let node = { ...nodeBlock }

  if (node.hasOwnProperty('children')) {
    node = node as BlockType
    let deserializedChildren: BlockType['children'] = []

    for (let i = 0; i < node.children.length; i++) {
      const deserializedResult = deserializeMention(node.children[i] as BlockType)

      if (Array.isArray(deserializedResult)) {
        deserializedChildren = [...deserializedChildren, ...deserializedResult]
      } else {
        deserializedChildren.push(deserializedResult)
      }
    }

    node.children = deserializedChildren
  }

  if (node.hasOwnProperty('text')) {
    return extractContent((node as LeafType).text).map(({ text, isMention }) => {
      if (isMention) {
        return parseMentionText(text)
      }

      return { ...node, text }
    })
  }

  return node
}

function serializeMention(nodeBlock: BlockType) {
  const node = { ...nodeBlock }

  if (node.type === 'mention') {
    const { uid, children, fullName } = node as MentionElement
    const firstChild = children[0] as LeafType & { underline?: boolean }
    const styles = ['bold', 'italic', 'underline'].filter((style) => firstChild[style as keyof LeafType]).map((style) => style[0])

    // using this format to make it easier to parse when deserializing
    return { text: `@{[${uid}:${fullName}][${styles.join(',')}]}@` } as LeafType
  }

  if (node.children?.length) {
    node.children = node.children.reduce((serializedChildren: Array<BlockType | LeafType>, child, i, children) => {
      if ((child as BlockType).type === 'mention') {
        // remove leading empty text node before mention, this will be added back when the mention is serialized
        if (serializedChildren.length && (serializedChildren[serializedChildren.length - 1] as LeafType).text === '') {
          serializedChildren.pop()
        }

        serializedChildren.push(serializeMention(child as BlockType))
      } else if (i < children.length - 1 || (child as LeafType).text !== '' || (children[i - 1] as BlockType)?.type !== 'mention') {
        // remove trailing empty text node after mention, this will be added back when the mention is serialized
        serializedChildren.push(child)
      }

      return serializedChildren
    }, [])
  }

  return node
}

export { insertMention, deserializeMention, serializeMention, extractMetionedUsers }
