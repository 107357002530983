import { Orbit } from '@pickstar/orbit'
import { useAppSelector } from '@redux/hooks'
import { selectAuth } from '@redux/reducers/authReducer'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'

export const getPersistedToken = () => {
  try {
    return JSON.parse(JSON.parse(localStorage.getItem('persist:auth') as string).token).access_token
  } catch (error) {
    return undefined
  }
}

export const useMe = () => {
  const router = useRouter()

  const { token, loggedOut } = useAppSelector(selectAuth)

  const hasToken = !!Orbit.Services.AxiosBaseInstance.defaults.headers.common['Authorization']

  return useQuery({
    queryKey: ['me', router.query.workspace],
    queryFn: async () => {
      const res = await Orbit.Services.AxiosBaseInstance.get(`/user/me`)
      return res.data.data
    },
    enabled: hasToken && (getPersistedToken() || !!token?.access_token) && !loggedOut,
  })
}
