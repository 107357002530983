import { Orbit, Types } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { setViewedJob } from '@redux/reducers/jobsReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { toast } from 'react-toastify'

export default function useJobUpdate({ jobEid }: { jobEid: string }) {
  const mutateJobKey = 'jobDetails'
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { workspace } = useAppSelector(selectWorkspace)
  const { mutate, status } = useMutation({
    mutationKey: ['jobDetails', workspace, jobEid],
    mutationFn: async (job: { jobEid: string; payload: Types.Job.iJobPayload }) => {
      toast.loading('Updating job...', { toastId: mutateJobKey })
      const response = await Orbit.Services.jobService.updateJobById(job)
      return response.data
    },
    onSuccess(data, _variables) {
      trackEvent({
        event: TrackingEventEnums.Job.JOB_UPDATED,
        eventProperties: {
          component_name: 'Job Details Page Header',
          workspace,
        },
      })

      if (data.event_types && data.event_types.length) {
        const eventTypeEids = data.event_types.map((eventType) => eventType.eid)
        data.event_type_eids = eventTypeEids
      }

      queryClient.setQueryData(['jobDetails', workspace, jobEid], data)
      dispatch(setViewedJob(data))
      toast.update(mutateJobKey, { render: 'Job has been updated.', type: 'success', autoClose: 5000, isLoading: false })
    },
    onError(error: { message: string }, _variables) {
      toast.update(mutateJobKey, { render: error?.message || 'Failed to update job', type: 'error', isLoading: false, autoClose: 5000 })
    },
  })

  return { updateJob: mutate, updateJobStatus: status }
}
