import { DropdownOption, GlobalStyles, Icon, Text } from '@andromeda'
import { ExternalJobFormSent } from '@components'
import { Button, Stack, autocompleteClasses } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { acceptJobRequest, createJobRequest, initialJobRequestDetails, selectJobs } from '@redux/reducers/jobsReducer'
import { listOrganisations, selectRoles } from '@redux/reducers/rolesReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { BUDGET_GUIDE_TYPE, DATE_GUIDE_TYPE } from '@utils/enum/commons/guide'
import { useClientOrg } from '@utils/hooks/useClientOrg'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { SearchTagV2 } from '../search/searchtag.new'
import { FormSearchTagV2 } from '../search/searchtag.new'
import { FormProvider, SubmitHandler, useController, useForm } from 'react-hook-form'
import { Row } from './components'
import { Textfield } from '../form/Textfield'
import { useCountryCode } from '@utils/hooks/useCountryCode'
import { PhoneInputDefaultCountry } from 'src/andromeda/Input/types'
import { FormAutoCompleteLocation } from '../autoCompleteLocation'
import { formatLocation } from '@utils/functions/helperFunctions'
import { parsePhoneNumber } from 'react-phone-number-input'
import { Types } from '@pickstar/orbit'
import { FormDateRangePicker } from '../form/DateRangePicker'
import { FormDateTimePicker } from '../form/DateTimePicker'
import moment from 'moment'
import { FormAutoComplete } from '../form/AutoComplete'
import { DevTool } from '@hookform/devtools'
import { CurrencyTextfield } from '../form/CurrencyTextfield'
import { useCurrencyCode } from '@utils/hooks/useCurrencyCode'
import { API_DATE_FORMAT } from '@utils/constants/date'
import { getDefaultCurrencyCode } from '@utils/functions/helperFunctions'

type tFormValues = Types.Job.iJobExternal & { budget_type: DropdownOption; date_type: DropdownOption; country_code: { [key: string]: any } }

const DATE_GUIDE_OPTIONS = [
  {
    eid: DATE_GUIDE_TYPE.FLEXIBLE,
    value: 'Flexible',
  },
  {
    eid: DATE_GUIDE_TYPE.FIXED,
    value: 'Fixed',
  },
]
const BUDGET_GUIDE_OPTIONS = [
  {
    eid: BUDGET_GUIDE_TYPE.FLEXIBLE,
    value: 'Flexible',
  },
  {
    eid: BUDGET_GUIDE_TYPE.FIXED,
    value: 'Fixed',
  },
  {
    eid: BUDGET_GUIDE_TYPE.NO_FEE,
    value: 'No Fee',
  },
]
const DEFAULT_VALUES = {
  ...initialJobRequestDetails,
  country_code: PhoneInputDefaultCountry,
  budget_type: BUDGET_GUIDE_OPTIONS[1],
  date_type: DATE_GUIDE_OPTIONS[1],
}

const ExternalJobForm = ({ jobRequestEid, token }: { jobRequestEid?: string; token?: string }): JSX.Element => {
  const { createJobRequestPayload: job } = useAppSelector(selectJobs)
  const { workspace, settings } = useAppSelector(selectWorkspace)
  const { organisations: organizations } = useAppSelector(selectRoles)
  const dispatch = useAppDispatch()

  const { countryOptions } = useCountryCode()
  const [selectedOrg, setSelectedOrg] = useState<DropdownOption | null>(null)

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [showSent, setShowSent] = useState<boolean>(false)
  const [organisationOptions, setOrganisationOptions] = useState<DropdownOption[]>(
    // @ts-expect-error
    job.external_client.organisation?.eid && job.external_client.organisation?.name
      ? // @ts-expect-error
        [{ id: job.external_client.organisation?.eid, value: job.external_client.organisation?.name }]
      : []
  )

  const form = useForm<tFormValues>({
    defaultValues: DEFAULT_VALUES,
  })
  const {
    field: { value: budgetType, onChange: onChangeBudgetType },
  } = useController({ name: 'budget_type', control: form.control })
  const { field: locationField } = useController({ name: 'location', control: form.control, rules: { required: true } })

  const {
    field: { value: dateType, onChange: onChangeDateType },
  } = useController({ name: 'date_type', control: form.control })
  const { field: startDateField } = useController({ name: 'start_datetime', control: form.control })
  const { field: endDateField } = useController({ name: 'end_datetime', control: form.control })
  const {
    field: { onChange: onChangeClientName },
  } = useController({ name: 'external_client.name', control: form.control })
  const { field: fieldClientOrganisation } = useController({
    name: 'external_client.organisation',
    control: form.control,
    rules: { required: true },
  })

  const currenyCodeOptions = useCurrencyCode()
  const {
    orgUserOptions,
    organisationOptions: organisationDropdownOptions,
    organisationOptionsLoads,
    loadMoreOrgOptions,
    searchOrgOptions,
  } = useClientOrg({
    clientName: job.external_client.name,
    organisation_eid: (fieldClientOrganisation.value as DropdownOption)?.id as string,
    external_client: job.external_client,
  })
  const [organisationUserOptions, setOrganisationUserOptions] = useState<DropdownOption[]>(orgUserOptions)

  useEffect(() => {
    fieldClientOrganisation.onChange(selectedOrg)
  }, [selectedOrg])

  useEffect(() => {
    if (jobRequestEid && job && !form.getValues('external_client.name')) {
      // @ts-expect-error
      form.reset(job)
      onChangeClientName(job.external_client.name)
    }
  }, [job, form, jobRequestEid, onChangeClientName])

  useEffect(() => {
    if (currenyCodeOptions.length && settings?.default_currency)
      form.setValue(
        'job_currency_code',
        // @ts-expect-error
        getDefaultCurrencyCode(settings?.default_currency, currenyCodeOptions)
      )
  }, [settings?.default_currency, currenyCodeOptions])

  useEffect(() => {
    if (orgUserOptions) setOrganisationUserOptions(orgUserOptions)
  }, [orgUserOptions])

  useEffect(() => {
    setOrganisationOptions(organisationDropdownOptions)
  }, [organisationDropdownOptions])

  const onSubmit: SubmitHandler<tFormValues> = (data) => {
    const payload = {
      ...data,
      // @ts-expect-error
      job_currency_code: data.job_currency_code.id,
      external_client: {
        ...data.external_client,
        // @ts-expect-error
        name: data.external_client.name?.name,
        // @ts-expect-error
        organisation: data.external_client.organisation?.eid,
      },
      department: job.department ?? null,
      budget_type: budgetType.eid,
      date_type: dateType.eid,
    }
    setIsSaving(true)
    if (!jobRequestEid && !token) {
      // create job request
      toast
        .promise(dispatch(createJobRequest(payload)), {
          pending: 'Sending external form...',
        })
        .then((response) => {
          setIsSaving(false)
          if (response.meta.requestStatus === 'fulfilled') {
            trackEvent({
              event: TrackingEventEnums.ExternalForm.EXTERNAL_FORM_SENT,
            })
            toast.success('External form has been sent.')
          } else {
            toast.error(response.payload.message || 'Error sending external form.')
          }
        })
    } else if (jobRequestEid && token && job.external_client.eid) {
      toast
        .promise(dispatch(acceptJobRequest({ jobRequestEid, token, payload: payload })), {
          pending: 'Saving job request...',
        })
        .then((response) => {
          trackEvent({
            event: TrackingEventEnums.ExternalForm.EXTERNAL_FORM_SENT,
            eventProperties: {
              workspace,
            },
          })
          setIsSaving(false)
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('Job request has been sent.')
            setShowSent(true)
          } else {
            toast.error(response.payload.message || 'Error saving job request.')
          }
        })
    }
  }

  if (showSent) {
    return <ExternalJobFormSent key={'external-form-sent'} cardContainerStyle={{ backgroundColor: GlobalStyles.MONO_WHITE }} />
  } else {
    return (
      <FormProvider {...form}>
        <DevTool control={form.control} />
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack direction="column" rowGap="24px">
            <Text size="xl" type={'paragraph'} bold>
              {'Client Details'}
            </Text>

            <Stack className="paper-border" style={{ backgroundColor: 'white' }}>
              <Row label="Name" tooltipText="Only internal users will see this">
                <FormAutoComplete
                  name="external_client.name"
                  textfieldProps={{
                    placeholder: 'eg: John Doe',
                  }}
                  getOptionLabel={(option) => option.name}
                  options={organisationUserOptions}
                  hasAdd
                />
              </Row>
              <Row label="Organisation">
                <FormAutoComplete
                  hasAdd
                  name="external_client.organisation"
                  getOptionLabel={(option) => option.name}
                  options={organisationOptions}
                  textfieldProps={{
                    placeholder: 'The company or organisation the client represents',
                    onChange: (e) => searchOrgOptions(e.target.value),
                  }}
                  loading={!organisationOptions.length && organisationOptionsLoads}
                  isOptionsLoading={organisationOptionsLoads}
                  onScrollToBottom={() => loadMoreOrgOptions()}
                />
              </Row>
              <Row label="Email">
                <Textfield
                  placeholder="client@email.com"
                  name="external_client.email"
                  registerOptions={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9.-]+/i,
                      message: 'Invalid email address',
                    },
                  }}
                />
              </Row>
              <Row label="Phone" noBorder>
                <Stack direction="row" columnGap="8px">
                  <Textfield
                    name="external_client.phone_number"
                    type="number"
                    placeholder="400 000 000"
                    registerOptions={{
                      validate: () => {
                        const parsedPhoneNumber = parsePhoneNumber(
                          form.getValues('external_client.phone_number') as string,
                          form.getValues('country_code')['label'].split(' ')[0]
                        )
                        return parsedPhoneNumber && parsedPhoneNumber.isPossible() ? true : 'Invalid phone number'
                      },
                    }}
                    sx={{ flex: 1 }}
                    InputProps={{
                      startAdornment: (
                        <FormAutoComplete
                          open
                          disableClearable
                          name="country_code"
                          options={countryOptions}
                          getOptionLabel={(option) => option.label}
                          sx={{
                            [`&.${autocompleteClasses.root} .${autocompleteClasses.inputRoot}`]: {
                              minWidth: '110px',
                            },
                          }}
                        />
                      ),
                    }}
                  />
                </Stack>
              </Row>
            </Stack>
            <Text size="xl" type={'paragraph'} bold>
              {'Job Details'}
            </Text>
            <Stack className="paper-border" direction={{ lg: 'row' }} style={{ backgroundColor: 'white' }}>
              <Row
                noBorder
                containerProps={{
                  padding: '24px 32px',
                  flex: 1,
                  borderRight: { sm: `1px solid ${GlobalStyles.SLATE_100}` },
                  borderBottom: { xxs: `1px solid ${GlobalStyles.SLATE_100}`, sm: 'none' },
                }}
                label="Job Date Guide"
                tooltipText="Add a date or date range"
                flex={1}
                direction="row"
                justifyContent="space-between">
                {form.getValues('date_type').value === 'Fixed' ? (
                  <FormDateTimePicker
                    format={settings?.date_format}
                    defaultValue={job.start_datetime ? moment(job.start_datetime) : null}
                    onChange={(date) => {
                      startDateField.onChange(date?.format(API_DATE_FORMAT))
                      endDateField.onChange(`${date?.format('YYYY-MM-DD')} 23:59:59`)
                    }}
                  />
                ) : (
                  <FormDateRangePicker
                    defaultValue={[
                      job.start_datetime ? moment(job.start_datetime) : null,
                      job.end_datetime ? moment(job.end_datetime) : null,
                    ]}
                    onChange={([startDate, endDate]) => {
                      startDateField.onChange(startDate?.format(API_DATE_FORMAT))
                      endDateField.onChange(endDate?.format(API_DATE_FORMAT))
                    }}
                  />
                )}
                <FormSearchTagV2
                  name="date_type"
                  noSearch
                  secondary
                  defaultValue={DATE_GUIDE_OPTIONS.find((option) => option.eid === job.date_type) ?? DATE_GUIDE_OPTIONS[1]}
                  options={DATE_GUIDE_OPTIONS}
                  style={{ minWidth: '100px' }}
                  onSelect={onChangeDateType}
                />
              </Row>
              <Row
                noBorder
                containerProps={{
                  padding: '24px 32px',
                  flex: 1,
                }}
                labelContainerProps={{ width: '140px' }}
                label="Job Budget Guide"
                tooltipText="Add a budget or a budget range"
                direction="row"
                alignItems="center">
                <CurrencyTextfield
                  currency={{
                    name: 'job_cost',
                    placeholder: '100,000',
                    disabled: budgetType.eid === BUDGET_GUIDE_TYPE.NO_FEE,
                    sx: { flex: 1 },
                  }}
                  currencyCode={{
                    name: 'job_currency_code',
                    options: currenyCodeOptions,
                  }}
                />
                {budgetType.eid === BUDGET_GUIDE_TYPE.FLEXIBLE && (
                  <>
                    <Text>{'-'}</Text>
                    <Textfield
                      name="budget_to_from"
                      type="number"
                      placeholder="100,000"
                      InputProps={{ startAdornment: '$' }}
                      style={{ width: budgetType.eid === BUDGET_GUIDE_TYPE.FLEXIBLE ? '50%' : '100%' }}
                    />
                  </>
                )}
                <SearchTagV2
                  noSearch
                  secondary
                  defaultValue={BUDGET_GUIDE_OPTIONS[1]}
                  options={BUDGET_GUIDE_OPTIONS}
                  style={{ minWidth: '100px' }}
                  onSelect={onChangeBudgetType}
                />
              </Row>
            </Stack>

            <Stack className="paper-border" style={{ backgroundColor: 'white' }}>
              <Row label="Job title" tooltipText="">
                <Textfield name="name" placeholder="Give this job a unique title" />
              </Row>
              <Row label="Location" tooltipText="This is a list of all locations your deliverables are based in">
                <FormAutoCompleteLocation
                  name="location"
                  field={locationField}
                  onChange={locationField.onChange}
                  defaultValue={formatLocation(job.location ?? form.getValues('location'))}
                  placeholder="Add a location"
                />
              </Row>
              <Row
                label="Description"
                tooltipText="Add extra details that talent will need to know in order to fullfill this deliverable/job."
                noBorder>
                <Textfield
                  multiline
                  name="description"
                  placeholder="Add a short summary to describe your job. Cover any missions, goals, or special requirements relating to your organisation. This will be used by talent and their managers to understand your brief."
                  registerOptions={{ required: false }}
                />
              </Row>
            </Stack>
            <Stack direction="row" columnGap="10px" justifyContent="end">
              <Button
                variant={'outlined'}
                disabled={isSaving}
                style={{ width: 'fit-content' }}
                onClick={() => {
                  form.reset(DEFAULT_VALUES)
                }}>
                Clear Form
              </Button>
              <Button
                type="submit"
                variant={'contained'}
                disabled={isSaving}
                style={{ width: 'fit-content' }}
                endIcon={<Icon.ChevronRight color={GlobalStyles.MONO_WHITE} size={GlobalStyles.FONT_SIZES.BASE} />}>
                Send
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    )
  }
}

export { ExternalJobForm }
