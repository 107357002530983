import { iLoadingState } from './common'

export interface iMyAccountsProps {
  isEditMode: boolean
  onSubmit: (accountInfo: any) => void
  onCancel: () => void
}

export enum AvailabilityRange {
  CUSTOM = 'Custom Range',
  MONTHLY = 'Monthly Range',
}

export interface iAvailability {
  status: string
  displayText: string
  startDate: null | Date | string
  endDate: null | Date | string
  selectedRange: AvailabilityRange
}

export interface iAccountsInitialState {
  sendLinkStatus: iLoadingState
  passwordResetStatus: iLoadingState
  availability: iAvailability
}
