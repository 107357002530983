import { Avatar, Dropdown, DropdownOption, GlobalStyles, Icon } from '@andromeda'
import { Stack, Typography, Button, Chip, Pagination, TextField, CircularProgress } from '@mui/material'
import { getInitials } from '@utils/functions/helperFunctions'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { listWorkspaceTalents, selectTalents } from '@redux/reducers/talentReducer'
import { Types } from '@pickstar/orbit'
import { toast } from 'react-toastify'
import { assignTalentToDeliverable } from '@redux/reducers/deliverableReducer'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { Filter, FilterOptions } from '@utils/types/filter'
import useDebouncedCallback from '@utils/hooks/useDebouncedCallback'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { ModalHeader } from 'src/components/modal'
import { useQueryClient } from '@tanstack/react-query'

const paperStyles = {
  border: `1px solid ${GlobalStyles.SLATE_100}`,
  borderRadius: '6px',
  display: 'flex',
}

const FILTERS: Filter[] = [
  {
    eid: '1',
    name: 'Industry',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '1',
        value: 'Industry 1',
        parent_eid: '1',
      },
      {
        eid: '2',
        value: 'Industry 2',
        parent_eid: '1',
      },
    ],
  },
  {
    eid: '2',
    name: 'Location',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '3',
        value: 'Location 1',
        parent_eid: '2',
      },
      {
        eid: '4',
        value: 'Location 2',
        parent_eid: '2',
      },
    ],
  },
  {
    eid: '3',
    name: 'Gender',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '5',
        value: 'Male',
        parent_eid: '3',
      },
      {
        eid: '6',
        value: 'Female',
        parent_eid: '3',
      },
    ],
  },
  {
    eid: '4',
    name: 'Topics',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '7',
        value: 'Topics 1',
        parent_eid: '4',
      },
      {
        eid: '8',
        value: 'Topics 2',
        parent_eid: '4',
      },
    ],
  },
  {
    eid: '5',
    name: 'Agent',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '9',
        value: 'Agent 1',
        parent_eid: '5',
      },
      {
        eid: '10',
        value: 'Agent 2',
        parent_eid: '5',
      },
    ],
  },
  {
    eid: '6',
    name: 'Job Categories',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '11',
        value: 'Job Categories 1',
        parent_eid: '6',
      },
      {
        eid: '12',
        value: 'Job Categories 2',
        parent_eid: '6',
      },
    ],
  },
  {
    eid: '7',
    name: 'Social Followers',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '13',
        value: 'Social Followers 1',
        parent_eid: '7',
      },
      {
        eid: '14',
        value: 'Social Followers 2',
        parent_eid: '7',
      },
    ],
  },
  {
    eid: '8',
    name: 'Interests',
    collapsed: true,
    options: [
      {
        eid: 'all',
        value: 'All',
      },
      {
        eid: '15',
        value: 'Agent 1',
        parent_eid: '8',
      },
      {
        eid: '16',
        value: 'Agent 2',
        parent_eid: '8',
      },
    ],
  },
]

export type AddTalentProps = {
  deliverableEid?: string
  assignedTalentEids?: string[]
  assignedTalents?: Partial<Types.Talent.iTalent>[]
  onSuccess?: (data?: any) => void
  onChange?: (talents: DropdownOption[]) => void
  context: 'inline-editor' | 'focus-panel'
  costMode?: Types.Job.iJobPayload['cost_mode']
}

export const AddTalent = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { talents, listMeta, listStatus } = useAppSelector(selectTalents)
  const { workspace } = useAppSelector(selectWorkspace)

  const { options } = useContext(ModalContext)
  const { deliverableEid, assignedTalentEids, onSuccess, onChange, context, assignedTalents, costMode } = options?.modalProps?.[
    ModalPages.ASSIGN_TALENT
  ] ?? {
    deliverableEid: undefined,
    assignedTalentEids: undefined,
    assignedTalents: undefined,
    onSuccess: undefined,
    onChange: undefined,
    context: 'focus-panel',
    costMode: undefined,
  }

  const [page, setPage] = useState(1)
  const [selectedTalents, setSelectedTalents] = useState<Partial<Types.Talent.iTalent>[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [queryString, setQueryString] = useState<string>('')
  const [showMore, setShowMore] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<FilterOptions[]>([])
  const [showFilters, setShowFilters] = useState(false)
  const [searchKeys, setSearchKeys] = useState<string[]>([])

  const filters = useMemo(() => (showMore ? FILTERS : FILTERS.slice(0, 3)), [showMore])
  const pagination = useMemo(() => {
    if (listMeta?.pagination) {
      const currentPage = listMeta?.pagination.current_page || 0
      const totalPage = listMeta?.pagination.total_number_of_pages

      return {
        currentPage,
        totalPage,
        nextPage: currentPage + 1 > totalPage ? 0 : currentPage + 1,
        previousPage: currentPage - 1 <= 0 ? 0 : currentPage - 1,
      }
    }
  }, [listMeta])
  // @ts-expect-error
  const filteredTalents = useMemo<Types.Talent.iTalent[]>(() => {
    if (context === 'inline-editor') {
      return talents ?? []
    } else {
      return talents?.length
        ? talents.reduce<Types.Talent.iTalent[]>(
            // @ts-expect-error
            (acc, curr) => (!assignedTalentEids?.some((assignTalentEid) => assignTalentEid === curr.eid) ? [...acc, curr] : acc),
            []
          )
        : ([] as Types.Talent.iTalent[])
    }
  }, [assignedTalentEids, context, talents])

  const handleSearch = (e: any) => {
    const searchPillKey = queryString.toLowerCase().replace(/[^a-z-A-Z0-9]/g, '')
    const searchPill = { eid: searchPillKey, value: queryString }
    const key: string = queryString.trim()

    if (key && !selectedFilters.find((selected) => selected.eid === searchPillKey)) {
      setSelectedFilters([...selectedFilters, searchPill])
      const newSearchKeys: Array<string> = [...searchKeys, key]
      setSearchKeys(newSearchKeys)

      const params: Types.Talent.iTalentListParams = { page: '1' }
      if (newSearchKeys.length) params.query = newSearchKeys
      dispatch(listWorkspaceTalents({ params }))
    }
  }

  const debouncedHandleSearch = useDebouncedCallback(handleSearch, 500)

  useEffect(() => {
    if (context === 'inline-editor' && !selectedTalents.length && assignedTalents?.length) {
      setSelectedTalents(assignedTalents ?? [])
    }
  }, [assignedTalents, context, selectedTalents])

  useEffect(() => {
    dispatch(listWorkspaceTalents({ params: { page: page.toString(), per_page: '10' } }))
  }, [dispatch, page])

  useEffect(() => {
    debouncedHandleSearch()
  }, [debouncedHandleSearch, queryString])

  const handleSelectTalent = (talent: Types.Talent.iTalent) => {
    const newSelectedTalents = [...selectedTalents, talent]
    if (context === 'inline-editor' && onChange) {
      if (costMode === 'PER_JOB') {
        onChange([talent])
        setSelectedTalents([talent])
      } else {
        setSelectedTalents(newSelectedTalents)
        onChange(newSelectedTalents.map(({ eid, display_name, profile_image }) => ({ eid, display_name, profile_image })))
      }
    } else {
      setSelectedTalents(newSelectedTalents)
    }
  }

  const _setFilterFromDropdown = (e: any, parentId: string) => {
    if (e.eid === 'all') {
      const newSelectedFilters = selectedFilters.filter((filter) => filter.parent_eid !== parentId)
      setSelectedFilters(newSelectedFilters)
    } else {
      if (selectedFilters.indexOf(e) === -1) setSelectedFilters([...selectedFilters, e])
    }
  }
  const _removeFilterFromDropdown = (index: number) => {
    const newSelectedFitlers: FilterOptions[] = []
    let key: FilterOptions | null = null
    selectedFilters.forEach((pill: any, pillIndex: number) => {
      if (index !== pillIndex) newSelectedFitlers.push(pill)
      else key = pill
    })

    let newParams: Types.Talent.iTalentListParams = { page: '1' }
    if (key) {
      const newSearchKeys: string[] = []
      searchKeys.forEach((str) => {
        if (str !== key?.value) newSearchKeys.push(str)
      })
      setSearchKeys(newSearchKeys)
      if (newSearchKeys.length) newParams.query = newSearchKeys
    }

    if (selectedFilters[index] && queryString === selectedFilters[index].value) {
      setQueryString('')
    }

    setSelectedFilters(newSelectedFitlers)
    dispatch(listWorkspaceTalents({ params: newParams }))
  }

  const clearFilters = () => {
    dispatch(listWorkspaceTalents({ params: { page: '1' } }))
    setQueryString('')
    setSelectedFilters([])
  }

  const handleAddTalents = async () => {
    if (context === 'inline-editor') {
      onSuccess?.()
    } else {
      setIsLoading(true)

      const agreement = selectedTalents?.reduce(
        (acc, talent) => (talent.eid ? [...acc, { talent_eid: talent.eid }] : acc),
        [] as Array<{ talent_eid: string }>
      )
      await toast.promise(
        dispatch(
          assignTalentToDeliverable({
            assign: [
              {
                deliverable_eid: deliverableEid as string,
                agreement,
              },
            ],
          })
        ).then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('Talents assigned.')
            onSuccess?.(response.payload[0])
            setSelectedTalents([])

            queryClient.invalidateQueries({ queryKey: ['deliverable'], type: 'active' })

            trackEvent({
              event: TrackingEventEnums.Deliverable.DELIVERABLE_ADD_TALENT,
              eventProperties: {
                component_name: 'L2 Deliverable Panel',
                workspace: workspace,
              },
            })
          } else {
            toast.error('Failed to assign talents.')
          }
          setIsLoading(false)
        }),
        { pending: 'Assigning talent to deliverable...' }
      )
    }
  }

  return (
    <Stack width={{ sm: '450px' }}>
      <ModalHeader modalTitle="Add Talent" modalContext={ModalPages.ASSIGN_TALENT} />
      <Stack gap="14px" padding="12px" {...paperStyles}>
        <TextField
          variant="outlined"
          placeholder="Search for Talent"
          InputProps={{
            startAdornment: <Icon.Search size={16} style={{ marginRight: '8px' }} />,
          }}
          sx={{ '.MuiInputBase-input': { fontSize: `${GlobalStyles.FONT_SIZES.SMALL}px` } }}
          onChange={(e) => setQueryString(e.target.value)}
          value={queryString}
        />
        <Stack direction="row" justifyContent="space-between">
          <Button variant="outlined" onClick={() => setShowFilters(!showFilters)}>
            <Stack direction="row" alignItems="center" gap="8px">
              <Icon.Sliders size={16} /> {`Filters ${selectedFilters.length ? `(${selectedFilters.length})` : ''}`}
            </Stack>
          </Button>
          <Button variant="outlined" onClick={clearFilters}>
            Clear Filters
          </Button>
        </Stack>
        {showFilters && (
          <>
            <Stack direction="row" flexWrap="wrap" columnGap="14px">
              {filters.map((filter, index: number) => {
                const selectedItemInFilter = selectedFilters.find(({ parent_eid }) => parent_eid === filter.eid)
                const selectedItem = selectedFilters.length && selectedItemInFilter ? selectedItemInFilter : filter.options[0]
                return (
                  <Stack direction="row" alignItems="center" spacing={2} key={`filter-${filter.name.toLowerCase()}-${index}`}>
                    <Typography fontSize="14px" fontWeight="bold">
                      {filter.name}
                    </Typography>
                    <Dropdown
                      options={filter.options}
                      selectedItem={selectedItem}
                      onSelect={(e: any) => _setFilterFromDropdown(e, filter.eid as string)}
                      optionLabel={'value'}
                      dropdownContainerStyle={{
                        padding: 0,
                        height: 'auto',
                        borderWidth: 0,
                        minWidth: 0,
                        width: 60,
                      }}
                      labelStyle={{
                        padding: 0,
                        borderRadius: 0,
                        color: GlobalStyles.PRIMARY_500,
                        fontWeight: '500',
                      }}
                      inputSpace={'none'}
                      dropdownStyle={{ width: 200 }}
                      stackProps={{ space: 'none' }}
                    />
                  </Stack>
                )
              })}
            </Stack>
            <Button variant="text" onClick={() => setShowMore(!showMore)}>
              <Stack direction="row" alignItems="center" sx={{}}>
                {showMore ? (
                  <>
                    <Icon.Minus size={14} />
                    <Typography fontSize="14px" color={GlobalStyles.PRIMARY_500} fontWeight={500}>
                      Show Less
                    </Typography>
                  </>
                ) : (
                  <>
                    <Icon.Plus size={14} />
                    <Typography fontSize="14px" color={GlobalStyles.PRIMARY_500} fontWeight={500}>
                      Show More
                    </Typography>
                  </>
                )}
              </Stack>
            </Button>
          </>
        )}
        <Stack direction="row" flexWrap="wrap" rowGap="14px" columnGap="8px">
          {selectedFilters.map(({ eid, parent_eid, value }, index) => {
            const filterCategory = FILTERS.find((filter) => filter.eid === parent_eid)

            return (
              <Chip
                style={{
                  color: GlobalStyles.MONO_BLACK,
                  backgroundColor: GlobalStyles.SLATE_100,
                  fontSize: `${GlobalStyles.FONT_SIZES.TINY}px`,
                  padding: '6px',
                  borderRadius: '12px',
                }}
                key={eid}
                size="small"
                label={
                  <Stack alignItems="center" direction="row" gap="5px">
                    {`${filterCategory ? filterCategory.name + ':' : ''} ${value}`}
                    <Icon.XCircle size={13} cursor="pointer" onClick={() => _removeFilterFromDropdown(index)} />
                  </Stack>
                }
              />
            )
          })}
        </Stack>
        <Typography color={GlobalStyles.SLATE_1000}>{`${listMeta?.pagination.total_results} users match your criteria`}</Typography>
        <Stack {...paperStyles} maxHeight="50vh" sx={{ overflowY: 'auto' }}>
          {!filteredTalents?.length && listStatus === 'pending' ? (
            <Stack justifyContent="center" alignSelf="center">
              <CircularProgress />
            </Stack>
          ) : (
            filteredTalents?.map((talent, index) => {
              const { eid, profile_image, display_name, role } = talent
              return (
                <Stack
                  direction="row"
                  columnGap="12px"
                  alignItems="center"
                  key={eid}
                  padding="16px"
                  borderBottom={index < filteredTalents.length - 1 ? paperStyles.border : 'none'}>
                  <Avatar
                    size={'md'}
                    image={typeof profile_image == 'string' ? profile_image : profile_image?.full_url}
                    imageStyle={{ backgroundColor: GlobalStyles.SLATE_800 }}
                    placeHolder={
                      <Typography fontSize="18px" color="white" textAlign="center">
                        {getInitials(display_name)}
                      </Typography>
                    }
                  />
                  <Stack justifyContent="center" flex={1}>
                    <Typography fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM} color={GlobalStyles.MONO_BLACK}>
                      {display_name}
                    </Typography>
                    <Typography fontSize={`${GlobalStyles.FONT_SIZES.TINY}px`} color={GlobalStyles.SLATE_700}>
                      {role}
                    </Typography>
                  </Stack>
                  {selectedTalents.some((selectedTalent) => selectedTalent.eid === eid) ? (
                    <Button
                      variant="contained"
                      sx={{ height: '40px', width: '40px', padding: 0 }}
                      aria-label={`Remove ${display_name} from selection`}
                      onClick={() => {
                        setSelectedTalents((prev) => prev.filter((x) => x.eid !== eid))
                      }}>
                      <Icon.Check size={14} />
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{ height: '40px', width: '40px', padding: 0 }}
                      aria-label={`Add ${display_name} to selection`}
                      onClick={() => {
                        handleSelectTalent(talent)
                      }}>
                      <Icon.Plus size={14} />
                    </Button>
                  )}
                </Stack>
              )
            })
          )}
        </Stack>
        <Stack justifyContent="center">
          <Pagination
            page={page}
            size="medium"
            count={pagination?.totalPage}
            shape="rounded"
            onChange={(_, page) => {
              setPage(page)
            }}
            sx={{
              '&.MuiPagination-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="end">
          <Button variant="contained" onClick={handleAddTalents} disabled={!selectedTalents.length || isLoading}>{`Confirm Selection ${
            selectedTalents.length ? `(${selectedTalents.length})` : ''
          }`}</Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
