import { Button, GlobalStyles, Icon, Stack, Text } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { useAppSelector } from '@redux/hooks'
import { selectJobs } from '@redux/reducers/jobsReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { iJobCompleteModalProps } from '@utils/types/jobs'
import { useContext } from 'react'

const JobComplete = (props: iJobCompleteModalProps): JSX.Element => {
  const { closeModal } = useContext(ModalContext)
  const { completeJobStatus } = useAppSelector(selectJobs)

  const _handleSubmit = () => {
    if (props.onConfirm) props.onConfirm()
    else closeModal(ModalPages.JOB_COMPLETE)
  }

  return (
    <Stack rounded space="none" height="auto" direction="column" style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        modalContext={ModalPages.JOB_COMPLETE}
        modalTitle={'Complete Job?'}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
        style={{
          paddingBottom: 0,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.md,
        }}
      />

      <Stack
        direction="column"
        align="flex-start"
        space="xs"
        style={{
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingVertical: GlobalStyles.PADDING_SIZES.md,
        }}>
        <Text size="sm">{'This action will:'}</Text>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">{'This will notify any invited or booked stars, along with any users viewing the job.'}</Text>
        </Stack>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">{'Change assigned stars to removed'}</Text>
        </Stack>

        <Stack direction="row" space="2xs" align="flex-start">
          <Text style={{ lineHeight: 15 }}>
            <Icon.Check color={GlobalStyles.SLATE_300} />
          </Text>
          <Text size="sm">{'Notify relevant users that the job has been completed'}</Text>
        </Stack>
      </Stack>

      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{
          shadowOpacity: 0,
          justifyContent: 'flex-end',
          paddingBottom: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
        }}
        buttonStyles={{
          paddingVertical: 12,
          paddingHorizontal: 18,
          borderRadius: 8,
          backgroundColor: GlobalStyles.MONO_WHITE,
        }}
        buttonTextStyle={{
          color: GlobalStyles.STATUS_NEGATIVE,
          fontWeight: '500',
        }}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
            onPress={() => closeModal(ModalPages.JOB_COMPLETE)}
            textStyle={{ color: GlobalStyles.PRIMARY_500, fontWeight: '500' }}
            text="Review Job"
          />
        }
        onButtonPress={_handleSubmit}
        buttonText={'Mark Complete'}
        loading={completeJobStatus === 'loading'}
        loadingType={'blue'}
      />
    </Stack>
  )
}

export default JobComplete
