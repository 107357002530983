import { Orbit, Types } from '@pickstar/orbit'
import { AppState } from '@redux/store'
import { createAsyncThunk, createSlice, Draft, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { uploadFileToUrl } from '@utils/functions/helperFunctions'
import { ApiResponseError } from '@utils/types/auth'
import { iTalentInitialState } from '@utils/types/talent'

const initialState: iTalentInitialState = {
  talents: null,
  listMeta: null,
  listStatus: 'idle',
  jobs: null,
  jobListMeta: null,
}

export const listWorkspaceTalents = createAsyncThunk<
  Draft<Types.Talent.iTalentListResponse & { lazyLoaded: boolean }>,
  { params: Types.Talent.iTalentListParams; customWorkspace?: string; lazyLoaded?: boolean }
>('talents/all', async (params): Promise<Types.Talent.iTalentListResponse & { lazyLoaded: boolean }> => {
  const response = await Orbit.Services.talentService.list(params.params, params.customWorkspace)
  return { data: response.data, meta: response.meta as Types.Talent.iTalentListMeta, lazyLoaded: !!params.lazyLoaded }
})

export const listManagedTalent = createAsyncThunk<
  Draft<Types.Talent.iTalentListResponse>,
  { params: Types.Talent.iTalentListParams; customWorkspace?: string }
>('talents/listManaged', async (params): Promise<Types.Talent.iTalentListResponse> => {
  const response = await Orbit.Services.talentService.listManaged(params.params, params.customWorkspace)
  return { data: response.data, meta: response.meta as Types.Talent.iTalentListMeta }
})

export const listTalentJobs = createAsyncThunk(
  'talents/listTalentJobs',
  async (
    { talentId, queryParams }: { talentId: string; queryParams: Types.Talent.iTalentJobsParams },
    { rejectWithValue }
  ): Promise<Types.Job.iJob | any> => {
    try {
      const response = await Orbit.Services.talentService.listTalentJobs(talentId, queryParams)
      return response
    } catch (e) {
      return rejectWithValue((e as ApiResponseError).response.data)
    }
  }
)

export const talentsSlice = createSlice({
  name: 'talents',
  initialState,
  reducers: {
    resetTalentSlice: () => initialState,
    setTalents: (state: iTalentInitialState, action: PayloadAction<Types.Talent.iTalent[] | null>) => {
      state.talents = action.payload
    },
    resetTalentList: (state: iTalentInitialState) => {
      state.talents = null
      state.listMeta = null
    },
    resetTalentJobs: (state) => {
      state.jobs = null
      state.jobListMeta = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        listWorkspaceTalents.fulfilled,
        (state: iTalentInitialState, action: PayloadAction<Types.Talent.iTalentListResponse & { lazyLoaded: boolean }>) => {
          state.talents = action.payload.lazyLoaded && state.talents ? [...state.talents, ...action.payload.data] : action.payload.data
          state.listMeta = action.payload.meta
          state.listStatus = 'success'
        }
      )
      .addCase(listWorkspaceTalents.pending, (state: iTalentInitialState) => {
        state.listStatus = 'pending'
      })
      .addCase(listWorkspaceTalents.rejected, (state: iTalentInitialState) => {
        state.listStatus = 'failed'
      })
      .addCase(listManagedTalent.fulfilled, (state: iTalentInitialState, action: PayloadAction<Types.Talent.iTalentListResponse>) => {
        state.talents = action.payload.data
        state.listMeta = action.payload.meta
        state.listStatus = 'success'
      })
      .addCase(listManagedTalent.pending, (state: iTalentInitialState) => {
        state.listStatus = 'pending'
      })
      .addCase(listManagedTalent.rejected, (state: iTalentInitialState) => {
        state.listStatus = 'failed'
      })
      .addCase(listTalentJobs.fulfilled, (state: iTalentInitialState, action: PayloadAction<any>) => {
        state.jobs = action.payload.data
        state.jobListMeta = action.payload.meta
        state.listStatus = 'success'
      })
      .addCase(listTalentJobs.pending, (state: iTalentInitialState) => {
        state.listStatus = 'pending'
      })
      .addCase(listTalentJobs.rejected, (state: iTalentInitialState) => {
        state.listStatus = 'failed'
      })
  },
})

export const { resetTalentList, setTalents, resetTalentSlice, resetTalentJobs } = talentsSlice.actions
export const { reducer } = talentsSlice
export const selectTalents = (state: AppState) => state.talents
