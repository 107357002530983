import { DropDownItemV2, DropdownV2, GlobalStyles, Icon } from '@andromeda'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, GridProps, Button, Grid, Stack, Typography } from '@mui/material'
import { Types } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { createInvite, selectInvite } from '@redux/reducers/inviteReducer'
import { listRoles, selectRoles } from '@redux/reducers/rolesReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useController, useForm, FormProvider, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { Textfield } from '../form/Textfield'

import { PERMISSION_OPTIONS } from 'src/modals/userPermissions'
import { FormAutoComplete } from '../form/AutoComplete'
import { DevTool } from '@hookform/devtools'
import { useMe } from '@utils/query/useMe'
import { RolePermissions } from '@utils/enum/permissions'

const borderStyle = `1px solid ${GlobalStyles.SLATE_100}`
const inputStyles = {
  fontSize: `${GlobalStyles.FONT_SIZES.SMALL}px`,
  width: '100%',
}

type UserFormType = {
  name: string
  lastName?: string
  email: string
  roles: Pick<Types.Roles.iRole, 'eid' | 'name' | 'label'>[]
  note?: string
  ownership_type?: string
}

const validationSchema = yup.object({
  name: yup.string().required('First name is required.'),
  lastName: yup.string(),
  email: yup.string().email('Invalid email format').required('Email address is required.'),
  roles: yup.array().min(1, 'Please select at least 1 role.').required('Please select at least 1 role.'),
  note: yup.string(),
  ownership_type: yup.string(),
})

export const Row = ({ children, noBorder = false, ...other }: GridProps & { children: JSX.Element[]; noBorder?: boolean }) => (
  <Grid container borderBottom={noBorder ? 'none' : borderStyle} direction="row" alignItems="center" {...other}>
    {children}
  </Grid>
)

export const Label = ({ children, miniLabels, ...other }: GridProps & { children: string; miniLabels?: boolean }) => (
  <Grid item xxs={4.5} sm={3.5} boxSizing="border-box" padding={miniLabels ? '0 12px' : '0 16px 0 32px'} {...other}>
    <Typography color={GlobalStyles.MONO_BLACK} fontWeight={GlobalStyles.FONT_WEIGHT_MEDIUM}>
      {children}
    </Typography>
  </Grid>
)
export const FormField = ({
  children,
  disabled = false,
  ...other
}: GridProps & { children: JSX.Element | JSX.Element[]; disabled?: boolean }) => (
  <Grid
    item
    xxs={7.5}
    sm={8.5}
    padding="24px 16px 24px 32px"
    borderLeft={borderStyle}
    {...other}
    sx={{
      backgroundColor: disabled ? GlobalStyles.SLATE_50 : '',
      boxSizing: 'border-box',
      height: disabled ? '100%' : 'auto',
    }}>
    {children}
  </Grid>
)

export const InviteUserForm = ({
  submitOverrideAction,
  submitButtonText,
  miniLabels = false,
  additionalInfo,
  hasOwnerShipType = false,
  showRoleSelection = true,
}: {
  submitOverrideAction?: (form: Types.Invite.iInviteCreatePayload) => void
  submitButtonText?: string
  miniLabels?: boolean
  additionalInfo?: string
  hasOwnerShipType?: boolean
  showRoleSelection?: boolean
}) => {
  const { data: me } = useMe()
  const { createStatus } = useAppSelector(selectInvite)
  const { roles } = useAppSelector(selectRoles)
  const dispatch = useAppDispatch()
  const { closeModal } = useContext(ModalContext)

  const [roleOptions, setRoleOptions] = useState<Types.Roles.iRole[]>([])
  const guestRole = useMemo(() => (roles ? (roles.find((role) => role.name === 'guest') as Types.Roles.iRole) : undefined), [roles])

  // React Hook Form Actions
  const form = useForm<UserFormType>({
    resolver: yupResolver(validationSchema),
    defaultValues: { name: '', lastName: '', email: '', roles: [], note: '' },
    mode: 'onSubmit',
  })
  const {
    handleSubmit: doSubmit,
    control,
    reset,
    formState: { dirtyFields },
  } = form

  const {
    field: { value: ownershipTypeValue, onChange: ownershipValueOnChange },
  } = useController<UserFormType>({ name: 'ownership_type', control })
  const rolesFormState = useWatch({ name: 'roles', control })

  useEffect(() => {
    if (hasOwnerShipType) {
      form.setValue('ownership_type', 'VIEWER')
    }

    form.setValue('roles', [{ eid: guestRole?.eid as string, name: 'guest', label: 'Guest' }])
  }, [dispatch, guestRole?.eid])

  useEffect(() => {
    if (!roles) {
      dispatch(listRoles())
    }
  }, [dispatch, roles])

  useEffect(() => {
    if (roles) {
      setRoleOptions(
        roles.filter((role) => {
          const hasPermission = role.permissions?.includes(RolePermissions.ADD_USER)
          return hasPermission && !rolesFormState?.some((formRole) => formRole.eid === role.eid)
        })
      )
    }
  }, [roles, rolesFormState])

  const onSubmit: SubmitHandler<UserFormType> = (data) => {
    const changes = Object.fromEntries(Object.keys(dirtyFields).map((key: any) => [key, data[key as keyof UserFormType]]))

    let inviteData: Types.Invite.iInviteCreatePayload = {
      ...changes,
      name: `${changes?.name} ${changes?.lastName}`,
      roles: guestRole ? [guestRole?.eid] : [],
    }

    if (showRoleSelection) inviteData.roles = form.getValues('roles').map((role) => role.eid)
    if (changes.note) inviteData.note = changes.note
    if (changes.roles) inviteData.roles = (changes.roles as UserFormType['roles']).map((role) => role.eid)
    if (hasOwnerShipType) {
      // @ts-expect-error
      inviteData = { ...inviteData, ownership_type: ownershipTypeValue }
    }

    if (submitOverrideAction) {
      submitOverrideAction(inviteData)
      return
    }

    toast
      .promise(dispatch(createInvite(inviteData)), {
        pending: 'Creating invite..',
      })
      .then((response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          toast.success('Invite sent.')
          closeModal(ModalPages.INVITE_USER)
        } else {
          toast.error(response?.payload?.message || 'Invite creation failed.')
        }
      })
  }

  return (
    <FormProvider {...form}>
      <DevTool control={form.control} />
      <Stack className="dialog-content">
        <Stack border={borderStyle} borderRadius="6px">
          <form>
            <Row>
              <Label miniLabels={miniLabels}>First Name</Label>
              <FormField>
                <Textfield variant="standard" name="name" placeholder="Add Name" />
              </FormField>
            </Row>
            <Row>
              <Label miniLabels={miniLabels}>Last Name</Label>
              <FormField>
                <Textfield variant="standard" name="lastName" style={inputStyles} fullWidth placeholder="Add Last Name" />
              </FormField>
            </Row>
            <Row>
              <Label miniLabels={miniLabels}>Email</Label>
              <FormField>
                <Textfield variant="standard" name="email" fullWidth type="email" placeholder="example@email.com" />
              </FormField>
            </Row>

            {showRoleSelection && (
              <Row>
                <Label miniLabels={miniLabels}>Role(s)*</Label>
                <FormField>
                  <FormAutoComplete multiple name="roles" options={roleOptions} getOptionLabel={(option) => option.label} />
                </FormField>
              </Row>
            )}

            {hasOwnerShipType && (
              <Row height="auto" noBorder>
                <Label miniLabels={miniLabels}>Ownership Type</Label>
                <FormField>
                  <DropdownV2
                    wrapperStyle={{ flex: 1 }}
                    title={PERMISSION_OPTIONS.find((value) => value.eid == ownershipTypeValue)?.label}
                    menuIcon={<Icon.ChevronDown color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />}>
                    {PERMISSION_OPTIONS &&
                      PERMISSION_OPTIONS.map((option) => (
                        <DropDownItemV2 key={option.value} onClick={() => ownershipValueOnChange(option.eid)}>
                          {option.label}
                        </DropDownItemV2>
                      ))}
                  </DropdownV2>
                </FormField>
              </Row>
            )}
            <Row height="auto" noBorder>
              <Label height="80px" miniLabels={miniLabels}>
                Add a note
              </Label>
              <FormField height="80px">
                <Textfield name="note" multiline placeholder="Add a note to welcome your new user" />
              </FormField>
            </Row>
          </form>
        </Stack>
      </Stack>
      {additionalInfo && (
        <Stack direction="row" columnGap="6px" alignItems="flex-start" marginTop="12px" paddingX={`${GlobalStyles.PADDING_SIZES.md}px`}>
          <Box height="12px" width="12px" marginTop="1px">
            <Icon.Info height={12} width={12} color={GlobalStyles.SLATE_500} />
          </Box>
          <Typography fontSize="12px" color={GlobalStyles.SLATE_500}>
            {additionalInfo}
          </Typography>
        </Stack>
      )}
      <Stack justifyContent="end" direction="row" className="dialog-footer">
        <Button
          variant="contained"
          sx={{ width: submitButtonText ? 'auto' : '110px', padding: '10px 16px', fontSize: GlobalStyles.FONT_SIZES.SMALL }}
          onClick={doSubmit(onSubmit)}
          disabled={createStatus === 'loading'}>
          {submitButtonText ?? 'Send Invite'}
        </Button>
      </Stack>
    </FormProvider>
  )
}
