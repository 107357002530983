import { DropdownOption, GlobalStyles } from '@andromeda'
import { ModalHeader } from '@components'
import { Stack } from '@mui/material'
import { Types } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { createInvite } from '@redux/reducers/inviteReducer'
import { listRoles, selectRoles } from '@redux/reducers/rolesReducer'
import { listUsers, selectUsers, updateUserRoles } from '@redux/reducers/usersReducer'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { InviteUserForm } from 'src/components/user/invite'
import { ManageUserForm } from 'src/components/user/manage'

export type UserFormType = {
  firstName: string
  lastName: string
  email: string
  roles: DropdownOption[]
  note: string
  organisation?: string | null
  ownership_type?: string
}

export const InviteUserModal = () => {
  const dispatch = useAppDispatch()
  const { users } = useAppSelector(selectUsers)
  const { roles } = useAppSelector(selectRoles)

  const { options, closeModal } = useContext(ModalContext)
  const { userEid = '', onSuccess = () => {} } = options?.modalProps?.inviteUser || {}

  const [form, setForm] = useState<UserFormType>({ firstName: '', lastName: '', email: '', roles: [], note: '' })

  const guestRole = useMemo(() => (roles ? (roles.find((role) => role.name === 'guest') as Types.Roles.iRole) : undefined), [roles])

  useEffect(() => {
    if (users && roles && guestRole) {
      if (userEid) {
        const user = users.find((user) => user.eid === userEid)

        const [firstName = '', lastName = ''] = (user?.name || ' ').split(' ')

        // @ts-expect-error
        let formRoles = user?.roles.reduce((acc, { name }) => {
          const role = roles.find((role) => role.name === name)

          return [...acc, role]
        }, []) as DropdownOption[]

        setForm({
          firstName,
          lastName,
          email: user?.email || '',
          roles: formRoles,
          organisation: user?.company,
          note: '',
        })
      } else {
        setForm((prev) => ({ ...prev, roles: [guestRole] }))
      }
    }
  }, [guestRole, roles, userEid, users])

  useEffect(() => {
    if (!roles) {
      // dispatch(listRoles())
    }
  }, [dispatch, roles])

  const handleChange = (newVal: Partial<UserFormType>) => {
    setForm((prev) => ({ ...prev, ...newVal }))
  }

  const hanldeSubmit = () => {
    if (userEid) {
      const payload: Types.User.iUserUpdateRolePayload = {
        roles: form.roles.map((role) => role.id as string),
      }
      if (guestRole && !payload.roles.some((roleId) => roleId === guestRole?.eid)) {
        payload.roles = [...payload.roles, guestRole?.eid]
      }

      toast
        .promise(dispatch(updateUserRoles({ userEid, payload })), {
          pending: 'Updating user..',
        })
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('User updated.')
            onSuccess()
          } else {
            toast.error(response?.payload?.message || 'Updating user failed.')
          }
        })
    }
  }

  return (
    <Stack sx={{ width: { sm: '600px' } }}>
      <ModalHeader modalContext={ModalPages.INVITE_USER} modalTitle={userEid ? 'Manage User Roles' : 'Invite User to Workspace'} />
      {userEid ? <ManageUserForm form={form} /> : <InviteUserForm />}
    </Stack>
  )
}
