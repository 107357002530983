import { StyleSheet } from 'react-native'
import { GlobalStyles } from '../globalStyles'

export const NotificationStyles = StyleSheet.create({
  mainWrapper: {
    width: 'auto',
  },
  touchableWrapper: {
    width: 'fit-content',
  },
  notificationBadge: {
    height: 10,
    width: 'fit-content',
    position: 'absolute',
    top: -5,
    right: -5,
    padding: 3,
  },
  notificationBadgeText: {
    fontSize: 8,
  },
  notificationModalSurface: {
    flex: 1,
  },
  notificationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingVertical: 12,
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E2E4E9',
    boxSizing: 'border-box',
    boxShadow:
      '0px 4px 2px rgba(101, 109, 134, 0.02), 0px 4px 8px rgba(101, 109, 134, 0.04), 0px 4px 16px rgba(101, 109, 134, 0.06), 0px 4px 48px rgba(101, 109, 134, 0.08)',
    borderRadius: 8,
  },
})
