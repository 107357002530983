import { Button, GlobalStyles, Stack, Text, View } from '@andromeda'
import { ModalFooter, ModalHeader } from '@components'
import { ModalContext, ModalPages } from '@utils/context/Modal.context'
import { useContext } from 'react'

export const UnsavedChanges = () => {
  const { closeModal, options } = useContext(ModalContext)
  const props = options?.modalProps?.unsavedChanges

  const _handleClose = () => {
    closeModal(ModalPages.UNSAVED_CHANGES)
    if (props && props.exitWithoutSave) props.exitWithoutSave()
  }
  return (
    <Stack rounded space={'2xs'} height="auto" direction="column" align={'flex-start'} style={{ backgroundColor: GlobalStyles.MONO_WHITE }}>
      <ModalHeader
        modalContext={ModalPages.UNSAVED_CHANGES}
        modalTitle={'Exit without saving?'}
        textStyle={{ fontSize: GlobalStyles.HEADER_FONT_SIZES.SMALL }}
        style={{
          paddingBottom: 0,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.md,
        }}
      />
      <View style={{ width: '100%', paddingHorizontal: GlobalStyles.PADDING_SIZES.md, paddingTop: GlobalStyles.PADDING_SIZES.sm }}>
        <Text>{'You will lose all unsaved changes if you choose to exit without saving.'}</Text>
      </View>

      <ModalFooter
        backgroundColor={GlobalStyles.MONO_WHITE}
        wrapperStyle={{
          shadowOpacity: 0,
          justifyContent: 'space-between',
          paddingBottom: GlobalStyles.PADDING_SIZES.md,
          paddingTop: GlobalStyles.PADDING_SIZES.sm,
          paddingHorizontal: GlobalStyles.PADDING_SIZES.md,
        }}
        buttonStyles={{
          backgroundColor: GlobalStyles.MONO_WHITE,
        }}
        onButtonPress={() => _handleClose()}
        buttonText={'Exit without Saving'}
        buttonTextStyle={{ color: GlobalStyles.STATUS_NEGATIVE }}
        disabled={false}
        leftComponent={
          <Button
            type="secondary"
            style={{ backgroundColor: GlobalStyles.MONO_WHITE, padding: 0 }}
            onPress={() => closeModal(ModalPages.UNSAVED_CHANGES)}
            textStyle={{ color: GlobalStyles.PRIMARY_500 }}
            text="Return to Deliverable"
          />
        }
      />
    </Stack>
  )
}
