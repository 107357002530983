import { Orbit, Types } from '@pickstar/orbit'
import { useAppSelector } from '@redux/hooks'
import { selectAuth } from '@redux/reducers/authReducer'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { getPersistedToken } from './useMe'
import { useContext } from 'react'

const getPersistedWorkspace = () => {
  try {
    return JSON.parse(JSON.parse(localStorage.getItem('persist:workspace') as string).workspaceList)
  } catch (error) {
    return undefined
  }
}
export const useWorkspaces = () => {
  const router = useRouter()

  const { token, loggedOut } = useAppSelector(selectAuth)

  const hasToken = !!Orbit.Services.AxiosBaseInstance.defaults.headers.common['Authorization']

  return useQuery<Types.Workspaces.iWorkspace[] | undefined>({
    queryKey: ['workspaces'],
    queryFn: async () => {
      const res = await Orbit.Services.userService.getMyWorkspaces()
      return res.data
    },
    enabled: hasToken && (getPersistedToken() || !!token?.access_token) && !loggedOut,
    initialData: localStorage.getItem('persist:workspace') ? getPersistedWorkspace() : undefined,
  })
}
