import { Button, GlobalStyles, Icon, Input, Label, Text, View } from '@andromeda'
import { Checkbox, Stack } from '@mui/material'
import { useAppDispatch } from '@redux/hooks'
import { changeUserPassword, logout } from '@redux/reducers/authReducer'
import { AppStyles } from '@styles'
import { useScreenDimensions } from '@utils/hooks/dimensions'
import { passwordStrengthCalculate } from '@utils/index'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const PasswordChange = () => {
  const { screenSize } = useScreenDimensions()
  const screenIsSmall = screenSize.sm

  const dispatch = useAppDispatch()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [oldPassword, setOldPassword] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [password2, setPassword2] = useState<string>('')
  const [password2Touched, setPassword2Touched] = useState<boolean>(false)
  const [strictOldPassword, setStrictOldPassword] = useState<boolean>(true)
  const [strictPassword, setStrictPassword] = useState<boolean>(true)
  const [strictPassword2, setStrictPassword2] = useState<boolean>(true)
  const [passwordStrengthLabel, setPasswordStrengthLabel] = useState<string>('')
  const [passwordStregthColor, setPasswordStregthColor] = useState<string>(GlobalStyles.STATUS_NEGATIVE)
  const [passwordNotMatch, setPasswordNotMatch] = useState<boolean>(false)
  const [autoLogout, setAutoLogout] = useState<any>({ value: { id: 1 }, isChecked: true })

  const childrenStyle = {
    borderBottomWidth: !isEdit ? 2 : 0,
    borderBottomColor: GlobalStyles.SLATE_100,
    paddingBottom: 24,
  }

  const _handleCheckboxValueChange = (item: any) => {
    setAutoLogout(item)
  }

  const _handleSubmit = () => {
    if (password !== password2 && password2Touched) {
      setPasswordNotMatch(true)
    } else if (password === password2) {
      setPasswordNotMatch(false)
      toast
        .promise(
          dispatch(
            changeUserPassword({
              current_password: oldPassword,
              password: password,
              password_confirmation: password2,
            })
          ),
          {
            pending: 'Changing password.',
          }
        )
        .then((response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            toast.success('Password changed successfully.')
            setIsEdit(false)
            setOldPassword('')
            setPassword('')
            setPassword2('')
            if (autoLogout.isChecked) {
              dispatch(logout())
            }
          } else {
            toast.error(response.payload.message || response.payload.error.message || 'Error changing password.')
          }
        })
    }
  }

  useEffect(() => {
    if (password !== password2 && password2Touched) {
      setPasswordNotMatch(true)
    } else if (password === password2) {
      setPasswordNotMatch(false)
    }

    const strength = passwordStrengthCalculate(password)
    setPasswordStrengthLabel(strength.text)
    setPasswordStregthColor(strength.color)
  }, [password, password2, password2Touched])

  return (
    <Stack direction="column" rowGap="16px">
      <Stack direction="row" justifyContent="space-between">
        <Stack flex={1} direction="column" rowGap="24px">
          <Text style={{ fontSize: AppStyles.FONT_SIZES.FORM_TITLE, fontWeight: '500' }}>Password</Text>
          {!isEdit ? (
            <Text>{'Choose a strong, unique password that’s at least 8 characters long.'}</Text>
          ) : (
            <Stack direction="column">
              <Text style={{ paddingBottom: AppStyles.PADDING_SIZES.XXS }}>
                {"Choose a strong password and don't reuse it for other accounts."}
              </Text>
              <Text>{'Changing your password will sign you out on your devices.'}</Text>
            </Stack>
          )}
        </Stack>
        {!isEdit && (
          <Button
            type="secondary"
            style={{
              backgroundColor: GlobalStyles.SLATE_100,
              borderRadius: AppStyles.BUTTON_RADIUS,
              width: AppStyles.BUTTON_SIZE_LARGE,
              height: AppStyles.BUTTON_SIZE_LARGE,
            }}
            onPress={() => setIsEdit(true)}>
            <View>
              <Icon.Edit2 color={GlobalStyles.PRIMARY_500} size={18} />
            </View>
          </Button>
        )}
      </Stack>
      {!isEdit ? (
        <Stack direction="column">
          <Text>Password</Text>
          <Text>***************</Text>
        </Stack>
      ) : (
        <>
          <Stack direction="column">
            <Text>Old Password</Text>
            <Input
              name="old-password"
              secureTextEntry={strictOldPassword}
              style={{ borderWidth: 0, padding: AppStyles.PADDING_SIZES.BASE }}
              wrapperStyle={{
                backgroundColor: GlobalStyles.MONO_WHITE,
                height: 'auto',
                borderColor: GlobalStyles.SLATE_100,
                paddingRight: AppStyles.PADDING_SIZES.BASE,
              }}
              value={oldPassword}
              onChangeText={(value: string) => setOldPassword(value)}
              rightElement={
                <Button
                  type="primary"
                  style={{ backgroundColor: GlobalStyles.SLATE_100 }}
                  onPress={() => setStrictOldPassword(!strictOldPassword)}>
                  <Text style={{ color: GlobalStyles.PRIMARY_500 }}>
                    {strictOldPassword ? <Icon.Eye size={GlobalStyles.ICON_SIZE} /> : <Icon.EyeOff size={GlobalStyles.ICON_SIZE} />}
                  </Text>
                </Button>
              }
            />
          </Stack>
          <Stack direction="column">
            <Text>New Password</Text>
            <Input
              name="new-password"
              secureTextEntry={strictPassword}
              style={{ borderWidth: 0, padding: AppStyles.PADDING_SIZES.BASE }}
              wrapperStyle={{
                backgroundColor: GlobalStyles.MONO_WHITE,
                height: 'auto',
                borderColor: GlobalStyles.SLATE_100,
                paddingRight: AppStyles.PADDING_SIZES.BASE,
              }}
              value={password}
              onChangeText={(value: string) => setPassword(value)}
              placeholder={'***************'}
              rightElement={
                <Button
                  type="primary"
                  style={{ backgroundColor: GlobalStyles.SLATE_100 }}
                  onPress={() => setStrictPassword(!strictPassword)}>
                  <Text style={{ color: GlobalStyles.PRIMARY_500 }}>
                    {strictPassword ? <Icon.Eye size={GlobalStyles.ICON_SIZE} /> : <Icon.EyeOff size={GlobalStyles.ICON_SIZE} />}
                  </Text>
                </Button>
              }
            />
          </Stack>
          <Stack direction="column">
            <Text>Confirm New Password</Text>
            <Input
              name="confirm-new-password"
              secureTextEntry={strictPassword2}
              style={{ borderWidth: 0, padding: AppStyles.PADDING_SIZES.BASE }}
              wrapperStyle={{
                backgroundColor: GlobalStyles.MONO_WHITE,
                height: 'auto',
                borderColor: GlobalStyles.SLATE_100,
                paddingRight: AppStyles.PADDING_SIZES.BASE,
              }}
              value={password2}
              onChangeText={(value: string) => {
                setPassword2(value)
                setPassword2Touched(true)
              }}
              placeholder={'***************'}
              errorMessage={passwordNotMatch ? 'Password did not match' : ''}
              rightElement={
                <Button
                  type="primary"
                  style={{ backgroundColor: GlobalStyles.SLATE_100 }}
                  onPress={() => setStrictPassword2(!strictPassword2)}>
                  <Text style={{ color: GlobalStyles.PRIMARY_500 }}>
                    {strictPassword2 ? <Icon.Eye size={GlobalStyles.ICON_SIZE} /> : <Icon.EyeOff size={GlobalStyles.ICON_SIZE} />}
                  </Text>
                </Button>
              }
            />
          </Stack>
          <Stack direction="column" rowGap="14px">
            <Stack direction="row" alignItems="center">
              <Text bold>Password strength: </Text>
              {passwordStrengthLabel !== '' && (
                <Label
                  type={'DEFAULT'}
                  text={passwordStrengthLabel}
                  style={{ backgroundColor: passwordStregthColor, paddingVertical: AppStyles.PADDING_SIZES['4XS'] }}
                  textStyle={{ fontSize: GlobalStyles.FONT_SIZES.TINY }}
                />
              )}
            </Stack>
            <Text>
              {'Use at least 8 characters. Don’t use a password from another site, or something too obvious like your pet’s name.'}
            </Text>
          </Stack>

          <Stack direction={screenIsSmall ? 'row' : 'column'} alignItems="center" justifyContent="space-between">
            <Stack flex={1} direction="row" alignItems="center">
              <Checkbox defaultChecked={autoLogout.isChecked} value={{ id: 1 }} onChange={_handleCheckboxValueChange} />
              <Text>All devices will be required to sign in with new password</Text>
            </Stack>
            <Stack direction="row" width={screenIsSmall ? 'auto' : '100%'} justifyContent={'flex-end'}>
              <Button
                type="secondary"
                style={{ backgroundColor: GlobalStyles.MONO_WHITE }}
                onPress={() => setIsEdit(false)}
                textStyle={{ color: GlobalStyles.PRIMARY_500 }}
                text="Cancel"
              />
              <Button
                type="primary"
                text="Change Password"
                style={{ borderRadius: 8, paddingVertical: 12, paddingHorizontal: 16 }}
                disabled={!oldPassword || !password || !password2 || passwordNotMatch}
                onPress={_handleSubmit}
              />
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  )
}

export { PasswordChange }
