import { Avatar, GlobalStyles, Icon } from '@andromeda'
import { Button, Divider, Popover, Stack, Typography } from '@mui/material'
import { Types } from '@pickstar/orbit'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { logout } from '@redux/reducers/authReducer'
import { selectWorkspace } from '@redux/reducers/workspaceReducer'
import { AppStyles } from '@styles'
import { PathEnums } from '@utils/context/Navigation.context'
import { useMe } from '@utils/query/useMe'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import Link from 'next/link'

export const UserMenu = ({ anchorEl, handleClose }: { anchorEl: null | HTMLElement; handleClose: (e: any) => void }) => {
  const dispatch = useAppDispatch()
  const { workspace } = useAppSelector(selectWorkspace)

  const { data: user } = useMe()

  const open = Boolean(anchorEl)
  const id = open ? 'user-popover' : undefined

  return (
    <Popover
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
      <Stack sx={{ padding: '12px' }} rowGap="10px">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontSize="24px" color={GlobalStyles.MONO_BLACK}>
            Account
          </Typography>
          <Button variant="outlined" onClick={handleClose} style={{ height: '40px', width: '40px', padding: 0 }}>
            <Icon.X size={16} />
          </Button>
        </Stack>
        <Stack direction="row" columnGap="6px" marginBottom="4px" alignItems="center">
          <Avatar
            // @ts-expect-error
            style={{ borderWidth: 2, borderColor: GlobalStyles.MONO_WHITE, height: '48px', width: '48px', borderRadius: '100%' }}
            image={user?.profile_image?.full_url || undefined}
            placeHolder={<Icon.User style={{ height: '44px', width: '44px' }} color={GlobalStyles.SLATE_200} strokeWidth={1} />}
          />
          <Stack>
            <Typography fontSize="14px" color={GlobalStyles.MONO_BLACK}>
              {user?.name}
            </Typography>
            <Typography fontSize="12px" color={GlobalStyles.SLATE_700}>
              {user?.email}
            </Typography>
          </Stack>
        </Stack>
        <Link
          href={{ pathname: PathEnums.MY_ACCOUNT, query: { workspace } }}
          style={{ width: '100%', textDecoration: 'none' }}
          onClick={handleClose}>
          <Typography fontSize="14px" color={GlobalStyles.PRIMARY_500}>
            Manage account
          </Typography>
        </Link>
        <Divider />
        <Button
          variant="text"
          onClick={(e) => {
            trackEvent({
              event: TrackingEventEnums.Account.ACCOUNT_LOGOUT,
              eventProperties: {
                workspace: workspace,
                component_name: 'Header Menu',
              },
            })
            dispatch(logout())
            handleClose(e)
          }}
          style={{ padding: 0, justifyContent: 'left', fontSize: '14px', fontWeight: '400' }}>
          Sign out
        </Button>
      </Stack>
    </Popover>
  )
}
